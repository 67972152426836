<template>
  <section class="w-full p-6">
    <form class="w-full mx-auto space-y-6" @submit.prevent novalidate>
      <figure class="w-full bg-white rounded ring-1 ring-gray-200">
        <header class="p-4">
          <p class="text-base font-semibold rtl:font-bold">Basic details</p>
        </header>
        <blockquote class="w-full p-5 border-t border-gray-200 space-y-4">
          <div class="w-full flex gap-4 flex-col sm:flex-row">
            <div class="space-y-3 flex-1">
              <figure class="space-y-4">
                <p class="text-sm font-medium rtl:font-semibold">Image | Ar</p>
                <slide-transition>
                  <figcaption v-if="add_data.image_ar" class="relative table">
                    <img
                      :src="
                        add_data.image_ar
                          ? convertImage(add_data.image_ar)
                          : require('@/assets/images/placeholder.png')
                      "
                      @error="
                        (e) =>
                          (e.target.src = require('@/assets/images/placeholder.png'))
                      "
                      alt="Package image ar"
                      class="w-auto max-h-[7rem] max-w-[5rem] object-contain"
                    />
                    <button
                      class="
                        w-6
                        h-6
                        rounded-full
                        flex
                        items-center
                        justify-center
                        bg-red-500
                        text-white
                        absolute
                        -top-2
                        -right-2
                        rtl:right-auto rtl:-left-2
                        ring-2 ring-white
                      "
                      type="button"
                      @click.prevent="
                        () =>
                          clearImage('add_data', 'image_ar', 'imageArUploader')
                      "
                    >
                      <i class="fa-solid fa-xmark fa-sm"></i>
                    </button>
                  </figcaption>
                </slide-transition>
                <blockquote class="space-y-2">
                  <label class="block">
                    <span class="sr-only">Choose image</span>
                    <input
                      type="file"
                      accept="image/*"
                      class="custom-file"
                      ref="imageArUploader"
                      @change="
                        (e) => $set(add_data, 'image_ar', e.target.files[0])
                      "
                    />
                  </label>
                  <p class="error" v-if="add_errors.image_ar">
                    {{ add_errors.image_ar.join(" ") }}
                  </p>
                </blockquote>
              </figure>
            </div>
            <div class="space-y-3 flex-1">
              <figure class="space-y-4">
                <p class="text-sm font-medium rtl:font-semibold">Image | En</p>
                <slide-transition>
                  <figcaption v-if="add_data.image_en" class="relative table">
                    <img
                      :src="
                        add_data.image_en
                          ? convertImage(add_data.image_en)
                          : require('@/assets/images/placeholder.png')
                      "
                      @error="
                        (e) =>
                          (e.target.src = require('@/assets/images/placeholder.png'))
                      "
                      alt="Package image en"
                      class="w-auto max-h-[7rem] max-w-[5rem] object-contain"
                    />
                    <button
                      class="
                        w-6
                        h-6
                        rounded-full
                        flex
                        items-center
                        justify-center
                        bg-red-500
                        text-white
                        absolute
                        -top-2
                        -right-2
                        rtl:right-auto rtl:-left-2
                        ring-2 ring-white
                      "
                      type="button"
                      @click.prevent="
                        () =>
                          clearImage('add_data', 'image_en', 'imageEnUploader')
                      "
                    >
                      <i class="fa-solid fa-xmark fa-sm"></i>
                    </button>
                  </figcaption>
                </slide-transition>
                <blockquote class="space-y-2">
                  <label class="block">
                    <span class="sr-only">Choose image</span>
                    <input
                      type="file"
                      accept="image/*"
                      class="custom-file"
                      ref="imageEnUploader"
                      @change="
                        (e) => $set(add_data, 'image_en', e.target.files[0])
                      "
                    />
                  </label>
                  <p class="error" v-if="add_errors.image_en">
                    {{ add_errors.image_en.join(" ") }}
                  </p>
                </blockquote>
              </figure>
            </div>
          </div>
          <div class="w-full">
            <div class="w-full flex gap-4 flex-col sm:flex-row">
              <div class="form-group space-y-3 flex-1">
                <label for="name_ar"> Name | Ar </label>
                <input
                  type="text"
                  name="name_ar"
                  id="name_ar"
                  v-model="add_data.name_ar"
                  placeholder="الاسم"
                  v-rtl
                />
                <p class="error">
                  {{ (add_errors["name_ar"] || []).join(" ") }}
                </p>
              </div>
              <div class="form-group space-y-3 flex-1">
                <label for="name_en"> Name | En </label>
                <input
                  type="text"
                  name="name_en"
                  id="name_en"
                  v-model="add_data.name_en"
                  placeholder="Name"
                  v-ltr
                />
                <p class="error">
                  {{ (add_errors["name_en"] || []).join(" ") }}
                </p>
              </div>
            </div>
          </div>
          <div class="w-full">
            <div class="w-full flex gap-4 flex-col sm:flex-row">
              <div class="form-group space-y-3 flex-1">
                <label for="desc_ar"> Description | Ar </label>
                <textarea
                  name="desc_ar"
                  id="desc_ar"
                  placeholder="..."
                  v-rtl
                  v-model="add_data.desc_ar"
                ></textarea>
                <p class="error">
                  {{ (add_errors["desc_ar"] || []).join(" ") }}
                </p>
              </div>
              <div class="form-group space-y-3 flex-1">
                <label for="desc_en"> Description | En </label>
                <textarea
                  name="desc_en"
                  id="desc_en"
                  placeholder="..."
                  v-ltr
                  v-model="add_data.desc_en"
                ></textarea>
                <p class="error">
                  {{ (add_errors["desc_en"] || []).join(" ") }}
                </p>
              </div>
            </div>
          </div>
          <div class="w-full">
            <div class="w-full flex gap-4 flex-col sm:flex-row">
              <div class="form-group space-y-3 flex-1">
                <label for="months"> Months </label>
                <input
                  type="number"
                  name="months"
                  id="months"
                  v-model="add_data.months"
                  placeholder="1"
                  min="1"
                />
                <p class="error">
                  {{ (add_errors["months"] || []).join(" ") }}
                </p>
              </div>
              <div class="form-group space-y-3 flex-1">
                <label for="trial_days"> Trial days </label>
                <input
                  type="number"
                  name="trial_days"
                  id="trial_days"
                  v-model="add_data.trial_days"
                  placeholder="1"
                  min="1"
                />
                <p class="error">
                  {{ (add_errors["trial_days"] || []).join(" ") }}
                </p>
              </div>
            </div>
          </div>
          <div class="w-full">
            <div class="w-full flex gap-4 flex-col sm:flex-row">
              <div class="form-group space-y-3 flex-1">
                <label for="price"> Price </label>
                <input
                  type="number"
                  name="price"
                  id="price"
                  v-model="add_data.price"
                  placeholder="1"
                  min="1"
                />
                <p class="error">
                  {{ (add_errors["price"] || []).join(" ") }}
                </p>
              </div>
              <div class="form-group space-y-3 flex-1">
                <label for="alt_price"> Alt price </label>
                <input
                  type="number"
                  name="alt_price"
                  id="alt_price"
                  v-model="add_data.alt_price"
                  placeholder="1"
                  min="1"
                />
                <p class="error">
                  {{ (add_errors["alt_price"] || []).join(" ") }}
                </p>
              </div>
            </div>
          </div>
          <div class="form-group space-y-3 flex-1">
            <label for="users_limit"> Users limit </label>
            <input
              type="number"
              name="users_limit"
              id="users_limit"
              v-model="add_data.users_limit"
              placeholder="1"
              min="1"
            />
            <p class="error">
              {{ (add_errors["users_limit"] || []).join(" ") }}
            </p>
          </div>
          <div class="form-group space-y-3 flex-1">
            <label for="installments"> Installments </label>
            <input
              type="number"
              name="installments"
              id="installments"
              v-model="add_data.installments"
              placeholder="1"
              min="1"
            />
            <p class="error">
              {{ (add_errors["installments"] || []).join(" ") }}
            </p>
          </div>
          <ul class="space-y-4">
            <li class="checkbox">
              <input
                type="checkbox"
                name="portal_login"
                id="portal_login"
                :true-value="1"
                :false-value="0"
                v-model="add_data.portal_login"
              />
              <label
                for="portal_login"
                class="text-sm font-medium rtl:font-semibold capitalize"
                >Portal login</label
              >
            </li>
            <li class="checkbox">
              <input
                type="checkbox"
                name="has_device"
                id="has_device"
                :true-value="1"
                :false-value="0"
                v-model="add_data.has_device"
              />
              <label
                for="has_device"
                class="text-sm font-medium rtl:font-semibold capitalize"
                >Has device</label
              >
            </li>
            <slide-transition>
              <li v-if="add_data.has_device">
                <div class="space-y-3">
                  <label
                    class="font-medium rtl:font-semibold text-gray-600 text-sm"
                  >
                    Devices
                  </label>
                  <select-with-limit
                    :options="devices"
                    :text="'name_' + $i18n.locale"
                    id="id"
                    placeholder="Select"
                    v-model="add_data.device_ids"
                  />
                  <div class="checkbox">
                    <input
                      type="checkbox"
                      name="devices_installment"
                      id="devices_installment"
                      :true-value="1"
                      :false-value="0"
                      v-model="add_data.devices_installment"
                    />
                    <label
                      for="devices_installment"
                      class="text-sm font-medium rtl:font-semibold capitalize"
                      >Devices installment</label
                    >
                  </div>
                  <p class="error">
                    {{
                      Object.keys(add_errors)
                        .filter((e) => /device\w+/gi.test(e))
                        .map((e) => add_errors[e])
                        .join(" ")
                    }}
                  </p>
                </div>
              </li>
            </slide-transition>
            <li class="checkbox">
              <input
                type="checkbox"
                name="has_discount"
                id="has_discount"
                :true-value="1"
                :false-value="0"
                v-model="add_data.has_discount"
              />
              <label
                for="has_discount"
                class="text-sm font-medium rtl:font-semibold capitalize"
                >Has discount</label
              >
            </li>
          </ul>
        </blockquote>
      </figure>
      <slide-transition>
        <figure
          class="w-full bg-white rounded ring-1 ring-gray-200"
          v-show="!!add_data.has_discount"
        >
          <header class="p-4">
            <p class="text-base font-semibold rtl:font-bold">Discount</p>
          </header>
          <blockquote class="w-full p-5 border-t border-gray-200 space-y-4">
            <div class="w-full">
              <div class="w-full flex gap-4 flex-col sm:flex-row">
                <div class="form-group space-y-3 flex-1">
                  <label for="discount_type"> Discount type </label>
                  <select
                    name="discount_type"
                    id="discount_type"
                    v-model="add_data.discount_type"
                    class="custom-select"
                  >
                    <option value="" selected disabled>- select --</option>
                    <option value="fixed">Fixed</option>
                    <option value="percentage">Percentage</option>
                  </select>
                  <p class="error">
                    {{ (add_errors["discount_type"] || []).join(" ") }}
                  </p>
                </div>
                <div class="form-group space-y-3 flex-1">
                  <label for="discount_value"> Discount value </label>
                  <input
                    type="number"
                    name="discount_value"
                    id="discount_value"
                    v-model="add_data.discount_value"
                    placeholder="1"
                    min="1"
                  />
                  <p class="error">
                    {{ (add_errors["discount_value"] || []).join(" ") }}
                  </p>
                </div>
              </div>
            </div>
            <div class="w-full">
              <div class="w-full flex gap-4 flex-col sm:flex-row">
                <div class="flex-1 form-group space-y-3">
                  <label for="discount_start_date"> Start at </label>
                  <t-datepicker
                    placeholder="xxxx-xx-xx"
                    v-model="add_data.discount_start_date"
                    :clearable="false"
                    id="discount_start_date"
                    userFormat="Y-m-d"
                    :weekStart="6"
                  />
                  <p class="error">
                    {{ (add_errors.discount_start_date || []).join(" ") }}
                  </p>
                </div>
                <div class="flex-1 form-group space-y-3">
                  <label for="discount_end_date"> End at </label>
                  <t-datepicker
                    placeholder="xxxx-xx-xx"
                    v-model="add_data.discount_end_date"
                    :clearable="false"
                    id="discount_end_date"
                    userFormat="Y-m-d"
                    :weekStart="6"
                    :minDate="add_data.discount_start_date"
                  />
                  <p class="error">
                    {{ (add_errors.discount_end_date || []).join(" ") }}
                  </p>
                </div>
              </div>
            </div>
            <div class="form-group space-y-3 flex-1">
              <label for="discount_used_limit"> Discount used limit </label>
              <input
                type="number"
                name="discount_used_limit"
                id="discount_used_limit"
                v-model="add_data.discount_used_limit"
                placeholder="1"
                min="1"
              />
              <p class="error">
                {{ (add_errors["discount_used_limit"] || []).join(" ") }}
              </p>
            </div>
          </blockquote>
        </figure>
      </slide-transition>

      <button-with-loader
        btnClass="w-auto py-2 px-4 rounded bg-primary
          text-white text-xs"
        :disabled="add_disabled"
        @click="addPackage"
        >Save</button-with-loader
      >
    </form>
  </section>
</template>
<script>
import ButtonWithLoader from "@/components/basics/ButtonWithLoader.vue";
import { mapActions } from "vuex";
export default {
  components: { ButtonWithLoader },
  name: "CreatePackage",
  data() {
    return {
      add_data: {
        discount_type: "",
        device_ids: [],
      },
      add_errors: {},
      devices: [],
      add_disabled: false,
    };
  },

  watch: {
    "add_data.has_discount"() {
      for (const key in this.add_data) {
        if (/discount_\w+/gi.test(key)) {
          delete this.add_data[key];
        }
      }
    },
  },
  created() {
    this.getDevices();
  },
  methods: {
    ...mapActions("getting", ["getRoute"]),
    async getDevices() {
      try {
        const { result } = await this.getRoute("devices");
        this.devices = result.devices;
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },
    async addPackage() {
      try {
        this.add_disabled = true;
        this.add_errors = {};
        let item_data = this.add_data;
        let fd = new FormData();

        for (const key in item_data) {
          const item = item_data[key];

          if (item || typeof item === "number") {
            if (/image_/g.test(key)) {
              fd.append(key, item, item?.name);
            } else if (key === "devices_installment") {
              if (item_data.has_device) {
                fd.append(key, item);
              }
            } else if (key === "device_ids") {
              for (const deviceIdx in item) {
                fd.append(`${key}[${deviceIdx}]`, item[deviceIdx]);
              }
            } else {
              fd.append(key, item);
            }
          }
        }
        
        const { data } = await this.axios.post("packages/add", fd);
        this.$router.push("/packages");
        this.createAlert(data.message);
      } catch (error) {
        console.log(error);
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.add_errors = responseError;
        } else {
          this.createAlert(responseError, "error");
        }
      } finally {
        this.add_disabled = false;

        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }
    },

    convertImage(e) {
      return URL.createObjectURL(e);
    },
    clearImage(type, key, elem) {
      const uploader = this.$refs[elem];
      if (!uploader) return;
      uploader.value = null;
      this.$set(this[type], key, null);
    },
  },
};
</script>
