import SecureLS from "secure-ls";
import createPersistedState from "vuex-persistedstate";
const ls = new SecureLS({ isCompression: false });

export default createPersistedState({
  storage: {
    getItem: (key) => {
      try {
        return ls.get(key);
      } catch {
        return null;
      }
    },
    setItem: (key, value) => ls.set(key, value),
    removeItem: (key) => ls.remove(key),
  },
  paths: ["root", "Cart", "warehouseCart"],
});
