<template>
  <section class="w-full p-6 space-y-6">
    <Table
      title="Products"
      :records="`${pagination.total || 0} records`"
      :pagination="pagination"
      :total_pages="pagination.total_pages"
      v-model="pagination.page"
    >
      <template #actions>
        <div>
          <router-link
            to="/products/create"
            class="
              py-1.5
              px-3
              text-sm
              flex
              items-center
              gap-2
              text-gray-600
              font-medium
              rtl:font-semibold
            "
          >
            <i class="fa-solid fa-plus"></i>
            <span>New product</span>
          </router-link>
        </div>
      </template>
      <template #filter>
        <ul class="flex items-center">
          <li v-for="(item, index) in status" :key="index">
            <button
              class="
                py-4
                px-6
                text-center
                font-medium
                rtl:font-semibold
                text-sm
                capitalize
              "
              @click.prevent="
                changeFilterStatus(item === 'all' ? null : item, index)
              "
              :class="{
                'active-tab':
                  pagination.is_active === (item === 'all' ? null : item),
              }"
              :disabled="disables[index]"
            >
              <spinner
                size="w-4 h-4 inline-block"
                class="mr-2 rtl:mr-0 rtl:ml-2"
                v-if="disables[index]"
              />
              <!-- {{ $t(item.replace(/\s/g, "_")) }} -->
              {{ item }}
            </button>
          </li>
        </ul>
      </template>
      <template #head>
        <th>Product</th>
        <th>Price (SAR)</th>
        <th>SKU</th>
        <th>Barcode</th>
        <th>Qty</th>
        <th>Status</th>
        <th>Production date</th>
        <th>Expiry date</th>
        <th>Latest update</th>
        <th>Actions</th>
      </template>
      <template #tbody v-if="!load">
        <TableLoad :rows="10" v-for="i in 4" :key="i" />
      </template>
      <template #tbody v-else-if="load && items.length">
        <tr v-for="(product, index) in items" :key="index">
          <td>
            <div class="flex items-center gap-3">
              <div class="w-10 h-10 shrink-0">
                <img
                  :src="
                    product.image || require('@/assets/images/placeholder.png')
                  "
                  @error="
                    $event.target.src = require('@/assets/images/placeholder.png')
                  "
                  alt="product image"
                  class="
                    w-full
                    h-full
                    rounded-md
                    border
                    object-cover
                    border-gray-100
                  "
                />
              </div>
              <span
                class="inline-block line-clamp-2 max-w-sm whitespace-normal"
              >
                {{ product["name_" + $i18n.locale] }}
              </span>
            </div>
          </td>

          <td>{{ product.price }}</td>

          <td>{{ product.sku || "-" }}</td>
          <td>{{ product.barcode || "-" }}</td>
          <td>{{ product.qty || "N/A" }}</td>
          <td>
            <p
              class="status capitalize"
              :class="item_status[product.is_active]"
            >
              {{ product.is_active === 1 ? "Active" : "Not active" }}
            </p>
          </td>

          <td v-html="createTime(product.prod_date)"></td>
          <td v-html="createTime(product.exp_date)"></td>
          <td
            v-html="createTime(product.updated_at || product.created_at)"
          ></td>

          <td>
            <div class="flex items-center gap-2">
              <div>
                <button
                  class="p-2 px-3 text-red-500 font-medium rtl:font-semibold"
                  @click.prevent="deleteItem(product.id)"
                  :disabled="disables[`delete_${product.id}`]"
                >
                  <spinner
                    size="w-4 h-4 inline-block"
                    v-if="disables[`delete_${product.id}`]"
                  />
                  <span v-else> Delete </span>
                </button>
              </div>
              <div>
                <button
                  class="w-8 h-8 bg-sky-500/10 text-sky-500 rounded"
                  @click.prevent="changeStatus(product.id)"
                  :content="'Change status'"
                  v-tippy
                  :disabled="disables[`status_${product.id}`]"
                >
                  <spinner
                    size="w-4 h-4 inline-block"
                    v-if="disables[`status_${product.id}`]"
                  />
                  <i class="fa-solid fa-right-left" v-else></i>
                </button>
              </div>
              <div>
                <router-link
                  :to="`/products/${product.id}-${
                    product.name_ar ||
                    product.name_en ||
                    `product-${product.created_at}`
                  }`"
                  class="
                    w-8
                    h-8
                    bg-sky-600/10
                    text-sky-600
                    rounded
                    flex
                    items-center
                    justify-center
                  "
                  :content="'Update'"
                  v-tippy
                >
                  <i class="fa-solid fa-pen"></i>
                </router-link>
              </div>
            </div>
          </td>
        </tr>
      </template>

      <template #tbody v-else>
        <tr>
          <td colspan="10" class="!text-center">There are no products</td>
        </tr>
      </template>
    </Table>
  </section>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  name: "ProductsPage",
  data() {
    return {
      load: false,
      items: [],

      item_status: {
        1: "bg-sky-500/10 text-sky-500",
        0: "bg-red-500/10 text-red-500",
      },
      status: ["all", "active", "not active"],
      pagination: {
        is_active: null,
        page: 1,
        sort_key: null,
        sort_type: "asc",
        supplier_id: this.$route.query?.supplier,
        warehouse_id: this.$route.query?.warehouse,
        module_ids: this.$route.query?.modules,
      },
      disables: {},
    };
  },
  created() {
    this.getItems();
    console.log(this.$route.query);
  },
  watch: {
    "pagination.page"() {
      this.getItems();
    },
    is_called(val, _) {
      if (val) {
        this.getItems();
      }
    },
  },
  computed: {
    ...mapGetters("table", ["search", "is_called"]),
  },
  methods: {
    ...mapActions("getting", ["getRoute"]),
    ...mapMutations("table", ["changeIsCalled"]),
    async getItems() {
      try {
        const { total, total_pages, ...others } = this.pagination;
        const user = this.$store.state.user;

        const { result } = await this.getRoute({
          name: "products",
          options: {
            ...others,
            is_active: others.is_active
              ? others.is_active === "active"
                ? 1
                : 0
              : null,
            search_key: this.search,
            vendor_id: user.user_type === "vendor" ? user?.id : null,
            branch_id: user.user_type === "cashier" ? user?.id : null,
          },
        });
        const { data, pagination } = result.products;
        this.items = data;
        this.$set(this.pagination, "total", pagination.total);
        this.$set(this.pagination, "total_pages", pagination.total_pages);
      } catch (error) {
        console.log(error);
        // this.createAlert(result?.message, type);
      } finally {
        this.load = true;
        this.changeIsCalled(false);
      }
    },
    async changeStatus(id) {
      this.$set(this.disables, `status_${id}`, true);
      try {
        const { data } = await this.axios.post(
          `products/update_is_active/${id}`
        );
        this.createAlert(data.message);
        this.getItems();
      } catch (err) {
        const res = err?.response;
        if (!res) {
          this.createAlert(
            "There is something went wrong, please try again later.",
            "error"
          );
          return;
        }
        this.add_errors = res?.data?.message;
        this.createAlert(res?.data?.message, "error");
      } finally {
        this.disables = {};
      }
    },
    async deleteItem(id) {
      this.$set(this.disables, `delete_${id}`, true);
      try {
        const { data } = await this.axios.post(`products/delete/${id}`);
        this.createAlert(data.message);
        this.getItems();
      } catch (err) {
        const res = err?.response;
        if (!res) {
          this.createAlert(
            "There is something went wrong, please try again later.",
            "error"
          );
          return;
        }
        this.add_errors = res?.data?.message;
        this.createAlert(res?.data?.message, "error");
      } finally {
        this.disables = {};
      }
    },
    sortTable(key) {
      if (this.pagination.sort_key != key) {
        this.pagination.sort_type = "asc";
      } else {
        this.pagination.sort_type =
          this.pagination.sort_type == "asc" ? "desc" : "asc";
      }
      this.pagination.sort_key = key;
      this.getItems().finally(() => {
        this.createAlert(`Table sorted by ${key}`);
      });
    },

    changeFilterStatus(status, index) {
      this.$set(this.disables, index, true);
      this.$set(this.pagination, "is_active", status);
      this.getItems().finally(() => {
        this.disables = {};
      });
    },
  },
};
</script>
