<template>
  <section class="w-full p-6 space-y-6">
    <Table
      title="POS Cashiers"
      :records="`${pagination.total || 0} records`"
      :pagination="pagination"
      :total_pages="pagination.total_pages"
      v-model="pagination.page"
    >
      <template #filter>
        <ul class="flex items-center">
          <li v-for="(item, index) in status" :key="index">
            <button
              class="py-4 px-6 text-center font-medium rtl:font-semibold text-sm capitalize"
              @click.prevent="
                changeFilterStatus(item === 'all' ? null : item, index)
              "
              :class="{
                'active-tab':
                  pagination.is_delivered === (item === 'all' ? null : item),
              }"
              :disabled="disables[index]"
            >
              <spinner
                size="w-4 h-4 inline-block"
                class="mr-2 rtl:mr-0 rtl:ml-2"
                v-if="disables[index]"
              />
              <!-- {{ $t(item.replace(/\s/g, "_")) }} -->
              {{ item }}
            </button>
          </li>
        </ul>
      </template>
      <template #head>
        <th>Vendor</th>
        <th>Store</th>
        <th>Code</th>
        <th>Package</th>
        <th>Serial no.</th>
        <th>Price (SAR)</th>
        <th>Advance payment</th>
        <th>Devices status</th>
        <th>Latest update</th>
        <th>Actions</th>
      </template>
      <template #tbody v-if="!load">
        <TableLoad :rows="9" v-for="i in 4" :key="i" />
      </template>
      <template #tbody v-else-if="load && items.length">
        <tr v-for="(item, index) in items" :key="index">
          <td>
            <div class="space-y-1" v-if="item.vendor">
              <p class="font-semibold rtl:font-bold text-sm text-gray-600">
                {{ (item.vendor || {}).name || "-" }}
              </p>
              <a
                :href="`mailto:${(item.vendor || {}).email}`"
                class="font-semibold text-xs text-primary table"
                v-if="(item.vendor || {}).email"
              >
                {{ (item.vendor || {}).email }}
              </a>
            </div>
            <span v-else>-</span>
          </td>
          <td>
            <div class="flex items-center gap-3" v-if="item.store">
              <div class="w-10 h-10 shrink-0">
                <img
                  :src="
                    (item.store || {}).logo ||
                    require('@/assets/images/placeholder.png')
                  "
                  @error="
                    $event.target.src = require('@/assets/images/placeholder.png')
                  "
                  alt="store logo"
                  class="w-full h-full rounded-md border object-cover border-gray-100"
                />
              </div>
              <span
                class="inline-block line-clamp-2 max-w-sm whitespace-normal"
              >
                {{ (item.store || {})["name_" + $i18n.locale] || "-" }}
              </span>
            </div>
            <span v-else>-</span>
          </td>

          <td>
            <button
              class="py-2 px-4 bg-gray-600 text-gray-100 rounded text-xs whitespace-nowrap inline-flex gap-2 items-center"
              content="Update serial"
              v-tippy
              @click.prevent="showUpdateCode(item)"
            >
              <span>{{ item.code || "N/A" }}</span>
              <i class="fa-solid fa-pen fa-sm"></i>
            </button>
          </td>
          <td>
            {{ (item.package || {})["name_" + $i18n.locale] }}
          </td>
          <td>
            <button
              class="py-2 px-4 bg-gray-600 text-gray-100 rounded text-xs whitespace-nowrap inline-flex gap-2 items-center"
              content="Update serial"
              v-tippy
              @click.prevent="UpdateSerialNumber(item)"
            >
              <span>{{ item.serial_no || "N/A" }}</span>
              <i class="fa-solid fa-pen fa-sm"></i>
            </button>
          </td>

          <td>
            {{
              Number.isInteger(item.price)
                ? item.price > 0
                  ? item.price.toFixed(2)
                  : "FREE"
                : "N/A"
            }}
          </td>
          <td>
            {{
              Number.isInteger((item.advance_payment || {}).amount)
                ? (item.advance_payment || {}).amount > 0
                  ? (item.advance_payment || {}).amount.toFixed(2)
                  : "Not paid"
                : "Not paid"
            }}
          </td>

          <td>
            <p
              class="status capitalize"
              :class="item_status[item.is_delivered]"
              v-if="((item.package || {}).devices || []).length"
            >
              {{ item.is_delivered ? "DELIVERED" : "PROGRESSING" }}
            </p>

            <p class="text-xs text-gray-600 italic" v-else>Without devices</p>
          </td>

          <td v-html="createTime(item.updated_at || item.created_at)"></td>

          <td>
            <div class="flex items-center gap-2">
              <!-- <div>
                <button
                  class="p-2 px-3 text-red-500 font-medium rtl:font-semibold"
                  @click.prevent="deleteItem(item.id)"
                  :disabled="disables[`delete_${item.id}`]"
                >
                  <spinner
                    size="w-4 h-4 inline-block"
                    v-if="disables[`delete_${item.id}`]"
                  />
                  <span v-else> Delete </span>
                </button>
              </div>
              <div>
                <button
                  class="w-8 h-8 bg-sky-500/10 text-sky-500 rounded"
                  @click.prevent="changeStatus(item.id)"
                  :content="'Change status'"
                  v-tippy
                  :disabled="disables[`status_${item.id}`]"
                >
                  <spinner
                    size="w-4 h-4 inline-block"
                    v-if="disables[`status_${item.id}`]"
                  />
                  <i class="fa-solid fa-right-left" v-else></i>
                </button>
              </div> -->
              <div v-if="!item.is_delivered">
                <button
                  class="w-8 h-8 bg-sky-500/10 text-sky-500 rounded"
                  @click.prevent="ShowDelivered(item.id)"
                  :content="'Delivered'"
                  v-tippy
                  :disabled="disables[`delivered_${item.id}`]"
                  v-if="((item.package || {}).devices || []).length"
                >
                  <spinner
                    size="w-4 h-4 inline-block"
                    v-if="disables[`delivered_${item.id}`]"
                  />
                  <i class="fa-regular fa-paper-plane" v-else></i>
                </button>
              </div>
              <div>
                <button
                  class="w-8 h-8 bg-sky-500/10 text-sky-500 rounded"
                  @click.prevent="ShowDetails(item.id)"
                  :content="'More details'"
                  v-tippy
                >
                  <i class="fa-solid fa-eye"></i>
                </button>
              </div>
            </div>
          </td>
        </tr>
      </template>

      <template #tbody v-else>
        <tr>
          <td colspan="9" class="!text-center">There are no subscriptions</td>
        </tr>
      </template>
    </Table>

    <transition name="scale">
      <Modal
        v-if="show_details"
        @close="show_details = $event"
        title="View details"
        size="!max-w-screen-sm"
      >
        <template>
          <div class="px-4">
            <ul class="list-none divide-y divide-gray-200">
              <li v-if="has_devices" class="py-4">
                <ul class="list-none space-y-3 divide-y divide-gray-200">
                  <li
                    class="flex gap-4 pt-3 first:p-0"
                    v-for="(device, index) in (item.package || {}).devices"
                    :key="index"
                  >
                    <div
                      class="shrink-0 w-14 h-14 rounded flex items-center justify-center bg-gray-100 border border-gray-200"
                    >
                      <img
                        :src="
                          device.image ||
                          require('@/assets/images/placeholder.png')
                        "
                        @error="
                          (e) =>
                            (e.target.src = require('@/assets/images/placeholder.png'))
                        "
                        alt="Device image"
                        class="max-w-full max-h-full shrink-0 object-contain"
                      />
                    </div>
                    <div class="flex-1 space-y-1">
                      <p class="font-semibold rtl:font-bold text-sm">
                        {{ device["name_" + $i18n.locale] }}
                      </p>
                      <p
                        class="font-medium rtl:font-semibold text-sm text-gray-600"
                      >
                        {{ device["desc_" + $i18n.locale] }}
                      </p>
                    </div>
                    <div class="shrink-0">
                      <p class="text-sm font-bold text-primary">
                        {{ device.price }} SAR
                      </p>
                    </div>
                  </li>
                </ul>
              </li>
              <li class="py-4" v-if="has_devices">
                <p class="font-bold text-sm text-center">Installments</p>
              </li>
              <li v-if="has_devices">
                <ul class="list-none">
                  <li
                    class="grid grid-cols-3 gap-2 py-4 px-2 relative"
                    v-for="(installment, index) in item.installment_payments"
                    :key="index"
                  >
                    <span
                      class="absolute top-1/2 left-1/2 border border-dashed border-gray-200 -translate-x-1/2 -translate-y-1/2 h-full"
                    >
                    </span>
                    <p
                      class="text-xs font-semibold flex-1"
                      :class="
                        installment.status === 'paid'
                          ? 'text-gray-600 line-through'
                          : 'text-red-500'
                      "
                    >
                      {{
                        (+installment.amount || 0).toFixed(2) + " SAR" || "N/A"
                      }}
                      <br />
                      {{ installment.created_at | FilterDate("date") }}
                    </p>
                    <span class="relative">
                      <span class="flex h-3 w-3 relative mx-auto">
                        <span
                          class="animate-ping absolute inline-flex h-full w-full rounded-full bg-gray-500 opacity-75"
                        ></span>
                        <span
                          class="relative inline-flex rounded-full h-3 w-3 bg-gray-400"
                        ></span>
                      </span>
                    </span>
                    <div class="flex-1">
                      <div class="inline-flex items-center gap-2 flex-wrap">
                        <a
                          href="javascript:void(0)"
                          class="bg-red-500/10 text-red-500 text-xs py-1.5 px-2 rounded shrink-0"
                          @click.prevent="
                            handlePaymentPaid(installment.id, item.id)
                          "
                          v-if="installment.status !== 'paid'"
                          :disabled="
                            disables[`payment_status_paid_${installment.id}`]
                          "
                        >
                          <spinner
                            size="w-4 h-4 inline-block"
                            v-if="
                              disables[`payment_status_paid_${installment.id}`]
                            "
                          />
                          <span v-else
                            >Not paid -
                            {{ (+installment.amount || 0).toFixed(2) }}
                            SAR</span
                          >
                        </a>
                        <p
                          class="bg-teal-600/10 text-teal-600 text-xs py-1.5 px-2 rounded shrink-0"
                          v-else
                        >
                          <i class="fa-solid fa-check"></i> Paid
                        </p>
                        <a
                          href="javascript:void(0)"
                          class="bg-primary/10 text-primary text-xs py-1.5 px-2 rounded shrink-0"
                          title="Add notes"
                          v-tippy
                          content="Add notes"
                          @click.prevent="openNotes(installment.id)"
                        >
                          <i class="fa-regular fa-edit"></i>
                        </a>
                      </div>
                    </div>
                    <!-- <p class="text-xs text-gray-600 font-semibold flex-1">
                      {{ installment.created_at | FilterDate("time") }}
                    </p> -->
                  </li>
                </ul>
              </li>
              <slide-transition>
                <li class="py-4 grid-cols-2" v-if="note_active">
                  <p class="font-bold text-sm">Notes</p>
                  <div class="space-y-3 my-3">
                    <div class="form-group space-y-1">
                      <textarea
                        name="notes"
                        id="notes"
                        class="text-sm"
                        placeholder="Type notes.."
                        v-model="note_data.notes"
                      ></textarea>
                      <p class="error" v-if="note_errors.notes">
                        {{ (note_errors.notes || []).join("") }}
                      </p>
                    </div>
                    <div>
                      <button-with-loader
                        btnClass="w-auto py-2 px-4 rounded bg-primary
            text-white text-xs"
                        :disabled="add_disabled"
                        @click="addNotes"
                        >Submit</button-with-loader
                      >
                    </div>
                  </div>
                </li>
              </slide-transition>
              <li class="py-4 grid grid-cols-2">
                <div>
                  <p class="font-bold text-sm">Vendor information</p>
                  <ul class="list-none space-y-3 mt-3">
                    <li class="space-y-1">
                      <p class="font-medium text-xs text-gray-500">Name</p>
                      <p class="font-semibold text-sm text-gray-600">
                        {{ (item.vendor || {}).name || "N/A" }}
                      </p>
                    </li>
                    <li class="space-y-1">
                      <p class="font-medium text-xs text-gray-500">Mobile</p>
                      <a
                        :href="`tel:${(item.vendor || {}).mobile}`"
                        class="font-semibold text-xs text-primary table tabular-nums"
                        v-if="(item.vendor || {}).mobile"
                      >
                        {{ (item.vendor || {}).mobile }}
                      </a>
                    </li>
                    <li class="space-y-1">
                      <p class="font-medium text-xs text-gray-500">
                        E-mail address
                      </p>
                      <a
                        :href="`mailto:${(item.vendor || {}).email}`"
                        class="font-semibold text-xs text-primary table tabular-nums"
                        v-if="(item.vendor || {}).email"
                      >
                        {{ (item.vendor || {}).email }}
                      </a>
                    </li>
                  </ul>
                </div>
                <div>
                  <p class="font-bold text-sm">Payment</p>
                  <ul class="list-none space-y-3 my-3">
                    <li class="space-y-1">
                      <p class="font-medium text-xs text-gray-500">
                        Advance payment
                      </p>
                      <p class="font-semibold text-sm text-gray-600">
                        {{
                          (+(item.advance_payment || {}).amount || 0).toFixed(
                            2
                          ) + " SAR" || "N/A"
                        }}
                      </p>
                      <div>
                        <div class="inline-flex gap-2 flex-wrap">
                          <a
                            href="javascript:void(0)"
                            class="bg-red-500/10 text-red-500 text-xs py-1.5 px-2 rounded shrink-0"
                            @click.prevent="
                              handlePaymentPaid(
                                (item.advance_payment || {}).id,
                                item.id
                              )
                            "
                            v-if="
                              (item.advance_payment || {}).status !== 'paid'
                            "
                            :disabled="
                              disables[
                                `payment_status_paid_${
                                  (item.advance_payment || {}).id
                                }`
                              ]
                            "
                          >
                            <spinner
                              size="w-4 h-4 inline-block"
                              v-if="
                                disables[
                                  `payment_status_paid_${
                                    (item.advance_payment || {}).id
                                  }`
                                ]
                              "
                            />
                            <span v-else
                              >Not paid -
                              {{
                                (
                                  +(item.advance_payment || {}).amount || 0
                                ).toFixed(2)
                              }}
                              SAR</span
                            >
                          </a>
                          <a
                            href="javascript:void(0)"
                            class="bg-primary/10 text-primary text-xs py-1.5 px-2 rounded shrink-0"
                            title="Add notes"
                            v-tippy
                            content="Add notes"
                            @click.prevent="
                              openNotes((item.advance_payment || {}).id)
                            "
                          >
                            <i class="fa-regular fa-edit"></i>
                          </a>
                        </div>
                      </div>
                    </li>
                    <li class="space-y-1" v-if="!(item.payments || []).length">
                      <p class="font-semibold text-sm text-gray-600">N\A</p>
                    </li>
                    <li
                      class="space-y-1"
                      v-for="(payment, index) in item.payments"
                      :key="index"
                      v-else-if="false"
                    >
                      <p
                        class="font-semibold text-sm text-gray-600 inline-flex gap-2 flex-wrap capitalize items-center"
                        v-if="payment.status === 'paid'"
                      >
                        {{ payment.payment_option }}
                        <img
                          v-if="payment.payment_option"
                          :src="
                            require(`@/assets/images/payments/${payment.payment_option}.png`)
                          "
                          class="shrink-0"
                          width="30"
                        />
                        - {{ (+payment.amount || 0).toFixed(2) }} SAR
                      </p>
                      <div>
                        <div class="inline-flex gap-2 flex-wrap">
                          <a
                            href="javascript:void(0)"
                            class="bg-red-500/10 text-red-500 text-xs py-1.5 px-2 rounded shrink-0"
                            @click.prevent="
                              handlePaymentPaid(payment.id, item.id)
                            "
                            v-if="payment.status !== 'paid'"
                            :disabled="
                              disables[`payment_status_paid_${payment.id}`]
                            "
                          >
                            <spinner
                              size="w-4 h-4 inline-block"
                              v-if="
                                disables[`payment_status_paid_${payment.id}`]
                              "
                            />
                            <span v-else
                              >Not paid -
                              {{ (+payment.amount || 0).toFixed(2) }} SAR</span
                            >
                          </a>
                          <a
                            href="javascript:void(0)"
                            class="bg-primary/10 text-primary text-xs py-1.5 px-2 rounded shrink-0"
                            title="Add notes"
                            v-tippy
                            content="Add notes"
                            @click.prevent="openNotes(payment.id)"
                          >
                            <i class="fa-regular fa-edit"></i>
                          </a>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <p class="font-bold text-sm">Store information</p>
                  <ul class="list-none space-y-3 mt-3">
                    <li class="space-y-1">
                      <p class="font-medium text-xs text-gray-500">Name</p>
                      <p class="font-semibold text-sm text-gray-600">
                        {{
                          (item.store || {})["name_" + $i18n.locale] || "N/A"
                        }}
                      </p>
                    </li>
                    <li class="space-y-1">
                      <p class="font-medium text-xs text-gray-500">Address</p>
                      <p class="font-semibold text-sm text-gray-600">
                        {{ (item.store || {}).address || "N/A" }}
                      </p>
                    </li>
                  </ul>
                </div>
              </li>

              <li class="py-4 grid grid-cols-2">
                <div class="col-span-2 mb-3">
                  <p class="font-bold text-sm">Package details</p>
                </div>
                <div>
                  <!-- <p class="font-bold text-sm">Package details</p> -->
                  <ul class="list-none space-y-3">
                    <li class="space-y-1">
                      <p class="font-medium text-xs text-gray-500">Package</p>
                      <div>
                        <p
                          class="font-semibold rtl:font-bold text-sm text-gray-600"
                        >
                          {{ (item.package || {})["name_" + $i18n.locale] }}
                        </p>
                        <p class="inline-block text-xs font-bold text-primary">
                          Price:
                          {{
                            item.is_main
                              ? (item.package || {}).total_price
                              : (item.package || {}).total_alt_price
                          }}
                          SAR
                        </p>
                      </div>
                    </li>
                    <li class="space-y-1">
                      <p class="font-medium text-xs text-gray-500">
                        Trial days
                      </p>
                      <p class="font-semibold text-sm text-gray-600">
                        {{
                          (item.package || {}).trial_days > 1
                            ? (item.package || {}).trial_days
                            : "N/A"
                        }}
                      </p>
                    </li>
                    <li class="space-y-1" v-if="has_devices">
                      <p class="font-medium text-xs text-gray-500">
                        Installments
                      </p>
                      <p class="font-semibold text-sm text-gray-600">
                        {{ (item.package || {}).installments }} installment(s)
                      </p>
                    </li>
                    <li class="space-y-1">
                      <p class="font-medium text-xs text-gray-500">Main POS</p>
                      <p class="font-semibold text-sm text-gray-600">
                        {{ item.is_main ? "Yes" : "No" }}
                      </p>
                    </li>
                    <li class="space-y-1">
                      <p class="font-medium text-xs text-gray-500">
                        Started at
                      </p>
                      <p class="font-semibold text-sm text-gray-600">
                        {{ item.start_date | moment("ll") }}
                      </p>
                    </li>
                    <li class="space-y-1">
                      <p class="font-medium text-xs text-gray-500">End at</p>
                      <p class="font-semibold text-sm text-gray-600">
                        {{ item.end_date | moment("ll") }}
                      </p>
                    </li>
                  </ul>
                </div>
                <div v-if="has_devices">
                  <ul class="list-none space-y-3">
                    <li class="space-y-1">
                      <p class="font-medium text-xs text-gray-500">
                        Package activation
                      </p>
                      <p>
                        <span
                          class="status uppercase !inline-block"
                          :class="item_status[item.is_delivered]"
                        >
                          {{ item.is_delivered ? "DELIVERED" : "PROGRESSING" }}
                        </span>
                      </p>
                    </li>
                    <li class="space-y-1">
                      <p class="font-medium text-xs text-gray-500">
                        Devices installment
                      </p>
                      <p class="font-semibold text-sm text-gray-600">
                        {{ item.devices_installment ? "Yes" : "No" }}
                      </p>
                    </li>
                    <li class="space-y-1">
                      <p class="font-medium text-xs text-gray-500">
                        Devices price
                      </p>
                      <p class="font-semibold text-sm text-gray-600">
                        {{
                          (+item.devices_price || 0).toFixed(2) + " SAR" ||
                          "N/A"
                        }}
                      </p>
                    </li>
                    <li class="space-y-1">
                      <p class="font-medium text-xs text-gray-500">
                        Total devices
                      </p>
                      <p class="font-semibold text-sm text-gray-600">
                        {{ (item.package || {}).devices.length || "N/A" }}
                      </p>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </template>
      </Modal>
    </transition>

    <Modal
      v-if="serial_no_active"
      title="Update serial no."
      @close="serial_no_active = $event"
    >
      <template>
        <form class="space-y-4 p-6">
          <div class="form-group space-y-3">
            <label class="form-label">Serial no.</label>
            <input type="text" v-model="serial_no_data.serial_no" />
            <p class="error">
              {{ (serial_no_errors.serial_no || []).join(" ") }}
            </p>
          </div>
          <slide-transition>
            <div class="form-group space-y-3" v-show="has_code">
              <label class="form-label">Verification code</label>
              <input type="text" v-model="serial_no_data.otp_code" />
              <p class="error">
                {{ (serial_no_errors.otp_code || []).join(" ") }}
              </p>
            </div>
          </slide-transition>
          <button-with-loader
            btnClass="w-auto py-2 px-4 rounded bg-primary
          text-white text-xs"
            :disabled="serial_no_disabled"
            @click="updateSerial"
            >Save changes</button-with-loader
          >
        </form>
      </template>
    </Modal>

    <Modal
      v-if="code_active"
      title="Update code."
      @close="code_active = $event"
    >
      <template>
        <form class="space-y-4 p-6">
          <div class="form-group space-y-3">
            <label class="form-label">Code</label>
            <input type="text" v-model="code_data.code" />
            <p class="error">
              {{ (code_errors.code || []).join(" ") }}
            </p>
          </div>
          <slide-transition>
            <div class="form-group space-y-3" v-show="has_code">
              <label class="form-label">Verification code</label>
              <input type="text" v-model="code_data.otp_code" />
              <p class="error">
                {{ (code_errors.otp_code || []).join(" ") }}
              </p>
            </div>
          </slide-transition>
          <button-with-loader
            btnClass="w-auto py-2 px-4 rounded bg-primary
          text-white text-xs"
            :disabled="code_disabled"
            @click="UpdateCode"
            >Save changes</button-with-loader
          >
        </form>
      </template>
    </Modal>
  </section>
</template>
<script>
import Modal from "@/components/basics/Modal.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import moment from "moment";
import ButtonWithLoader from "@/components/basics/ButtonWithLoader.vue";

export default {
  name: "Subscriptions",
  data() {
    return {
      load: false,
      items: [],
      item_status: {
        1: "bg-sky-500/10 text-sky-500",
        0: "bg-red-500/10 text-red-500",
      },
      status: ["all", "delivered", "progressing"],
      pagination: {
        is_delivered: null,
        page: 1,
        sort_key: null,
        sort_type: "asc",
      },
      disables: {},
      item: {},
      show_details: false,
      add_disabled: false,
      note_errors: {},
      note_active: false,
      note_data: {},
      serial_no_active: false,
      serial_no_data: {},
      serial_no_errors: {},
      serial_no_disabled: false,
      code_active: false,
      code_data: {},
      code_errors: {},
      code_disabled: false,
      has_code: false,
    };
  },
  created() {
    this.getItems();
  },
  watch: {
    "pagination.page"() {
      this.getItems();
    },
    is_called(val, _) {
      if (val) {
        this.getItems();
      }
    },
    show_details(val) {
      if (!val) {
        this.note_active = false;
        this.item = {};
      }
    },
    serial_no_active(val) {
      if (!val) {
        this.serial_no_active = false;
        this.serial_no_data = {};
        this.serial_no_errors = {};
      }
      this.has_code = false;
    },
    code_active(val) {
      if (!val) {
        this.code_active = false;
        this.code_data = {};
        this.code_errors = {};
      }
      this.has_code = false;
    },
  },
  computed: {
    ...mapGetters("table", ["search", "is_called"]),
    has_devices() {
      return !!this.item?.package?.devices?.length;
    },
  },
  filters: {
    FilterDate(...args) {
      const [value, type] = args;
      const types = {
        time: "mm:hh:A",
        date: "ll",
      };

      if (value) {
        if (type) return moment(value).format(types[type]);
      } else {
        switch (type) {
          case "time":
            return "--:--";
          case "date":
            return "--/--/--";
          default:
            return "N/A";
        }
      }
    },
  },
  methods: {
    ...mapActions("getting", ["getRoute"]),
    ...mapMutations("table", ["changeIsCalled"]),
    async getItems() {
      try {
        const { total, total_pages, ...others } = this.pagination;
        const user = this.$store.state.user;
        const { result } = await this.getRoute({
          name: "pos_devices",
          options: {
            ...others,
            is_delivered: others.is_delivered
              ? others.is_delivered === "delivered"
                ? 1
                : 0
              : null,
            search_key: this.search,
            vendor_id: user.user_type === "vendor" ? user?.id : null,
            branch_id: user.user_type === "cashier" ? user?.id : null,
          },
        });
        const { data, pagination } = result.pos_devices;
        this.items = data;
        this.$set(this.pagination, "total", pagination.total);
        this.$set(this.pagination, "total_pages", pagination.total_pages);
      } catch (error) {
        console.log(error);
        // this.createAlert(result?.message, type);
      } finally {
        this.load = true;
        this.changeIsCalled(false);
      }
    },
    async handlePaymentPaid(id, item_id) {
      this.$set(this.disables, `payment_status_paid_${id}`, true);
      try {
        const { isConfirmed } = await this.$swal.fire({
          theme: "bulma",
          title: "Are you sure about that procedure?",
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          showCancelButton: true,
        });
        if (!isConfirmed) return;
        const { data } = await this.axios.post(`pos_devices/pay_payment/${id}`);
        this.createAlert(data.message);
        this.getItems().then(() => {
          this.ShowDetails(item_id);
        });
      } catch (err) {
        const res = err?.response;
        if (!res) {
          this.createAlert(
            "There is something went wrong, please try again later.",
            "error"
          );
          return;
        }
        this.add_errors = res?.data?.message;
        this.createAlert(res?.data?.message, "error");
      } finally {
        this.disables = {};
      }
    },
    async changeStatus(id) {
      this.$set(this.disables, `status_${id}`, true);
      try {
        const { data } = await this.axios.post(
          `pos_devices/update_is_active/${id}`
        );
        this.createAlert(data.message);
        this.getItems();
      } catch (err) {
        const res = err?.response;
        if (!res) {
          this.createAlert(
            "There is something went wrong, please try again later.",
            "error"
          );
          return;
        }
        this.add_errors = res?.data?.message;
        this.createAlert(res?.data?.message, "error");
      } finally {
        this.disables = {};
      }
    },
    async deleteItem(id) {
      this.$set(this.disables, `delete_${id}`, true);
      try {
        const { data } = await this.axios.post(`pos_devices/delete/${id}`);
        this.createAlert(data.message);
        this.getItems();
      } catch (err) {
        const res = err?.response;
        if (!res) {
          this.createAlert(
            "There is something went wrong, please try again later.",
            "error"
          );
          return;
        }
        this.add_errors = res?.data?.message;
        this.createAlert(res?.data?.message, "error");
      } finally {
        this.disables = {};
      }
    },
    async UpdateDelivered(id) {
      this.$set(this.disables, `delivered_${id}`, true);
      try {
        const { data } = await this.axios.post(
          `pos_devices/update_is_delivered/${id}`
        );
        this.createAlert(data.message);
        this.getItems();
      } catch (err) {
        const res = err?.response;
        if (!res) {
          this.createAlert(
            "There is something went wrong, please try again later.",
            "error"
          );
          return;
        }
        this.add_errors = res?.data?.message;
        this.createAlert(res?.data?.message, "error");
      } finally {
        this.disables = {};
      }
    },
    async updateSerial() {
      try {
        this.serial_no_disabled = true;

        const { data } = await this.axios.post(
          `pos_devices/update`,
          this.serial_no_data
        );
        const { message, result } = data;
        if (result?.need_code) {
          this.has_code = true;
          return;
        }
        this.has_code = false;
        this.serial_no_active = false;
        this.createAlert(message);
        this.getItems();
      } catch (err) {
        const res = err?.response;
        if (!res) {
          this.createAlert(
            "There is something went wrong, please try again later.",
            "error"
          );
          return;
        }
        this.serial_no_errors = res?.data?.message;
        this.createAlert(res?.data?.message, "error");
      } finally {
        this.serial_no_disabled = false;
      }
    },
    async UpdateCode() {
      try {
        this.code_disabled = true;

        const { data } = await this.axios.post(
          `pos_devices/update`,
          this.code_data
        );
        const { message, result } = data;
        if (result?.need_code) {
          this.has_code = true;
          return;
        }
        this.has_code = false;
        this.code_active = false;
        this.createAlert(message);
        this.getItems();
      } catch (err) {
        const res = err?.response;
        if (!res) {
          this.createAlert(
            "There is something went wrong, please try again later.",
            "error"
          );
          return;
        }
        this.code_errors = res?.data?.message;
        this.createAlert(res?.data?.message, "error");
      } finally {
        this.code_disabled = false;
      }
    },
    async addNotes() {
      try {
        this.add_disabled = true;
        this.note_errors = {};
        const { data } = await this.axios.post(
          "pos_devices/update_payment_notes",
          this.note_data
        );
        this.createAlert(data.message);
        this.getItems();
        this.show_details = false;
        this.note_data = {};
        this.note_active = false;
      } catch (err) {
        const res = err?.response;
        if (!res) {
          this.createAlert(
            "There is something went wrong, please try again later.",
            "error"
          );
          return;
        }
        const errors = res?.data?.message;
        if (typeof errors === "object") {
          this.note_errors = errors;
          return;
        }
        this.createAlert(res?.data?.message, "error");
      } finally {
        this.add_disabled = false;
      }
    },
    openNotes(id) {
      this.note_data = {
        id,
        notes: null,
      };
      this.note_errors = {};
      this.note_active = true;
    },
    sortTable(key) {
      if (this.pagination.sort_key != key) {
        this.pagination.sort_type = "asc";
      } else {
        this.pagination.sort_type =
          this.pagination.sort_type == "asc" ? "desc" : "asc";
      }
      this.pagination.sort_key = key;
      this.getItems().finally(() => {
        this.createAlert(`Table sorted by ${key}`);
      });
    },
    changeFilterStatus(status, index) {
      this.$set(this.disables, index, true);
      this.$set(this.pagination, "is_delivered", status);
      this.getItems().finally(() => {
        this.disables = {};
      });
    },
    ShowDetails(id) {
      const item = this.items.find((e) => e.id == id);
      this.item = item;
      this.show_details = true;
    },
    UpdateSerialNumber(item) {
      this.serial_no_data = {
        id: item.id,
        serial_no: item.serial_no,
      };
      this.serial_no_active = true;
    },
    showUpdateCode(item) {
      this.code_data = {
        id: item.id,
        code: item.code,
      };
      this.code_active = true;
    },
    async ShowDelivered(id) {
      try {
        const item = this.items.find((e) => e.id === id);
        if (item?.is_delivered) {
          this.createAlert("Devices are already delivered", "info");
          return;
        }
        const { isConfirmed } = await this.$swal.fire({
          theme: "bulma",
          title: "Are you sure about that procedure?",
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          showCancelButton: true,
        });

        if (isConfirmed) {
          await this.UpdateDelivered(id);
        }
        // .then(() => {
        //   this.$router.push("/settings");
        // });
      } catch (error) {
        console.log(error);
      }
      //   const item = this.items.find((e) => e.id == id);
      //   this.item = item;
      //   this.show_details = true;
    },
  },
  components: { Modal, ButtonWithLoader },
};
</script>
