export default {
  namespaced: true,
  state: {
    handover_active: false,
    is_first_time: false,
    notifications_enabled: false,
  },
  getters: {
    is_handover: (state) => state.handover_active,
    is_first: (state) => state.is_first_time,
    enabled_notifications: (state) => state.notifications_enabled,
  },
  mutations: {
    SET_HANDOVER(state, data) {
      state.handover_active = data;
    },
    SET_IS_FIRST(state, data) {
      state.is_first_time = data;
    },
    CHANGE_NOTIFICATIONS_ENABLED(state, data) {
      state.notifications_enabled = data;
    },
  },
  actions: {},
  modules: {},
};
