<template>
  <div>
    <section class="p-6">
      <main class="w-full">
        <div
          class="
            w-full
            grid
            gap-4
            grid-cols-[repeat(auto-fill,minmax(13em,1fr))]
            sm:grid-cols-[repeat(auto-fill,minmax(13em,1fr))]
          "
          v-if="load"
        >
          <figure
            class="w-full rounded-md bg-white p-3 space-y-4 flex flex-col"
            v-for="(item, idx) in items"
            :key="idx"
          >
            <figcaption class="w-full flex items-center justify-center">
              <img
                :src="item.image"
                @error="
                  $event.target.src = require('@/assets/images/placeholder.png')
                "
                alt="product image"
                class="
                  rounded-md
                  w-full
                  h-36
                  object-cover
                  align-middle
                  bg-gray-100
                "
              />
            </figcaption>
            <blockquote class="flex-1 space-y-2">
              <h3
                class="
                  text-sm text-gray-800
                  font-semibold
                  rtl:font-bold
                  line-clamp-2
                "
              >
                {{ item["name_" + $i18n.locale] }}
              </h3>
              <p class="text-xs text-gray-500 font-medium rtl:font-semibold">
                {{ parseInt(item.price).toFixed(2) }} SAR
              </p>
            </blockquote>
            <button
              class="
                w-full
                py-2
                px-4
                rounded
                bg-primary/10
                text-primary
                whitespace-nowrap
                text-center text-sm
                font-medium
                rtl:font-semibold
              "
              @click="addToCart(item.id)"
            >
              <i class="fa-solid fa-shopping-cart mr-0 rtl:mr-0 rtl:ml-1"></i>
              Add to cart
            </button>
          </figure>
        </div>
        <div
          v-if="!load"
          class="
            w-full
            grid grid-cols-[repeat(auto-fill,minmax(13em,1fr))]
            gap-4
          "
        >
          <figure v-for="l in 6" :key="l" class="w-full">
            <figcaption
              class="w-full h-60 bg-gray-200 animate-pulse"
            ></figcaption>
            <blockquote class="pt-4">
              <div
                class="w-4/5 h-4 rounded bg-gray-200 animate-pulse mb-4"
              ></div>
              <div class="w-1/5 h-3 rounded bg-gray-200 animate-pulse"></div>
            </blockquote>
          </figure>
        </div>
        <div v-if="load && items.length === 0" class="w-full space-y-6">
          <img
            src="@/assets/images/empty-products.webp"
            alt="mo products image"
            class="w-full max-w-sm table mx-auto"
          />
          <p class="text-lg font-semibold text-gray-600 text-center">
            There are no products yet
          </p>
        </div>
      </main>
    </section>
    <div
      class="
        w-full
        max-w-sm
        bg-white
        h-full
        fixed
        top-0
        right-0
        z-20
        hidden
        transition
        cart-container
      "
      ref="cart"
      @click="closeCart"
    >
      <div class="sticky top-0 h-screen flex flex-col z-[5] bg-white">
        <div>
          <header
            class="
              w-full
              border-b border-gray-100
              p-4
              flex
              items-center
              justify-between
            "
          >
            <div class="flex items-center gap-3">
              <div>
                <div class="w-10 h-10 shrink-0">
                  <img
                    :src="
                      user.image || require('@/assets/images/placeholder.png')
                    "
                    @error="
                      $event.target.src = require('@/assets/images/placeholder.png')
                    "
                    alt="profile image"
                    class="
                      w-full
                      h-full
                      object-cover
                      rounded-full
                      border border-gray-100
                    "
                  />
                </div>
              </div>
              <div class="flex-1 grid">
                <h3
                  class="
                    text-sm text-gray-800
                    font-semibold
                    rtl:font-bold
                    truncate
                  "
                >
                  {{ user.name }}
                </h3>
                <a
                  :href="`mailto:${user.email}`"
                  class="
                    text-xs text-gray-400
                    font-medium
                    rtl:font-semibold
                    truncate
                    block
                    mt-0.5
                  "
                >
                  {{ user.email }}
                </a>
              </div>
            </div>
            <div class="actions-group items-center">
              <div>
                <button
                  class="
                    text-red-500
                    lg:hidden
                    w-9
                    h-9
                    flex
                    items-center
                    justify-center
                    rounded-full
                  "
                  @click="openCloseCart"
                >
                  <i class="fa-solid fa-times"></i>
                </button>
              </div>
            </div>
          </header>
        </div>
        <div class="grid flex-1 p-4 w-full overflow-y-auto">
          <div class="">
            <h3
              class="text-sm text-gray-600 font-semibold rtl:font-bold"
              v-if="false"
            >
              Bills
            </h3>
            <div class="gird mt-2 mb-4" v-if="false">
              <div class="flex flex-wrap gap-3">
                <div>
                  <button
                    class="
                      p-2
                      px-3
                      rounded-md
                      bg-primary
                      text-white text-xs
                      font-medium
                      rtl:font-semibold
                    "
                    @click.prevent="addNewInvoice"
                  >
                    <i class="fa-solid fa-plus"></i>
                  </button>
                </div>
                <div v-for="(bill, idx) in invoices" :key="idx">
                  <button
                    class="
                      p-2
                      px-3
                      rounded-md
                      text-xs
                      font-medium
                      rtl:font-semibold
                    "
                    :class="{
                      'bg-primary text-white': currentInvoice === idx,
                      'bg-gray-100 text-gray-500': currentInvoice !== idx,
                    }"
                    @click.prevent="currentInvoice = idx"
                  >
                    #{{ idx + 1 }}
                  </button>
                </div>
              </div>
            </div>
            <div class="">
              <h3 class="text-sm text-gray-600 font-semibold rtl:font-bold">
                Cart items ({{ (cart || []).length || 0 }})
              </h3>
              <div
                class="
                  flex
                  items-start
                  gap-3
                  mt-4
                  border-b border-gray-100
                  py-4
                "
                v-for="(item, index) in cart || []"
                :key="index"
              >
                <div>
                  <div class="w-14 h-14">
                    <img
                      :src="item.image"
                      alt="profile image"
                      class="
                        w-full
                        h-full
                        object-cover
                        rounded-md
                        border border-gray-200
                      "
                    />
                  </div>
                </div>
                <div class="flex-1">
                  <div class="flex items-center gap-2">
                    <div class="flex-1">
                      <h3
                        class="
                          text-sm text-gray-800
                          font-semibold
                          rtl:font-bold
                          line-clamp-2
                        "
                      >
                        {{ item.name_en }}
                      </h3>
                    </div>
                    <div class="self-start">
                      <button
                        class="
                          p-1
                          text-xs text-red-500
                          font-medium
                          rtl:font-semibold
                        "
                        @click.prevent="deleteItem(item.id)"
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                  <p
                    class="
                      text-xs text-gray-600
                      font-medium
                      rtl:font-semibold
                      mt-1
                    "
                  >
                    {{ Number(item.price * item.qty).toFixed(2) }} SAR
                  </p>
                  <div class="mt-2 table">
                    <div class="flex items-center gap-2">
                      <div>
                        <button
                          class="w-8 h-8 rounded bg-primary text-white"
                          @click="controlItemQty('increase', item.id)"
                        >
                          <i class="fa-solid fa-plus text-xs"></i>
                        </button>
                      </div>
                      <div>
                        <input
                          type="number"
                          name="proitem"
                          id="proitem"
                          class="
                            w-14
                            text-xs
                            pr-0
                            rtl:pl-0 rtl:pr-4
                            px-4
                            h-8
                            rounded
                            bg-gray-100
                          "
                          v-model="item.qty"
                        />
                      </div>
                      <div>
                        <button
                          class="w-8 h-8 rounded bg-primary text-white"
                          @click="controlItemQty('decrease', item.id)"
                        >
                          <i class="fa-solid fa-minus text-xs"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer class="w-full p-4 pb-0">
          <table class="w-full">
            <thead class="border-dashed border-b-2 border-gray-200">
              <tr>
                <th
                  class="
                    text-base text-gray-800 text-left
                    font-medium
                    rtl:font-semibold
                    py-2
                    px-4
                  "
                >
                  Products count
                </th>
                <th
                  class="
                    text-gray-400 text-sm text-right
                    font-medium
                    rtl:font-semibold
                    py-2
                    px-4
                  "
                >
                  {{ (cart || []).length }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  class="
                    text-base text-gray-800 text-left
                    font-medium
                    rtl:font-semibold
                    py-2
                    px-4
                  "
                >
                  total
                </td>
                <td
                  class="
                    text-gray-400 text-sm text-right
                    font-medium
                    rtl:font-semibold
                    py-2
                    px-4
                  "
                >
                  {{ getTotal() }}
                </td>
              </tr>
            </tbody>
          </table>
          <div class="w-full p-4 border-t border-gray-200">
            <button
              class="
                w-full
                py-3
                px-4
                rounded-md
                bg-primary
                text-white text-center
                font-medium
                rtl:font-semibold
                text-sm
              "
              @click.prevent="openDoOrder"
              :disabled="!(cart || []).length"
            >
              Checkout {{ getTotal() }} SAR
            </button>
          </div>
        </footer>
      </div>
    </div>
    <button
      class="
        w-12
        h-12
        rounded-full
        bg-white
        text-gray-600
        border border-gray-200
        fixed
        bottom-5
        right-5
        z-[5]
      "
      @click="openCloseCart"
    >
      <span
        class="
          absolute
          -top-1
          -left-1
          py-0.5
          px-1.5
          rounded-full
          text-xs
          font-medium
          text-white
          bg-primary
        "
      >
        {{ (cart || []).length || 0 }}
      </span>
      <i class="fa-solid fa-shopping-bag"></i>
    </button>
    <Modal
      v-if="order_active"
      :title="`Checkout - ${getTotal() || 0}SAR`"
      @close="
        (e) => {
          order_active = e;
          add_data = {
            payment_option: 'cash',
          };
          add_errors = {};
        }
      "
    >
      <template>
        <div class="w-full space-y-4 p-6">
          <div class="w-full space-y-3">
            <label
              for="branch"
              class="font-medium rtl:font-semibold text-gray-600 text-sm"
            >
              Branch
            </label>
            <t-rich-select
              :options="branches"
              placeholder="Select"
              :text-attribute="'name_' + $i18n.locale"
              value-attribute="id"
              :clear-able="true"
              :closeOnSelect="true"
              v-model="add_data.branch_id"
            />
            <span class="error">
              {{ (add_errors.branch_id || []).join(" ") }}
            </span>
          </div>
          <div class="w-full">
            <div class="flex gap-2 flex-wrap items-center">
              <div
                v-for="(item, index) in payments"
                :key="index"
                class="checkitem"
              >
                <input
                  type="radio"
                  name="payments"
                  :id="item"
                  hidden
                  class="hidden"
                  :value="item"
                  v-model="add_data.payment_option"
                />
                <label :for="item" class="space-y-2 border-none w-20 h-12">
                  <img
                    :src="getPaymentImage(item)"
                    alt="payment item"
                    class="w-full h-full object-contain table mx-auto"
                  />
                </label>
              </div>
            </div>
            <p class="error">
              {{ (add_errors.payment_option || []).join(" ") }}
            </p>
          </div>
          <div
            class="flex items-start gap-3 mt-4 border-b border-gray-100 py-4"
            v-for="(item, index) in cart || []"
            :key="index"
          >
            <div>
              <div class="w-14 h-14">
                <img
                  :src="item.image"
                  alt="profile image"
                  class="
                    w-full
                    h-full
                    object-cover
                    rounded-md
                    border border-gray-200
                  "
                />
              </div>
            </div>
            <div class="flex-1">
              <div class="flex items-center gap-2">
                <div class="flex-1">
                  <h3
                    class="
                      text-sm text-gray-800
                      font-semibold
                      rtl:font-bold
                      line-clamp-2
                    "
                  >
                    {{ item.name_en }}
                  </h3>
                </div>
                <div class="self-start">
                  <button
                    class="
                      p-1
                      text-xs text-red-500
                      font-medium
                      rtl:font-semibold
                    "
                    @click.prevent="deleteItem(item.id)"
                  >
                    Delete
                  </button>
                </div>
              </div>
              <p
                class="text-xs text-gray-600 font-medium rtl:font-semibold mt-1"
              >
                {{ Number(item.price * item.qty).toFixed(2) }} SAR
              </p>
              <p class="error mt-2" v-if="add_errors[`products.${index}.id`]">
                {{ (add_errors[`products.${index}.id`] || []).join(" ") }}
              </p>
            </div>
          </div>
          <table class="w-full styled-table">
            <tbody>
              <tr class="!border-dashed !border-b-2 border-gray-200">
                <th>Products count</th>
                <th class="text-gray-400">
                  {{ (cart || []).length }}
                </th>
              </tr>
              <tr class="!bg-transparent">
                <td>Total</td>
                <td class="text-gray-400">
                  {{ getTotal() }}
                </td>
              </tr>
            </tbody>
          </table>
          <div class="flex items-start justify-start gap-2 flex-wrap">
            <div class="flex-1">
              <button
                class="
                  w-full
                  py-3
                  px-4
                  text-sm
                  font-medium
                  rtl:font-semibold
                  text-white
                  bg-primary
                  rounded-md
                "
                :disabled="add_disabled"
                @click.prevent="doOrder"
              >
                <i class="fa-solid fa-spinner fa-spin" v-if="add_disabled"></i>
                Request order
              </button>
            </div>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import Modal from "@/components/basics/Modal.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  components: { Modal },
  name: "WarehouseProducts",
  data() {
    return {
      items: [],
      load: false,
      order_active: false,
      add_data: {
        payment_option: "cash",
        products: [],
      },
      payments: ["cash", "visa", "mada"],
      add_errors: {},
      branches: [],
      add_disabled: false,
    };
  },

  async created() {
    Promise.all([await this.getItems(), await this.getBranches()]).finally(
      () => (this.load = true)
    );
  },
  computed: {
    ...mapGetters("warehouseCart", {
      globalCart: "wareCart",
    }),

    cart() {
      const user = this.$store.state.user;
      return this.globalCart(`${user.user_type}_${user.id}`) || [];
    },
    user() {
      return this.$store.state.user || {};
    },
    itemId() {
      const id = this.$route.params.id;

      this.add_data.warehouse_id = id;
      return id;
    },
  },
  methods: {
    ...mapActions("warehouseCart", [
      "addItemsInsideWareCart",
      "removeItemFromWareCart",
    ]),
    ...mapActions("getting", ["getRoute"]),
    ...mapMutations("warehouseCart", ["GENERATE_WARE_CART"]),
    async getBranches() {
      try {
        const { result } = await this.getRoute("branches");
        this.branches = result.branches;
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },
    async getSuppliers() {
      try {
        const { result } = await this.getRoute("suppliers");
        this.suppliers = result.suppliers;
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },
    async getItems() {
      try {
        const { result } = await this.getRoute({
          name: "products",
          options: {
            warehouse_id: this.itemId,
          },
        });
        this.items = result.products;
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
        // this.$router.push("/orders/new");
      }
    },
    addToCart(id) {
      const product = this.items.find((e) => e.id === id);
      if (!product) {
        this.createAlert("Invalid product", "error");
        return;
      }

      const currentCart = this.cart;
      const currentProduct = currentCart?.find((e) => e.id === id);
      // Check the quantity of the product before adding it to the cart
      if (currentProduct) {
        if (currentProduct.qty === +product.qty) {
          this.createAlert(`Max quantity is ${product.qty}`, "info");
          return;
        }
      }
      this.addItemsInsideWareCart({ product, id }).then(() => {
        this.createAlert(
          currentProduct
            ? "Increased new quantity"
            : "Added to cart successfully"
        );
      });
    },
    controlItemQty(type, id) {
      const product = this.items.find((e) => e.id === id);
      if (!product) {
        this.createAlert("Invalid product", "error");
        return;
      }

      const currentCart = this.cart;
      const currentProduct = currentCart?.find((e) => e.id === id);
      // Check the quantity of the product before adding it to the cart

      // increase
      // decrease
      if (type === "increase") {
        if (currentProduct) {
          if (currentProduct.qty >= +product.qty) {
            this.createAlert(`Max quantity is ${product.qty}`, "info");
            return;
          }
        }
        this.addItemsInsideWareCart({
          product: {
            ...product,
            qty: currentProduct ? currentProduct.qty + 1 : 1,
          },
          id,
        });

        return;
      } else if (type === "decrease") {
        if (currentProduct) {
          if (currentProduct.qty <= 1) {
            this.createAlert(`Min quantity is 1`, "info");
            return;
          }
        }
        this.addItemsInsideWareCart({
          product: {
            ...product,
            type: "decrease",
          },
          id,
        });
      }
    },
    deleteItem(id) {
      const product = this.items.find((e) => e.id === id);
      if (!product) {
        this.createAlert("Invalid product", "error");
        return;
      }
      this.removeItemFromWareCart(id).then((result) =>
        this.createAlert(result)
      );
    },
    getTotal() {
      let total = 0;
      this.cart &&
        this.cart.forEach((product) => {
          total += Number(product.price) * product.qty;
        });
      return total.toFixed(2);
    },
    openDoOrder() {
      this.add_data.products = this.cart;
      this.order_active = true;
    },
    async doOrder() {
      this.add_disabled = true;
      let fd = new FormData();
      this.add_errors = {};
      Object.keys(this.add_data).forEach((key) => {
        if (key === "products") {
          this.cart.forEach((product, idx) => {
            fd.append(`${key}[${idx}][qty]`, product.qty);
            fd.append(`${key}[${idx}][id]`, product.id);
          });
        } else if (this.add_data[key]) {
          fd.append(key, this.add_data[key]);
        }
      });
      try {
        const { data } = await this.axios.post("orders/new", fd);
        const user = this.$store.state.user;
        this.createAlert(data.message);
        this.GENERATE_WARE_CART(`${user.user_type}_${user.id}`);
        this.order_active = false;
        this.add_data = {
          products: [],
          payment_option: "cash",
        };
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.add_errors = responseError;
        } else {
          this.createAlert(reponseError, "error");
        }
      } finally {
        this.add_disabled = false;
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }
    },
    openCloseCart() {
      this.$refs.cart.classList.toggle("!block");
    },
    closeCart() {
      window.addEventListener("click", (e) => {
        if (e.target === this.$refs.cart) {
          this.$refs.cart.classList.remove("!block");
        }
      });
    },
    getPaymentImage(image) {
      try {
        return require(`@/assets/images/${image}.png`);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
