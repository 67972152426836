<template>
  <div>
    <section class="w-full space-y-6 p-6">
      <header class="w-full">
        <div class="flex-1 relative">
          <input
            type="text"
            name="search"
            id="search"
            class="w-full bg-white rounded-md p-4 text-sm pl-10 rtl:pl-auto rtl:pr-10"
            placeholder="Search..."
          />
          <span
            class="absolute top-0 left-0 rtl:left-auto rtl:right-0 h-full w-10 flex items-center justify-center text-gray-500 text-sm"
          >
            <!-- <i class="fa-solid fa-spinner fa-spin" v-if="search_disabled"></i> -->
            <i class="fa-solid fa-search"></i>
          </span>
        </div>
      </header>
      <main class="space-y-4">
        <figure class="w-full rounded-xl bg-white" v-for="i in 10" :key="i">
          <blockquote class="p-5 flex gap-4">
            <div>
              <div
                class="w-12 h-12 rounded-full bg-gray-200 text-gray-600 flex items-center justify-center"
              >
                <i class="fa-solid fa-fire-flame-curved"></i>
              </div>
            </div>
            <div class="space-y-2 flex-1">
              <h1 class="font-semibold rtl:font-bold">New orders</h1>
              <p class="text-sm font-medium rtl:font-semibold text-gray-600">
                Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                Sapiente culpa quo quae, eligendi dicta tempora expedita
                pariatur veniam corrupti? Reiciendis praesentium assumenda quae
                voluptatem libero doloremque magni perferendis ex corporis!
              </p>
              <p class="text-xs font-medium rtl:font-semibold text-gray-600">
                <a
                  href="http://appligates.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="underline text-primary"
                  >Appligates</a
                >
                • <span>{{ new Date() | moment(" ddd DD MMM, YYYY") }}</span>
              </p>
            </div>
          </blockquote>
        </figure>
        <div class="table mx-auto !py-6">
          <!-- v-model="currentPage"
            :page-count="totalPages" -->
          <paginate
            :page-range="5"
            :margin-pages="2"
            :prev-text="'Previous'"
            :next-text="'Next'"
            :container-class="'pagination'"
            :page-class="'page-item'"
          >
          </paginate>
        </div>
      </main>
    </section>
  </div>
</template>

<script>
export default {
  name: "Notifications",
};
</script>
