<template>
   <div class="space-y-1" >
            <p class="text-sm text-gray-600 font-semibold">
              <span>{{ progress }}</span>
              <span> / </span>
              <span>100</span>
            </p>
            <div
              class="p-px rounded bg-gray-200 w-full flex items-center justify-start"
            >
              <span
                class="h-4 rounded bg-teal-600 transition-all flex"
                :style="{ width: progress + '%' }"
              ></span>
            </div>
          </div>
</template>

<script>
    export default {
        name: "ProgressBar",
        props: ['progress']
    }
</script>

