var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ul',{staticClass:"list-none space-y-2"},_vm._l((_vm.items),function(item,index){return _c('li',{key:index,staticClass:"block w-full"},[(item.flag)?_c('div',{staticClass:"py-3 border-y border-y-slate-200 my-10"},[_c('div',{staticClass:"table mx-auto p-3 rounded bg-slate-200"},[_c('p',{staticClass:"font-medium rtl:font-semibold text-sm"},[_vm._v(" "+_vm._s(item.content)+" ")])])]):_c('div',{staticClass:"table max-w-[80%] space-y-2",class:item.own_reply
            ? '[margin-inline-start:auto]'
            : '[margin-inline-end:auto]'},[_c('div',{directives:[{name:"tippy",rawName:"v-tippy"}],staticClass:"table w-auto p-2 rounded",class:item.own_reply
              ? 'bg-primary text-white [margin-inline-start:auto]'
              : 'border border-gray-200 [margin-inline-end:auto]',attrs:{"content":_vm._f("moment")(item.created_at,'from')}},[_c('p',{staticClass:"font-medium rtl:font-semibold text-sm"},[_vm._v(" "+_vm._s(item.body)+" ")])]),(item.attachments.length)?_c('div',{staticClass:"actions-group"},_vm._l((item.attachments),function(attachment,idx){return _c('div',{key:idx},[_c('a',{directives:[{name:"tippy",rawName:"v-tippy"}],staticClass:"table p-2 rounded bg-slate-200",attrs:{"href":attachment.path,"content":attachment.mime,"target":"_blank","rel":"noopener noreferrer"}},[_c('p',{staticClass:"font-medium rtl:font-semibold text-sm"},[_vm._v(" "+_vm._s(attachment.name)+" "+_vm._s(_vm.generateSize(attachment.size))+" ")])])])}),0):_vm._e(),(item.last_msg)?_c('p',{staticClass:"font-medium rtl:font-semibold text-xs text-gray-500 table",class:item.own_reply
              ? '[padding-inline-start:0.5rem] [margin-inline-end:auto]'
              : '[padding-inline-end:0.5rem] [margin-inline-start:auto]'},[_vm._v(" "+_vm._s(_vm._f("moment")(item.created_at,"h:mmA"))+" ")]):_vm._e()])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }