<template>
  <div>
    <t-rich-select
      :multiple="true"
      v-model="values"
      :fetch-options="fetch"
      :options="options"
      :text-attribute="text"
      :value-attribute="id"
      :placeholder="placeholder"
      :clear-able="true"
      :closeOnSelect="false"
      @change="$emit('input', $event)"
      v-bind="$attrs"
    >
      <template slot="label" slot-scope="{ options }">
        <div class="actions-group flex-wrap !gap-1 items-center">
          <button
            tabindex="-1"
            type="button"
            class="
              bg-primary
              text-white
              py-[0.170rem]
              px-1.5
              rounded
              flex
              gap-2
              text-left
              rtl:text-right
              items-center
            "
            v-for="(item, index) in limit && limit > 0
              ? options.slice(0, limit)
              : options"
            :key="index"
          >
            <span class="text-[10px] font-medium rtl:font-semibold">{{
              item.raw[text]
            }}</span
            ><button
              tabindex="-1"
              class="w-3 h-3 block"
              @click.prevent="
                $emit(
                  'input',
                  options
                    .filter((e) => e.raw.id !== item.raw.id)
                    .map((e) => e.raw.id)
                )
              "
            >
              <svg
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
                class="w-full h-full"
              >
                <path
                  fill-rule="evenodd"
                  evenodd="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                ></path>
              </svg>
            </button>
          </button>
          <span
            class="text-xs font-medium rtl:font-semibold"
            v-if="limit && options.length > limit"
          >
            +{{ options.length - limit }}
          </span>
        </div>
      </template>
    </t-rich-select>
  </div>
</template>

<script>
export default {
  name: "SelectWithLimit",
  props: {
    options: {
      type: Array,
      default: Array,
    },
    text: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    id: {
      type: [String, Number],
      default: null,
    },
    value: {
      type: [String, Array, Number, Object],
      default: null,
    },
    limit: {
      type: Number,
      default: null,
    },
    fetch: {
      type: Function,
      default: undefined,
    },
  },
  data() {
    return {
      values: this.value,
    };
  },
  watch: {
    value(val) {
      this.values = val;
    },
  },
};
</script>
