export default {
  dashboard: "Dashboard",
  calendar: "Calendar",
  users: "Users",
  user_visits: "User visits",
  user: "User",
  new_visit: "New visit",
  reports: "Reports",
  visits: "Visits",
  visit: "Visit",
  skus: "SKUs",
  expiry: "Expiry",
  expiries: "Expiries",
  stock_audits: "Stock audits",
  shelf_audits: "Shelf audits",
  shelf: "Shelf",
  returns: "Returns",
  promotion: "Promotion",
  planogram: "Planogram",
  setup: "Setup",
  journey_planes: "Journey planes",
  configuration: "Configuration",
  shelves: "Shelves",
  planograms: "Planograms",
  teams: "Teams",
  permissions: "Permissions",
  stores: "Stores",
  store: "Store",
  branches: "Branches",
  branches_count: "Branches count",
  branch: "Branch",
  brands: "Brands",
  brand: "Brand",
  categories: "Categories",
  category: "Category",
  sub_categories: "Sub categories",
  sub_category: "Sub category",
  store_placements: "Store placements",
  store_placement: "Store placement",
  units: "Units",
  unit: "Unit",
  notifications: "Notifications",
  messages: "Messages",
  regions: "Regions",
  region: "Region",
  cities: "Cities",
  city: "City",
  expiry_skus: "Expiry SKUs",
  out_of_stock: "Out of stock",
  supervisors: "Supervisors",
  supervisor: "Supervisor",
  leaders: "Leaders",
  leader: "Leader",
  sku: "SKU",
  total_visits_per_week: "Total visits per week",
  visits_per_status: "Visits per status",
  visits_per_day: "Visits per day",
  total_visits: "Total visits",
  visit_summary: "Visits summary",
  recent_visits: "Recent visits",
  done: "Done",
  missed: "Missed",
  pending: "Pending",
  extra_mission: "Extra mission",
  extra_mission_done: "Extra mission done",
  added_by_user: "Added by user",
  skipped: "Skipped",
  cancelled: "Cancelled",
  scheduled: "Scheduled",
  active: "Active",
  not_active: "Not active",
  available: "Available",
  not_available: "Not available",
  yes: "Yes",
  No: "No",
  customize: "Customize",
  download: "Download",
  send_email: "Send via email",
  filter: "Filter",
  add_new: {
    category: "New category",
    sub_category: "New sub category",
    store: "New store",
    branch: "New branch",
    brand: "New brand",
    team: "New team",
    user: "New user",
    merchandiser: "New merchandiser",
    client: "New client",
    supervisor: "New supervisor",
    visit: "New visit",
    sku: "New sku",
    shelf: "New shelf",
    planogram: "New planogram",
    new: "Add new",
    now: "Add now",
    company: "New company",
    country: "New country",
    region: "New region",
    city: "New city",
    store_placement: "New store placement",
    task: "New task",
    unit: "New unit",
    admin: "New admin",
    role: "New role",
    zone: "New zone",
    district: "New district",
    tutorial: "New Tutorial",
    group: "New group",
    partner: "New partner",
    page: "New page",
  },
  update: "Update",
  assign_branches: "Assign to branches",
  assign_units: "Assign units",
  assign_info: "Assignee info",
  client_info: "Client info",
  assignee: "Assignee",
  delete: "Delete",
  add: "Add",
  save: "Save",
  save_changes: "Save changes",
  submit: "Submit",
  cancel: "Cancel",
  name: "Name",
  alt_name: "Alt name",
  email: "E-mail address",
  mobile: "Mobile",
  barcode: "Barcode",
  selling_price: "Selling price",
  value: "Value",
  actual_value: "Actual value",
  tasks: "Tasks",
  required_tasks: "Required tasks",
  visit_date: "Visit date",
  created_at: "Created date",
  updated_at: "Last update",
  start_date: "Start date",
  production_date: "Production date",
  end_date: "End date",
  return_date: "Return date",
  expiry_date: "Expiry date",
  joined_date: "Joined date",
  qty: "Quantity",
  in_location: "In location",
  out_location: "Out location",
  search: "Search...",
  status: "Status",
  create_new: {
    category: "Create new category",
    sub_category: "Create new sub category",
    store: "Create new store",
    branch: "Create new branch",
    brand: "Create new brand",
    team: "Create new team",
    user: "Create new user",
    visit: "Create new visit",
    sku: "Create new sku",
    shelf: "Create new shelf",
    planogram: "Create new planogram",
  },
  currency: {
    sar: "SAR",
  },
  next: "Next",
  prev: "Previous",
  view_details: "View details",
  notes: "Notes",
  reason: "Reason",
  apply: "Apply",
  reset: "Reset",
  image: "Image",
  images: "Images",
  images_before: "Images before",
  images_after: "Images after",
  type: "Type",
  visit_type: "Visit type",
  change_status: "Change status",
  view: "View",
  view_update: "View and update",
  listed: "Listed branches",
  manager_mobile: "Branch manager mobile",
  address: "Address",
  lat: "latitude",
  lng: "longitude",
  lat_lng: "latitude and longitude",
  logout: "Logout",
  edit: "Edit",
  edit_profile: "Edit profile",
  window_width: "Window width",
  view_all: "View all",
  all: "All",
  langs: {
    ar: "Arabic",
    en: "English",
  },
  emails: "E-mail addresses",
  customize_excel: "Customize EXCEL columns",
  customize_table: "Customizing table",
  total_skus: "Total SKUs",
  total_visits: "Total visits",
  records: "Records",
  password: "Password",
  new_password: "New password",
  current_password: "Current password",
  confirmation_password: "Confirmation password",
  generate_password: "Generate password",
  force_password: "Force to change password",
  multi_client: "Multi clients",
  no_data: "There are no data to display",
  role: "Role",
  username: "Username",
  merchandiser: "Merchandiser",
  merchandisers: "Merchandisers",
  supervisor: "Supervisor",
  super_user: "Super user",
  client: "Client",
  clients: "Clients",
  administration: "Administration",
  week_days: {
    SAT: "SAT",
    SUN: "SUN",
    MON: "MON",
    TUE: "TUE",
    WED: "WED",
    THU: "THU",
    FRI: "FRI",
  },
  visit_time: {
    today: "Today",
    weekly: "Weekly",
    monthly: "Monthly",
  },
  weeks: {
    1: "1st week",
    2: "2th week",
    3: "3th week",
    4: "4th week",
  },
  choose_image: "Choose image",
  choose_photo: "Choose photo",
  profile_photo: "Profile photo",
  all_selected: "All items selected",
  email_note: "Exit the account will be logged out if you change the email",
  visits_no_data: "There are no visits on that day",
  user_no: "There are no users to display",
  user_no_content: `There are no users to display their visits, so you can add a
                  new visit and choose the required branch and then select the
                  visits, click the button below to go to the new visit page`,
  completed: "Completed",
  login: "Login",
  copyRight: "Copyright all rights reserved by",
  cost: "Cost",
  add_info_visit: "Do you want to add a new visit,",
  location_empty: "The location has not been determined by the user yet",
  join_requests: "Join requests",
  countries: "Countries",
  admins: "Admins",
  companies: "Companies",
  accepted: "Accepted",
  rejected: "Rejected",
  rejected_reason: "Rejected reason",
  accept: "Accept",
  reject: "Reject",
  country: "Country",
  permissions: "Permissions",
  permission: "Permission",
  roles: "Roles",
  select_all: "Select all",
  import: "Import",
  contact_name: "Contact name",
  latest_companies: "Latest companies",
  latest_join_requests: "Latest join requests",
  listed_stores: "Listed Stores",
  listed_branches: "Listed Branches",
  show: "Show",
  hide: "Hide",
  new: "New",
  zones: "Zones",
  merchandisers_count: "Merchandisers count",
  active_merchandisers: "Active merchandisers",
  not_active_merchandisers: "Not active merchandisers",
  domain: "Domain",
  client_users: "Client users",
  go_website: "Go to website",
  modules: "Modules",
  title: "Title",
  keywords: "Keywords",
  tutorials: "Tutorials",
  video_url: "Video url",
  requested_branches: "Requests to add branches",
  add_data: "Add data",
  select: "Select",
  region_groups: "Region groups",
  district_group: "District groups",
  clear_all: "Clear all",
  districts: "Districts",
  settings: "Settings",
  logs: "Logs",
  deletion: {
    title: "Are you sure you want to delete it?",
    content:
      "If you still want to delete it, you no longer have the authority to reaccess it, and everything related to it will be deleted",
    code: "Please enter the code sent via e-mail, and the deletion process will be confirmed",
  },
  wrong_location: "Wrong location",
  partners: "Partners",
  pages: "Pages",
  content: "Content",
  desc: "Description",
  // {{ $t(item.replace(/\s/g, '_')) }}
};
