<template>
  <div>
    <button
      class="text-center font-medium rtl:font-semibold flex items-center justify-center gap-2"
      :class="btnClass"
      :disabled="disabled"
      @click.prevent="$emit('click')"
      v-bind="$attrs"
    >
      <spinner
        size="w-4 h-4"
        class="inline-block align-middle"
        v-if="disabled"
      />
      <slot v-else></slot>
    </button>
  </div>
</template>

<script>
export default {
  name: "ButtonWithLoader",
  props: {
    disabled: { type: Boolean, default: false },
    btnClass: {
      type: String,
      default: null,
    },
  },
};
</script>
