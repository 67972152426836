export default {
  namespaced: true,
  state: {
    message: null,
  },
  getters: {
    getMessage: (state) => state.message,
  },
  mutations: {
    UPDATE_MESSAGE(state, data) {
      state.message = data;
    },
  },
  actions: {},
  modules: {},
};
