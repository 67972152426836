<template>
  <!-- footer -->
  <footer class="w-full bg-white">
    <div class="w-full p-6 text-center font-medium rtl:font-semibold text-sm">
      جميع الحقوق محفوظة © 2022 لدى
      <a
        href="https://appli-gate.com"
        class="text-[#45c1bd] under-line font-semibold"
      >
        AppliGate
      </a>
    </div>
  </footer>
  <!-- ./footer -->
</template>
<script>
export default {};
</script>
