<template>
  <div>
    <section class="p-6 flex gap-6 flex-col lg:flex-row">
      <aside
        class="flex-1 w-full z-20 lg:max-w-sm fixed inset-0 bg-gray-800/50 lg:bg-transparent lg:sticky lg:top-6 lg:max-h-[calc(100vh-50px)] transition-all opacity-0 pointer-events-none invisible lg:opacity-100 lg:pointer-events-auto lg:visible left-full lg:left-0"
        ref="asideMenu"
      >
        <div
          class="bg-white lg:bg-transparent max-w-sm lg:w-full h-full flex flex-col"
        >
          <header
            class="flex lg:hidden p-4 items-center flex-wrap border-b border-gray-100"
          >
            <div class="flex-1">
              <h1 class="font-bold text-sm text-gray-600">Invoice menu</h1>
            </div>
            <div>
              <button
                class="w-8 h-8 rounded-full flex items-center justify-center text-sm bg-gray-100 text-gray-600"
                @click.prevent="openCloseAsideMenu"
              >
                <i class="fa-solid fa-times"></i>
              </button>
            </div>
          </header>
          <header class="w-full p-4 lg:p-0 !mt-0 lg:!mb-4 space-y-4">
            <div class="w-full">
              <div class="table mx-auto">
                <div class="flex items-center overflow-x-auto">
                  <div>
                    <router-link
                      to="/invoices/products"
                      class="py-2 px-6 text-center block text-gray-600 text-sm font-medium border-b border-gray-200"
                      exact-active-class="!text-primary border-primary"
                      >Per product</router-link
                    >
                  </div>
                  <div>
                    <router-link
                      to="/invoices"
                      class="py-2 px-6 text-center block text-gray-600 text-sm font-medium border-b border-gray-200"
                      exact-active-class="!text-primary border-primary"
                      >Per invoice</router-link
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="flex-1 relative">
              <input
                type="text"
                name="search"
                id="search"
                class="w-full bg-gray-100 lg:bg-white h-12 rounded-md px-4 text-sm pl-10 rtl:pl-auto rtl:pr-10"
                placeholder="Search in customers..."
              />
              <span
                class="absolute top-0 left-0 rtl:left-auto rtl:right-0 h-full w-10 flex items-center justify-center text-gray-500 text-sm"
              >
                <i class="fa-solid fa-spinner fa-spin" v-if="false"></i>
                <i class="fa-solid fa-search" v-else></i>
              </span>
            </div>
          </header>

          <div
            class="flex-1 w-full lg:space-y-4 overflow-y-auto pt-0 p-4 lg:px-0"
          >
            <figure
              class="w-full rounded-md overflow-hidden"
              v-for="i in 5"
              :key="i"
              ref="invoiceItem"
            >
              <figcaption class="bg-white">
                <div class="w-full p-4 flex items-center gap-4 text-gray-600">
                  <div>
                    <div class="w-12 h-12">
                      <img
                        src="@/assets/images/banner.jpg"
                        alt="product image"
                        class="w-full h-full object-cover rounded"
                      />
                    </div>
                  </div>
                  <div class="flex-1 space-y-1">
                    <div class="grid">
                      <p class="text-sm truncate font-medium text-primary">
                        Lorem, ipsum dolor sit amet consectetur adipisicing
                        elit. Voluptatibus, earum.
                      </p>
                    </div>
                    <p class="text-xs font-medium !text-gray-400">
                      {{ new Date() | moment(" h:mm a . MMMM Do YYYY") }}
                    </p>
                  </div>
                  <div>
                    <button
                      class="w-6 h-6 flex items-center justify-center"
                      @click.prevent="openDrop"
                    >
                      <i class="fa-solid fa-angle-down"></i>
                    </button>
                  </div>
                </div>
              </figcaption>
              <blockquote
                class="border-b border-gray-100 rounded-t-lg lg:border-0 lg:rounded-md bg-white h-0 transition-all max-h-screen space-y-2"
              >
                <p
                  class="text-sm font-medium text-primary"
                  data-type="order_no"
                >
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Velit, temporibus.
                </p>
                <div class="flex gap-2 items-center">
                  <div class="flex-1">
                    <p class="text-sm font-medium text-gray-500">
                      Quantity: <span class="text-gray-600">{{ i * 1e1 }}</span>
                    </p>
                  </div>
                  <div class="flex-1">
                    <p class="text-xs font-medium text-gray-500">
                      Total:
                      <span class="text-gray-600 text-sm"
                        >{{ i * 1e1 }} SAR</span
                      >
                    </p>
                  </div>
                </div>

                <p
                  class="text-xs font-medium !text-gray-400"
                  data-type="order_date"
                >
                  {{ new Date() | moment(" h:mm a . MMMM Do YYYY") }}
                </p>
              </blockquote>
            </figure>
          </div>
        </div>
      </aside>
      <main class="w-full flex-1">
        <div class="w-full space-y-6">
          <header
            class="flex lg:hidden p-4 items-center flex-wrap bg-white rounded"
          >
            <div class="flex-1">
              <h1 class="font-bold text-sm text-gray-600">Products menu</h1>
            </div>
            <div>
              <button
                class="w-8 h-8 rounded-full flex items-center justify-center text-sm bg-gray-100 text-gray-600"
                @click.prevent="openCloseAsideMenu"
              >
                <i class="fa-solid fa-up-right-from-square"></i>
              </button>
            </div>
          </header>
          <figure class="w-full rounded bg-white">
            <blockquote class="flex flex-col sm:flex-row gap-6 p-4 sm:p-6">
              <div>
                <div class="bg-gray-100 w-full max-w-[10rem] rounded">
                  <img
                    src="@/assets/images/banner.jpg"
                    alt="product image"
                    class="max-w-full h-full rounded object-contain"
                  />
                </div>
              </div>
              <div class="flex-1">
                <ul class="list-none space-y-1">
                  <li>
                    <p class="text-primary font-semibold">
                      Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                      Commodi, harum?
                    </p>
                  </li>
                  <li>
                    <p class="text-gray-600 font-medium text-sm line-clamp-2">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Temporibus distinctio officiis impedit consectetur!
                      Facilis, repellat! Repellat inventore facere consequuntur
                      dolores ipsum dolor perferendis, repudiandae fugit iure
                      sapiente quam aspernatur eius?
                    </p>
                  </li>
                  <li>
                    <p class="text-gray-600 font-medium text-sm">
                      Quantity:
                      <span class="text-primary font-semibold">10</span>
                    </p>
                  </li>
                  <li>
                    <p class="text-gray-600 font-medium text-sm">
                      Total:
                      <span class="text-primary font-semibold">100 SAR</span>
                    </p>
                  </li>
                </ul>
              </div>
            </blockquote>
          </figure>
          <div class="table mx-auto">
            <div class="flex items-center gap-1 flex-wrap">
              <div
                class="w-16 h-16 p-1 border border-gray-200 rounded"
                v-for="k in 5"
                :key="k"
              >
                <img
                  src="@/assets/images/banner.jpg"
                  alt="product image"
                  class="max-w-full h-full rounded object-contain"
                />
              </div>
            </div>
          </div>
          <div class="table mx-auto">
            <div class="flex items-center gap-1 flex-wrap">
              <div
                class="py-2 px-4 text-sm bg-primary text-white font-medium rounded-sm"
                v-for="k in 10"
                :key="k"
              >
                Keyword
              </div>
            </div>
          </div>
          <figure class="w-full rounded bg-white">
            <header
              class="flex px-6 py-4 items-center flex-wrap border-b border-gray-100"
            >
              <div class="flex-1">
                <h1 class="font-bold text-primary">Product details</h1>
              </div>
            </header>
            <blockquote class="px-6 py-4">
              <ul class="w-full list-none">
                <li
                  class="flex py-4 items-center flex-wrap border-b border-gray-100 last:border-0 text-sm"
                >
                  <div class="flex-1">
                    <h1 class="font-bold">Category</h1>
                  </div>
                  <div>
                    <p class="font-bold text-gray-600">Category name</p>
                  </div>
                </li>
                <li
                  class="flex py-4 items-center flex-wrap border-b border-gray-100 last:border-0 text-sm"
                >
                  <div class="flex-1">
                    <h1 class="font-bold">Sub category name</h1>
                  </div>
                  <div>
                    <p class="font-bold text-gray-600">Sub category name</p>
                  </div>
                </li>
                <li
                  class="flex py-4 items-center flex-wrap border-b border-gray-100 last:border-0 text-sm"
                >
                  <div class="flex-1">
                    <h1 class="font-bold">Brand</h1>
                  </div>
                  <div>
                    <p class="font-bold text-gray-600">Brand name</p>
                  </div>
                </li>
              </ul>
            </blockquote>
          </figure>
          <figure class="w-full rounded bg-white">
            <header
              class="flex px-6 py-4 items-center flex-wrap border-b border-gray-100"
            >
              <div class="flex-1">
                <h1 class="font-bold text-primary">Price details</h1>
              </div>
            </header>
            <blockquote class="px-6 py-4">
              <ul class="w-full list-none">
                <li
                  class="flex py-4 items-center flex-wrap border-b border-gray-100 last:border-0 text-sm"
                >
                  <div class="flex-1">
                    <h1 class="font-bold">Selling price</h1>
                  </div>
                  <div>
                    <p class="font-bold text-gray-600">100 SAR</p>
                  </div>
                </li>
                <li
                  class="flex py-4 items-center flex-wrap border-b border-gray-100 last:border-0 text-sm"
                >
                  <div class="flex-1">
                    <h1 class="font-bold">Retail price</h1>
                  </div>
                  <div>
                    <p class="font-bold text-gray-600">300 SAR</p>
                  </div>
                </li>
                <li
                  class="flex py-4 items-center flex-wrap border-b border-gray-100 last:border-0 text-sm"
                >
                  <div class="flex-1">
                    <h1 class="font-bold">Quantity</h1>
                  </div>
                  <div>
                    <p class="font-bold text-gray-600">10</p>
                  </div>
                </li>
              </ul>
            </blockquote>
          </figure>
          <figure class="w-full rounded bg-white">
            <header
              class="flex px-6 py-4 items-center flex-wrap border-b border-gray-100"
            >
              <div class="flex-1">
                <h1 class="font-bold text-primary">Date</h1>
              </div>
            </header>
            <blockquote class="px-6 py-4">
              <ul class="w-full list-none">
                <li
                  class="flex py-4 items-center flex-wrap border-b border-gray-100 last:border-0 text-sm"
                >
                  <div class="flex-1">
                    <h1 class="font-bold">Production</h1>
                  </div>
                  <div>
                    <p class="font-bold text-gray-600">
                      {{ new Date() | moment(" h:mm a . MMMM Do YYYY") }}
                    </p>
                  </div>
                </li>
                <li
                  class="flex py-4 items-center flex-wrap border-b border-gray-100 last:border-0 text-sm"
                >
                  <div class="flex-1">
                    <h1 class="font-bold">Expired date</h1>
                  </div>
                  <div>
                    <p class="font-bold text-gray-600">
                      {{ new Date() | moment(" h:mm a . MMMM Do YYYY") }}
                    </p>
                  </div>
                </li>
              </ul>
            </blockquote>
          </figure>
        </div>
      </main>
    </section>
  </div>
</template>
<script>
export default {
  name: "ProductsInvoices",
  methods: {
    openDrop(e) {
      const icon = e.currentTarget.querySelector("i");
      const figParent =
        e.currentTarget.parentElement.parentElement.parentElement.parentElement;
      const blockquote = figParent.querySelector("blockquote");
      const figcaption = figParent.querySelector("figcaption");
      if (blockquote.classList.contains("h-0")) {
        icon.classList.replace("fa-angle-down", "fa-angle-up");
        blockquote.classList.remove("h-0");
        figcaption.classList.add("pb-4");
        figcaption.classList.replace("bg-white", "bg-gray-200");
        blockquote.classList.add(...["-mt-4", "p-4"]);
      } else {
        icon.classList.replace("fa-angle-up", "fa-angle-down");
        blockquote.classList.add("h-0");
        figcaption.classList.remove("pb-4");
        figcaption.classList.replace("bg-gray-200", "bg-white");
        blockquote.classList.remove(...["-mt-4", "p-4"]);
      }
    },
    openCloseAsideMenu() {
      const parAside = this.$refs.asideMenu;
      if (parAside.classList.contains("opacity-0")) {
        parAside.classList.remove(
          ...["opacity-0", "pointer-events-none", "invisible", "left-full"]
        );
      } else {
        parAside.classList.add(
          ...["opacity-0", "pointer-events-none", "invisible", "left-full"]
        );
      }
    },
  },
};
</script>
