<template>
  <div>
    <section class="w-full p-6 grid grid-cols-1 lg:grid-cols-2 gap-4">
      <figure
        class="w-full ring-1 ring-gray-200 bg-white rounded lg:col-span-2 p-4"
      >
        <div id="chart">
          <apexchart
            type="line"
            height="350"
            :options="{
              ...chartOptions,

              ...globalOptions,
            }"
            :series="series"
          ></apexchart>
        </div>
      </figure>
      <figure class="w-full ring-1 ring-gray-200 bg-white rounded p-4">
        <div id="chartBar">
          <apexchart
            type="bar"
            height="350"
            :options="{
              ...barChart,

              ...globalOptions,
            }"
            :series="series"
          ></apexchart>
        </div>
      </figure>
      <figure class="w-full ring-1 ring-gray-200 bg-white rounded p-4">
        <div id="chartBar">
          <apexchart
            type="radialBar"
            height="350"
            :options="{
              ...radialBarChart,

              ...globalOptions,
            }"
            :series="[40, 100, 50]"
          ></apexchart>
        </div>
      </figure>
    </section>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "Analytics",
  data() {
    return {
      series: [
        {
          name: "Last week",
          data: [31, 40, 28, 51, 42, 109, 100, 50, 70, 80, 80, 10],
        },
        {
          name: "This week",
          data: [11, 32, 45, 32, 34, 52, 41, 70, 80, 10, 500, 50],
        },
      ],
      globalOptions: {
        dataLabels: {
          enabled: false,
          formatter: function (value, { seriesIndex, dataPointIndex, w }) {
            return w.config.series[seriesIndex].data[dataPointIndex];
          },
        },
        tooltip: {
          y: {
            formatter: function (val, { w }) {
              return val;
            },
          },
          style: {
            fontSize: "12px",
            fontFamily: "Montserrat, Cairo",
          },
          x: {
            show: false,
          },
          marker: {
            show: false,
          },
        },
        xaxis: {
          labels: {
            style: {
              fontSize: "12px",
              fontFamily: "Montserrat, Cairo",
              fontWeight: "bold",
            },
          },
          axisBorder: {
            show: true,
            height: 1,
            width: "100%",
            offsetX: 0,
          },
          axisTicks: {
            show: false,
          },
        },
        theme: {
          palette: "palette8",
        },
      },
      chartOptions: {
        chart: {
          type: "line",
          height: 350,
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        stroke: {
          curve: "smooth",
        },
        dataLabels: {
          enabled: false,
        },
        title: {
          text: "Sales",
          style: {
            fontSize: "24px",
            fontFamily: "Montserrat, Cairo",
            fontWeight: "bold",
          },
        },
        xaxis: {
          type: "category",
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
        },
        // labels: series.monthDataSeries1.dates,
        yaxis: {
          opposite: false,
          labels: {
            show: true,
            align: "left",
            rotate: -45,
            style: {
              fontSize: "12px",
              fontFamily: "Montserrat, Cairo",
              fontWeight: "bold",
            },
            offsetX: -5,
            offsetY: 10,
          },
        },
        legend: {
          horizontalAlign: "left",
        },
        grid: {
          show: true,
          position: "back",
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
          padding: {
            right: 0,
            left: 0,
            top: 0,
            bottom: 0,
          },
        },
        legend: {
          position: "right",
          offsetY: 20,
          markers: {
            radius: 5,
            width: 10,
            height: 10,
          },
          itemMargin: {
            // horizontal: 10,
            vertical: 5,
          },
        },
        responsive: [
          {
            breakpoint: 678,
            options: {
              cart: {
                height: "auto",
              },
              legend: {
                position: "bottom",
                offsetY: 0,

                itemMargin: {
                  horizontal: 10,
                  vertical: 0,
                },
              },
            },
          },
        ],
      },
      barChart: {
        chart: {
          type: "bar",
          height: 350,
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        stroke: {
          curve: "smooth",
        },
        dataLabels: {
          enabled: false,
        },
        title: {
          text: "Inventory",
          style: {
            fontSize: "24px",
            fontFamily: "Montserrat, Cairo",
            fontWeight: "bold",
          },
        },
        xaxis: {
          type: "category",
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
        },
        // labels: series.monthDataSeries1.dates,
        yaxis: {
          opposite: false,
          labels: {
            show: true,
            align: "left",
            rotate: -45,
            style: {
              fontSize: "12px",
              fontFamily: "Montserrat, Cairo",
              fontWeight: "bold",
            },
            offsetX: -5,
            offsetY: 10,
          },
        },
        legend: {
          horizontalAlign: "left",
        },
        grid: {
          show: true,
          position: "back",
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
          padding: {
            right: 0,
            left: 0,
            top: 0,
            bottom: 0,
          },
        },
        legend: {
          position: "bottom",
          markers: {
            radius: 5,
            width: 10,
            height: 10,
          },
          itemMargin: {
            // horizontal: 10,
            vertical: 5,
          },
        },
        responsive: [
          {
            breakpoint: 678,
            options: {
              cart: {
                height: "auto",
              },
              legend: {
                position: "bottom",
                offsetY: 0,

                itemMargin: {
                  horizontal: 10,
                  vertical: 0,
                },
              },
            },
          },
        ],
      },
      radialBarChart: {
        chart: {
          type: "radialbar",
          height: 350,
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        labels: ["Sale", "Distribute", "Returns"],
        plotOptions: {
          radialBar: {
            dataLabels: {
              name: {
                fontSize: "22px",
              },
              value: {
                fontSize: "16px",
              },
              total: {
                show: true,
                label: "Total",
                formatter: function (w) {
                  // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                  return 249;
                },
              },
            },
          },
        },

        responsive: [
          {
            breakpoint: 678,
            options: {
              cart: {
                height: "auto",
              },
            },
          },
        ],
      },
    };
  },
  created() {
    this.getItems();
  },
  methods: {
    ...mapActions("getting", ["getRoute"]),
    async getItems() {
      try {
        const { result } = await this.getRoute("dashboard");
        console.log(result);
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },
  },
};
</script>
