import Vue from "vue";
import VueRouter from "vue-router";
import Login from "@/views/account/login.vue";
import Analytics from "@/views/Analytics.vue";
import PageNotFound from "@/views/access/PageNotFound.vue";
import PermissionsAllowed from "@/views/access/PermissionsAllowed.vue";
import Notifications from "@/views/Notifications.vue";
import Support from "@/views/support/index.vue";
import SingleTicket from "@/views/support/_id.vue";
import Stores from "@/views/stores/index.vue";
import Modules from "@/views/Modules.vue";
import MeatTypes from "@/views/MeatTypes.vue";
import Categories from "@/views/Categories.vue";
import CategoriesGroup from "@/views/CategoriesGroup.vue";
import SubCategories from "@/views/SubCategories.vue";
import Warehouses from "@/views/Warehouses.vue";
import Cashiers from "@/views/Cashiers.vue";
import Users from "@/views/Users.vue";
import Regions from "@/views/locations/Regions.vue";
import Cities from "@/views/locations/Cities.vue";
import Districts from "@/views/locations/Districts.vue";
import Suppliers from "@/views/suppliers/index.vue";
import Packages from "@/views/packages/index.vue";
import CreatePackage from "@/views/packages/create.vue";
import UpdatePackage from "@/views/packages/update.vue";
import Devices from "@/views/Devices.vue";
import Wastages from "@/views/Wastages.vue";
import Subscriptions from "@/views/Subscriptions.vue";
import POSCashiers from "@/views/POSCashiers.vue";
// import SingleSupplier from "@/views/suppliers/_id.vue";

// customers
import Admins from "@/views/Admins.vue";
import DefaultInventory from "@/views/inventory/default/index.vue";
import UpdateDefaultInventoryProduct from "@/views/inventory/default/update.vue";
import CreateDefaultInventoryProduct from "@/views/inventory/default/create.vue";
import DefaultSuppliers from "@/views/Suppliers.vue";
import ProductsPage from "@/views/products/index.vue";
import CreateProduct from "@/views/products/create.vue";
import UpdateProduct from "@/views/products/update.vue";
import DefaultProducts from "@/views/products/default/index.vue";
import CreateDefaultProduct from "@/views/products/default/create.vue";
import UpdateDefaultProduct from "@/views/products/default/update.vue";
import StockPage from "@/views/inventory/Stock.vue";
import NearExpiry from "@/views/inventory/NearExpiry.vue";
import Expired from "@/views/inventory/Expired.vue";
import Inventory from "@/views/inventory/index.vue";
import Invoices from "@/views/invoices/index.vue";
import InvoiceDetails from "@/views/invoices/_id.vue";
import Orders from "@/views/orders/index.vue";
// import Warehouses from "@/views/orders/warehouses/index.vue";
import WarehouseProducts from "@/views/orders/warehouses/_id.vue";
import Refunds from "@/views/Refunds.vue";
import OrderDetails from "@/views/orders/_id.vue";
import ProductsInvoices from "@/views/ProductsInvoices.vue";
import Documents from "@/views/Documents.vue";
import Roles from "@/views/Roles.vue";
// settings
import SettingsPage from "@/views/Settings.vue";
import Apps from "@/views/Apps.vue";
import Profile from "@/views/account/Profile.vue";
import WClient from "@/views/websites/clients";
import Partners from "@/views/websites/partners";
import Pages from "@/views/websites/pages";

Vue.use(VueRouter);
// middleware & store
import store from "@/store";
import auth from "@/middlewares/auth";

const routes = [
  {
    path: "/account/login",
    name: "Login",
    component: Login,
    meta: {
      middleware: [auth],
    },
  },

  {
    path: "/",
    name: "Analytics",
    component: Analytics,
    meta: {
      middleware: [auth],
    },
  },

  {
    path: "/default-wastages",
    name: "Wastages",
    component: Wastages,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/devices",
    name: "Devices",
    component: Devices,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/pos_cashiers",
    name: "POSCashiers",
    component: POSCashiers,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/subscriptions",
    name: "Subscriptions",
    component: Subscriptions,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/packages",
    name: "Packages",
    component: Packages,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/packages/create",
    name: "CreatePackage",
    component: CreatePackage,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/packages/:id",
    name: "UpdatePackage",
    component: UpdatePackage,
    meta: {
      middleware: [auth],
    },
  },

  {
    path: "/products",
    name: "ProductsPage",
    component: ProductsPage,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/products/create",
    name: "CreateProduct",
    component: CreateProduct,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/products/:slug",
    name: "UpdateProduct",
    component: UpdateProduct,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/default-products",
    name: "DefaultProducts",
    component: DefaultProducts,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/default-products/create",
    name: "CreateDefaultProduct",
    component: CreateDefaultProduct,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/default-products/:slug",
    name: "UpdateDefaultProduct",
    component: UpdateDefaultProduct,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/inventory/near-expiry",
    name: "NearExpiry",
    component: NearExpiry,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/inventory/expired",
    name: "Expired",
    component: Expired,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/inventory",
    name: "Inventory",
    component: Inventory,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/inventory/stock",
    name: "StockPage",
    component: StockPage,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/invoices",
    name: "Invoices",
    component: Invoices,
    meta: {
      middleware: [auth],
    },
  },
  // {
  //   path: "/invoices/products",
  //   name: "ProductsInvoices",
  //   component: ProductsInvoices,
  //   meta: {
  //     middleware: [auth],
  //   },
  // },
  {
    path: "/invoices/:id",
    name: "InvoiceDetails",
    component: InvoiceDetails,
    meta: {
      middleware: [auth],
    },
  },

  {
    path: "/refunds",
    name: "Refunds",
    component: Refunds,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/orders",
    name: "Orders",
    component: Orders,
    meta: {
      middleware: [auth],
    },
  },

  // {
  //   path: "/orders/new",
  //   name: "Warehouses",
  //   component: Warehouses,
  //   meta: {
  //     middleware: [auth],
  //   },
  // },
  {
    path: "/orders/new/:id",
    name: "WarehouseProducts",
    component: WarehouseProducts,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/orders/:id",
    name: "OrderDetails",
    component: OrderDetails,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/notifications",
    name: "Notifications",
    component: Notifications,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/suppliers",
    name: "Suppliers",
    component: Suppliers,
    meta: {
      middleware: [auth],
    },
  },
  // {
  //   path: "/support/:id",
  //   name: "SingleTicket",
  //   component: SingleTicket,
  //   meta: {
  //     middleware: [auth],
  //   },
  // },
  {
    path: "/support",
    name: "Support",
    component: Support,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/support/:id",
    name: "SingleTicket",
    component: SingleTicket,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/stores",
    name: "Stores",
    component: Stores,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/modules",
    name: "Modules",
    component: Modules,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/meat-types",
    name: "MeatTypes",
    component: MeatTypes,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/default-category-groups",
    name: "CategoriesGroup",
    component: CategoriesGroup,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/default-categories",
    name: "Categories",
    component: Categories,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/default-sub-categories",
    name: "SubCategories",
    component: SubCategories,
    meta: {
      middleware: [auth],
    },
  },

  {
    path: "/warehouses",
    name: "Warehouses",
    component: Warehouses,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/users",
    name: "Users",
    component: Users,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/locations/regions",
    name: "Regions",
    component: Regions,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/locations/cities",
    name: "Cities",
    component: Cities,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/locations/districts",
    name: "Districts",
    component: Districts,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/cashiers",
    name: "Cashiers",
    component: Cashiers,
    meta: {
      middleware: [auth],
    },
  },

  {
    path: "/admins",
    name: "Admins",
    component: Admins,
    meta: {
      middleware: [auth],
    },
  },

  {
    path: "/documents",
    name: "Documents",
    component: Documents,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/roles",
    name: "Roles",
    component: Roles,
    meta: {
      middleware: [auth],
    },
  },

  {
    path: "/account",
    name: "Profile",
    component: Profile,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/settings",
    name: "SettingsPage",
    component: SettingsPage,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/apps",
    name: "Apps",
    component: Apps,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/default-inventory",
    name: "DefaultInventory",
    component: DefaultInventory,
    meta: {
      middleware: [auth],
      title: "Update product",
    },
  },
  {
    path: "/default-inventory/update/:slug",
    name: "UpdateDefaultInventoryProduct",
    component: UpdateDefaultInventoryProduct,
    meta: {
      middleware: [auth],
      title: "Update product",
    },
  },
  {
    path: "/default-inventory/create",
    name: "CreateDefaultInventoryProduct",
    component: CreateDefaultInventoryProduct,
    meta: {
      middleware: [auth],
      title: "Create new product",
    },
  },
  {
    path: "/default-suppliers",
    name: "DefaultSuppliers",
    component: DefaultSuppliers,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/not-allowed",
    name: "PermissionsAllowed",
    component: PermissionsAllowed,
  },
  {
    path: "/website/clients",
    name: "WClient",
    component: WClient,
    meta: {
      middleware: [auth],
      title: "clients",
      permission: "show website",
    },
  },
  {
    path: "/website/pages",
    name: "Pages",
    component: Pages,
    meta: {
      middleware: [auth],
      title: "pages",
      permission: "show website",
    },
  },
  {
    path: "/website/partners",
    name: "Partners",
    component: Partners,
    meta: {
      middleware: [auth],
      title: "partners",
      permission: "show website",
    },
  },
  {
    path: "*",
    name: "PageNotFound",
    component: PageNotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters);
    // Then run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      to,
      store,
      $cookies,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);
    return middleware[0]({ ...context, next: nextMiddleware });
  } else if (store.state.token || Vue.$cookies.get("token")) {
    if (to.name === "Auth") {
      return next("/");
    }
  }
  return next();
});
export default router;
