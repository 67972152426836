<template>
  <div>
    <section
      class="
        w-full
        flex flex-col
        lg:flex-row
        gap-6
        p-6
        max-h-screen
        min-h-screen
      "
    >
      <aside
        class="w-full lg:w-1/4 xl:max-w-sm flex flex-col"
        ref="settingsRefParent"
      >
        <button
          class="
            w-full
            px-4
            py-2
            rounded
            ring-1 ring-gray-200
            bg-white
            flex
            lg:hidden
            items-center
            gap-4
          "
          @click.prevent="openSettingsDropdown"
        >
          <div class="flex-1">
            <p
              class="
                text-sm
                font-semibold
                rtl:font-bold
                text-gray-600 text-left
                rtl:text-right
              "
            >
              Tickets
            </p>
          </div>
          <div>
            <button class="w-8 h-8 rounded-full bg-gray-50 hover:bg-gray-100">
              <i class="fa-solid fa-angle-down fa-sm"></i>
            </button>
          </div>
        </button>
        <div class="w-full space-y-6 hidden lg:block flex-1 overflow-y-auto">
          <div class="w-full sticky top-0">
            <input
              type="text"
              name="search"
              id="search"
              class="
                w-full
                bg-white
                h-12
                rounded-md
                px-4
                text-sm
                pl-10
                rtl:pl-auto rtl:pr-10
              "
              placeholder="Search in tickets..."
              v-model="pagination.search_key"
              @keydown.enter.stop="getItems"
            />
            <span
              class="
                absolute
                top-0
                left-0
                rtl:left-auto rtl:right-0
                h-full
                w-10
                flex
                items-center
                justify-center
                text-gray-500 text-sm
              "
            >
              <i class="fa-solid fa-spinner fa-spin" v-if="!load"></i>
              <i class="fa-solid fa-search" v-else></i>
            </span>
          </div>
          <div class="w-full space-y-4">
            <div
              class="
                flex
                gap-4
                items-center
                animate-pulse
                p-5
                bg-white
                ring-1 ring-gray-200
              "
              v-if="!load"
            >
              <div>
                <div class="w-9 h-9 rounded-full bg-gray-200"></div>
              </div>
              <div class="flex-1 max-w-xs space-y-2">
                <div class="w-2/3 h-4 rounded bg-gray-200"></div>
                <div class="w-full h-3 rounded bg-gray-100"></div>
              </div>
            </div>
            <router-link
              v-for="(item, i) in items"
              :key="i"
              :to="`/support/${item.id}`"
              class="block rounded-xl bg-white"
              active-class="border-2 border-primary"
              v-else
            >
              <figure>
                <blockquote class="p-5 flex gap-4">
                  <div>
                    <div
                      class="
                        w-12
                        h-12
                        rounded-full
                        bg-gray-200
                        text-gray-600
                        flex
                        items-center
                        justify-center
                      "
                    >
                      <i class="fa-solid fa-ticket"></i>
                    </div>
                  </div>
                  <div class="space-y-1 flex-1">
                    <h1 class="font-semibold rtl:font-bold line-clamp-1">
                      {{ item.subject || "-" }}
                    </h1>
                    <!-- <p
                      class="text-xs font-medium rtl:font-semibold text-gray-600 line-clamp-2"
                    >
                      {{ item.body || "-" }}
                    </p> -->
                    <p
                      class="
                        text-xs
                        font-medium
                        rtl:font-semibold
                        text-gray-600
                        !mt-2
                      "
                    >
                      <span class="text-primary">{{ item.status }}</span>
                      •
                      <span>
                        {{
                          item.updated_at || item.created_at | moment("from")
                        }}</span
                      >
                    </p>
                  </div>
                </blockquote>
              </figure>
            </router-link>
            <div v-if="load && !items.length">
              <p
                class="
                  text-center text-sm text-gray-600
                  font-medium
                  rtl:font-semibold
                "
              >
                There are no tickets available to display
              </p>
            </div>
            <div class="table mx-auto !py-6" v-if="pagination.total_pages > 1">
              <paginate
                v-model="pagination.page"
                :page-count="pagination.total_pages"
                :page-range="5"
                :margin-pages="2"
                :prev-text="'Previous'"
                :next-text="'Next'"
                :container-class="'pagination'"
                :page-class="'page-item'"
              >
              </paginate>
            </div>
          </div>
        </div>
      </aside>
      <main class="w-full lg:w-3/4 xl:flex-1 h-full space-y-4">
        <slot></slot>
      </main>
    </section>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "ContainerWrapper",
  data() {
    return {
      items: [],
      pagination: {
        is_active: null,
        page: 1,
        sort_key: null,
        sort_type: "asc",
      },
      load: false,
    };
  },
  created() {
    this.getItems();
  },
  watch: {
    "pagination.page"() {
      this.getItems();
    },
  },
  methods: {
    ...mapActions("getting", ["getRoute"]),
    openSettingsDropdown() {
      const parent = this.$refs.settingsRefParent;
      const dropdown = parent?.children[1];
      const currentChild = parent?.children[0];
      const icon = currentChild?.querySelector(".fa-sm");
      if (!parent || !dropdown || !currentChild) return;

      dropdown.classList.toggle("!block");
      currentChild.classList.toggle("mb-4");
      icon.classList.replace(
        dropdown.classList.contains("!block") ? "fa-angle-down" : "fa-angle-up",
        dropdown.classList.contains("!block") ? "fa-angle-up" : "fa-angle-down"
      );
    },
    async getItems() {
      this.load = false;
      try {
        const { total, total_pages, ...others } = this.pagination;

        const { result } = await this.getRoute({
          name: "tickets",
          options: {
            ...others,
          },
        });
        const { data, pagination } = result.tickets;
        this.items = data;
        this.$set(this.pagination, "total", pagination.total);
        this.$set(this.pagination, "total_pages", pagination.total_pages);
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      } finally {
        this.load = true;
      }
    },
  },
};
</script>
