import axios from 'axios'
export default {
  namespaced: true,
  state: {
    is_error: false,
  },
  getters: {},
  mutations: {
    SET_ERROR(state, data) {
      state.is_error = data
    },
  },
  actions: {
    /**
     *
     * @param {object} state
     * @param {object|string} data - Name of route
     * dynamic function to get any endpoint with name and options
     * @returns resolve(data) reject(error came from server and locale)
     */
    getRoute(state, data) {
      const type = data
      const { name, options } = data // if typeof data is object
      return new Promise((resolve, reject) => {
        axios
          .get(typeof data === 'object' ? name : type, {
            params: {
              ...options,
            },
          })
          .then(({ data }) => {
            resolve(data)
          })
          .catch((err) => {
            const res = err?.response.data // return undefined if there is no response
            if (!res) {
              // Send locale error
              reject({
                type: 'server',
                result: {
                  message: `There is something went wrong while getting ${name}`,
                },
              })
              return
            }
            // Send the error result that came from the server
            reject({
              type: 'error',
              result: res,
            })
          })
      })
    },
  },
  modules: {},
}
