export default {
  can: {
    /**
     * remove the current element that has v-can attribute after check value from permissions from store.auth
     * @param {HTMLElement} el - current element with v-can="value" attribute
     * @param {object} binding
     * @param {object} vNode
     */
    bind(el, binding, vNode) {
      const parentElement = vNode.elm.parentElement;

      const value = binding.value;

      if (!value) return true;
      const unwatch = vNode.context.$store.watch(
        (state) => state.user.permissions,
        (permissions) => {
          const regex = new RegExp(`^(show|add|delete|edit) ${value}`, "gi");

          if (!permissions.some((e) => regex.test(e))) {
            parentElement ? parentElement.removeChild(el) : el.remove();
          }
        }
      );
      el.__role_unwatch__ = unwatch;
    },

    unbind(el) {
      el.__role_unwatch__ && el.__role_unwatch__();
    },
  },
  ltr: {
    inserted(el, binding, vNode) {
      const regex = /[\u0600-\u06FF]/g;
      el.addEventListener("input", (e) => {
        let { value } = e.target;
        if (!value) return;
        if (regex.test(value.trim())) {
          e.target.value = e.target.value.replace(regex, "");
        }
      });
    },
  },
  rtl: {
    inserted(el, binding, vNode) {
      const regex = /[a-z]/gi;
      el.addEventListener("input", (e) => {
        let { value } = e.target;
        if (!value) return;
        if (regex.test(value.trim())) {
          e.target.value = e.target.value.replace(regex, "");
        }
      });
    },
  },
  number: {
    inserted(el, binding, vNode) {
      const regex = /\d|[\u0660-\u0669]/g;
      el.addEventListener("input", (e) => {
        let { value } = e.target;
        if (!value) return;
        if (!regex.test(value.trim())) {
          return null;
        } else {
          e.target.value = e.target.value.match(regex).join("");
        }
      });
    },
  },
  mobile: {
    inserted(el, binding, vNode) {
      el.addEventListener("input", (e) => {
        const regex = /\d/g;
        let { value } = e.target;
        if (!value) return;
        e.target.value = e.target.value.match(regex).join("");
        if (value.length > 5 && value.length <= 16) {
          e.target.value = e.target.value.slice(0, 16);
        }
      });
    },
  },
  email: {
    inserted(el, binding, vNode) {
      el.addEventListener("input", (e) => {
        const regex = new RegExp(
          `^([a-zA-Z0-9!#$%&'*+\\/=?^_\`{|}~-]+(?:\\.[a-zA-Z0-9!#$%&'*+\\/=?^_\`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?)$`,
          "g"
        );
        let { value } = e.target;
        if (!value) return;
        if (!regex.test(value)) {
          e.target.classList.add(
            ...["ring-1", "!ring-red-500/30", "!bg-red-500/10"]
          );
          return;
        }
        e.target.classList.remove(
          ...["ring-1", "!ring-red-500/30", "!bg-red-500/10"]
        );
      });
    },
  },
  date: {
    inserted(el) {
      // const type = (el || el.target)?.type
      const __EL__ = el || el.target;
      __EL__.setAttribute("readonly", "readonly");
      __EL__.type = "text";
      __EL__.addEventListener("focus", (e) => {
        if (!e.target.value) {
          e.target.removeAttribute("readonly");
          e.target.type = "date";
        }
      });
      __EL__.addEventListener("blur", (e) => {
        if (!e.target.value) {
          e.target.setAttribute("readonly", "readonly");
          e.target.type = "text";
        }
      });
    },
  },
};
