<template>
  <div>
    <container-wrapper>
      <figure class="w-full bg-white rounded-xl h-full">
        <div class="flex flex-col h-full">
          <div>
            <header class="w-full border-b border-gray-100 p-4">
              <div class="flex items-center">
                <div>
                  <div
                    class="w-10 h-10 rounded-full bg-gray-200 animate-pulse"
                  ></div>
                </div>
                <div class="px-4 space-y-2 flex-1">
                  <div
                    class="w-full max-w-[10rem] h-4 rounded bg-gray-200 animate-pulse"
                  ></div>
                  <div
                    class="w-full max-w-xs h-3 rounded bg-gray-200 animate-pulse"
                  ></div>
                </div>
              </div>
            </header>
          </div>
          <div class="flex-1 p-10 py-20">
            <div class="table m-auto">
              <div class="flex items-center justify-center flex-col gap-6">
                <div>
                  <div
                    class="w-28 h-28 flex items-center justify-center bg-gray-100 rounded-full text-gray-500"
                  >
                    <i class="fa-solid fa-ticket fa-2xl"></i>
                  </div>
                </div>
                <div class="flex-1 space-y-2 text-center">
                  <h1 class="text-lg text-primary font-semibold rtl:font-bold">
                    There are no tickets
                  </h1>
                  <p
                    class="text-sm text-gray-400 max-w-xl font-medium rtl:font-semibold"
                  >
                    Click on the ticket from the other side if there are any
                    tickets available to complete the discussion and
                    conversation.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </figure>
    </container-wrapper>
  </div>
</template>

<script>
import ContainerWrapper from "@/components/support/ContainerWrapper.vue";
export default {
  components: { ContainerWrapper },
  name: "SupportPage",
};
</script>
