<template>
  <div>
    <header class="w-full space-y-4">
      <!-- :class="{
        'px-6':
          !/(reports|singleVisit|SingleClient)/gi.test($route.name) &&
          !/(reports|visits)/gi.test($route.path),
      }" -->
      <a
        href="#"
        role="button"
        class="w-full flex bg-white ring-1 ring-gray-200 p-3 sm:hidden items-center rounded group disabled:opacity-50"
        @click.prevent="openFilter"
        :disabled="!loaded"
      >
        <div class="flex-1">
          <p
            class="text-sm text-gray-600 block font-medium rtl:font-semibold truncate"
          >
            <i
              class="fa-solid fa-list mr-2 rtl:mr-0 rtl:ml-2"
              v-if="loaded"
            ></i>
            <spinner
              size="w-4 h-4 inline-block"
              class="mr-2 rtl:mr-0 rtl:ml-2"
              v-else
            />
            Filter
          </p>
        </div>
        <div>
          <button class="w-4 h-4" @click.prevent="openFilter">
            <i class="fa-solid fa-angle-down fa-sm" ref="filterIcon"></i>
          </button>
        </div>
      </a>
      <slide-transition>
        <div
          class="hidden sm:grid sm:grid-cols-[repeat(auto-fill,minmax(17em,auto))] 2xl:grid-cols-5 gap-4 sm:!mt-0"
          ref="filter"
        >
          <slot></slot>
        </div>
      </slide-transition>
    </header>
  </div>
</template>

<script>
export default {
  name: "FilterWrapper",
  props: {
    loaded: {
      type: Boolean,
      type: true,
    },
  },
};
</script>
