const requireModule = require.context('.', false, /\.js$/)

const modules = {}

requireModule.keys().forEach((filename) => {
  if (filename === './index.js') return // don't import index file
  const replaceFilename = filename.replace(/(\.\/|\.js)/g, '')
  console.log(
    `%c YOU BLOCKED ⍨ `,
    `
  font-size: 20px;
  font-family: 'Fira Code', 'Cairo', 'sans-serif';
  font-weight: normal;
  color:red
  `
  )
  modules[replaceFilename] = requireModule(filename).default
})

export default modules
