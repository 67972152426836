<template>
  <div>
    <div class="grid">
      <header
        class="w-full flex items-center justify-start gap-3 overflow-x-auto pb-4"
      >
        <div>
          <router-link
            to="/inventory"
            exact-active-class="!bg-primary !text-white"
            class="py-2 px-4 rounded bg-white text-gray-600 text-sm font-medium rtl:font-semibold table whitespace-nowrap"
          >
            All
          </router-link>
        </div>
        <div>
          <router-link
            to="/inventory/stock"
            exact-active-class="!bg-primary !text-white"
            class="py-2 px-4 rounded bg-white text-gray-600 text-sm font-medium rtl:font-semibold table whitespace-nowrap"
          >
            Stock
          </router-link>
        </div>
        <div>
          <router-link
            to="/inventory/near-expiry"
            exact-active-class="!bg-primary !text-white"
            class="py-2 px-4 rounded bg-white text-gray-600 text-sm font-medium rtl:font-semibold table whitespace-nowrap"
          >
            Near expiry
          </router-link>
        </div>
        <div>
          <router-link
            to="/inventory/expired"
            exact-active-class="!bg-primary !text-white"
            class="py-2 px-4 rounded bg-white text-gray-600 text-sm font-medium rtl:font-semibold table whitespace-nowrap"
          >
            Expired
          </router-link>
        </div>
      </header>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "ContainerWrapper",
};
</script>
