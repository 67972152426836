export default {
  namespaced: true,
  state: {
    stores: [],
    branches: [],
    regions: [],
    cities: [],
    users: [],
    visits: [],
    load: false,
  },
  getters: {
    stores: (state) => state.stores,
    branches: (state) => state.branches,
    regions: (state) => state.regions,
    cities: (state) => state.cities,
    users: (state) => state.users,
    load: (state) => state.load,
  },
  mutations: {
    STORE_DATA(state, payload) {
      for (const key in payload) {
        if (Object.hasOwnProperty.call(state, key)) {
          const item = payload[key]
          state[key] = item
        }
      }
    },
    CHANGE_LOAD(state, payload) {
      state.load = payload
    },
  },
  actions: {
    async getRegions({ commit }) {
      try {
        const { result } = await this.dispatch('getting/getRoute', 'region')
        commit('STORE_DATA', {
          regions: result.regions,
        })
      } catch (error) {}
    },
    async getCities({ commit }) {
      try {
        const { result } = await this.dispatch('getting/getRoute', 'cities')
        commit('STORE_DATA', {
          cities: result.cities,
        })
      } catch (error) {}
    },
    async getBranches({ commit }) {
      try {
        const { result } = await this.dispatch(
          'getting/getRoute',
          'company_branches'
        )
        commit('STORE_DATA', {
          company_branches: result.company_branches,
        })
      } catch (error) {}
    },
    async getStores({ commit }) {
      try {
        const { result } = await this.dispatch(
          'getting/getRoute',
          'company_stores'
        )
        commit('STORE_DATA', {
          company_stores: result.company_stores,
        })
      } catch (error) {}
    },
    async getUsers({ commit }) {
      try {
        const { result } = await this.dispatch('getting/getRoute', {
          name: 'users',
          options: {
            role_type: ['client', 'merchandiser', 'supervisor'],
          },
        })
        commit('STORE_DATA', {
          users: result.users,
        })
      } catch (error) {}
    },
  },
  modules: {},
}
