import Vue from "vue";

export default async function auth(ctx) {
  const { next, store, to, from } = ctx;
  const token = store.state.token || Vue.$cookies.get("token");
  // const isExisted = Object.keys(store.state.auth.user_info).length !== 0
  if (!token) {
    if (
      !["Login", "Register", "ForgotPassword", "ResetPassword"].includes(
        to.name
      )
    ) {
      return next({
        name: "Login",
        query: {
          redirect: [
            "Login",
            "Register",
            "ForgotPassword",
            "ResetPassword",
          ].includes(from.name)
            ? "/"
            : from.fullPath,
        },
      });
    }
    return next();
  }

  if (
    ["Login", "Register", "ForgotPassword", "ResetPassword"].includes(to.name)
  ) {
    return next({
      path: from.path || "/",
    });
  }
  store.commit("SET_TOKEN", token);
  await fetchProfile({
    ...ctx,
    token,
  });

  return next();
}

async function fetchProfile(ctx) {
  try {
    const { store, token } = ctx;
    const { result } = await store.dispatch("getting/getRoute", {
      name: "profile",
    });
    const generateCartKey = `${result.profile.user_type}_${result.profile.id}`;
    if (!store.getters["Cart/cart"](generateCartKey)) {
      store.commit("Cart/GENERATE_CART", generateCartKey);
    }
    if (!store.getters["warehouseCart/wareCart"](generateCartKey)) {
      store.commit("warehouseCart/GENERATE_WARE_CART", generateCartKey);
    }

    store.commit("SET_USER_DATA", {
      ...result.profile,
      permissions: result.permissions,
    });
    store.commit("SET_TOKEN", token);
    listenToChannel({
      ...ctx,
      id: result.profile.id,
    });
  } catch (err) {
    const res = err?.response;
  }
}

function listenToChannel({ store, $cookies, id }) {
  try {
    const root = new Vue();
    const echo = root.$echo;

    echo.connector.pusher.config.auth.headers["Authorization"] =
      "Bearer " + store.state.token || $cookies.get("token");

    const channel = echo?.private(`admin-${id}-channel`);
    const channel_2 = echo?.private(`admin-channel`);

    // listen to first channel by admin id to get replies from assigned tickets
    channel?.listen(".tickets", ({ data }) => {
      const reply = data.replay;
      // save current reply to chat module to append it inside current ticket
      store.commit("chat/UPDATE_MESSAGE", data);

      // push notification to current client via Notification API
      root.checkPermissions({
        title: "New message from support",
        body: reply.body,
      });
    });

    // listen to second channel via all admins
    channel_2?.listen(".tickets", ({ data }) => {
      const reply = data.replay;
      // save current reply to chat module to append it inside current ticket
      store.commit("chat/UPDATE_MESSAGE", data);

      // push notification to current client via Notification API
      root.checkPermissions({
        title: "New message from support",
        body: reply.body,
      });
    });
  } catch (error) {
    console.log(error);
  }
}
