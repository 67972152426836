<template>
  <div class="p-6">
    <PersonalAccount />
  </div>
</template>
<script>
import PersonalAccount from "@/components/settings/PersonalAccount.vue";

export default {
  components: {
    PersonalAccount,
  },

  name: "PersonalInformationPage",
};
</script>
