<template>
  <div
    class="z-20 fixed xl:sticky opacity-0 top-0 pointer-events-none invisible xl:opacity-100 xl:pointer-events-auto xl:visible left-full xl:left-0 bg-gray-800/20 xl:bg-transparent w-full xl:w-auto h-screen"
    @click.self="openCloseSideBar"
    ref="sideBar"
  >
    <aside
      class="bg-gray-800 overflow-y-auto flex flex-col top-0 w-screen max-w-[17rem] h-full max-h-screen transition-all"
    >
      <div class="w-full border-b border-gray-700 p-3 bg-white">
        <div>
          <img
            src="@/assets/images/logo.jpg"
            alt="logo image"
            class="w-full h-auto max-w-[8rem]"
          />
        </div>
      </div>
      <ul class="w-full space-y-2 flex-1 overflow-y-auto p-4 links-container">
        <li
          v-for="(item, index) in pages"
          :key="index"
          v-show="hasPermissions(item.permission)"
        >
          <div
            v-if="item.children"
            class="space-y-2 pt-2 border-t border-t-gray-700"
          >
            <a
              href="javascript:void(0)"
              class="flex items-center justify-center gap-2 py-2 px-3 rounded"
              @click.prevent="tree = tree === index ? null : index"
            >
              <span class="shrink-0 w-4 text-start">
                <i class="fa-sm" :class="item.icon"></i>
              </span>
              <div class="flex-1">
                <p class="font-medium line-clamp-2 text-sm text-gray-700">
                  {{ item.name }}
                </p>
              </div>
              <span class="shrink-0">
                <i class="fa-solid fa-angle-right fa-sm"></i>
              </span>
            </a>
            <slide-transition>
              <ul
                class="w-full space-y-2 flex-1 overflow-y-auto links-container"
                v-if="tree === index"
              >
                <li
                  v-for="(child, index) in item.children"
                  :key="index"
                  class="animate-item-move"
                  v-show="hasPermissions(child.permission)"
                  :data-permission="child.permission"
                >
                  <router-link
                    :to="child.path"
                    class="flex gap-4 py-2 px-3 rounded"
                    active-class="active-tab-pills !bg-secondary"
                  >
                    <span class="shrink-0 w-4 text-start">
                      <i :class="child.icon" v-if="child.icon"></i>
                    </span>
                    <div class="flex-1 self-center">
                      <p
                        class="font-medium rtl:font-semibold line-clamp-2 text-sm"
                      >
                        {{ child.name }}
                      </p>
                    </div>
                  </router-link>
                </li>
              </ul>
            </slide-transition>
          </div>
          <router-link
            :to="item.path"
            class="flex gap-4 py-2 px-3 rounded"
            :exact="item.path === '/'"
            active-class="active-tab-pills !bg-secondary"
            v-else
            v-show="hasPermissions(item.permission)"
          >
            <span class="shrink-0 w-4 text-start">
              <i :class="item.icon"></i>
            </span>
            <div class="flex-1 self-center">
              <p class="font-medium rtl:font-semibold line-clamp-2 text-sm">
                {{ item.name }}
              </p>
            </div>
          </router-link>
        </li>
      </ul>
      <div class="p-4 hidden xl:block">
        <button
          class="text-white w-12 h-12 bg-gray-700 flex items-center justify-center rounded-full"
          v-tippy
          content="Logout"
          @click.prevent="logout"
        >
          <i class="fa-solid fa-arrow-right-from-bracket"></i>
        </button>
      </div>
    </aside>
  </div>
</template>

<script>
export default {
  name: "SideBar",
  data() {
    return {
      pages: [
        {
          name: "POS cashiers",
          icon: "fa-solid fa-tachograph-digital",
          path: "/pos_cashiers",
          permission: "pos_cashiers",
        },
        {
          name: "Notifications",
          icon: "fa-solid fa-bell",
          path: "/notifications",
          permission: "notifications",
        },

        {
          name: "Subscriptions",
          icon: "fa-solid fa-circle-dollar-to-slot",
          // path: "/subscriptions",
          permission: "subscriptions",
          children: [
            {
              name: "Users",

              path: "/users",
              permission: "users",
            },
            {
              name: "Invoices",

              path: "/subscriptions",
              permission: "invoices",
            },
            {
              name: "Stores",

              path: "/stores",
              permission: "stores",
            },
            {
              name: "Devices",

              path: "/devices",
              permission: "devices",
            },
            {
              name: "Packages",

              path: "/packages",
              permission: "packages",
            },
          ],
        },

        {
          name: "Configuration",
          permission:
            "documents|modules|default_categories|default_category_groups|default_sub_categories|default_wastages|default_products|default_inventory|default_suppliers",
          icon: "fa-solid fa-sliders",
          children: [
            {
              name: "Documents",

              path: "/documents",
              permission: "documents",
            },
            {
              name: "Modules",

              path: "/modules",
              permission: "modules",
            },
            {
              name: "Default categories",

              path: "/default-categories",
              permission: "default_categories",
            },
            {
              name: "Default category groups",

              path: "/default-category-groups",
              permission: "default_category_groups",
            },
            {
              name: "Default sub categories",

              path: "/default-sub-categories",
              permission: "default_sub_categories",
            },
            // {
            //   name: "Meat types",
            //
            //   path: "/meat-types",
            //   permission: "meat_type",
            // },

            {
              name: "Default wastages",

              path: "/default-wastages",
              permission: "default_wastages",
            },
            {
              name: "Default products",

              path: "/default-products",
              permission: "default_products",
            },
            {
              name: "Default inventory",

              path: "/default-inventory",
              permission: "default_inventory",
            },
            // {
            //   name: "Default suppliers",

            //   path: "/default-suppliers",
            //   permission: "default_suppliers",
            // },
          ],
        },
        {
          name: "Administrations",
          permission: "admins|roles",
          icon: "fa-solid fa-user-shield",
          children: [
            {
              name: "Admins",
              path: "/admins",
              permission: "admins",
            },
            {
              name: "Roles",
              path: "/roles",
              permission: "roles",
            },
          ],
        },

        {
          name: "Settings",
          icon: "fa-solid fa-gear",
          children: [
            {
              name: "Basic settings",
              path: "/settings",
              permission: "settings",
            },
            {
              name: "Website appearance",
              path: "/website/clients",
              permission: "website",
            },
            {
              name: "Apps",
              path: "/apps",
              permission: "apps",
            },
            // {
            //   name: "Regions",
            //   icon: "fa-solid fa-earth-asia",
            //   path: "/locations/regions",
            //   permission: "regions",
            // },
            // {
            //   name: "Cities",
            //   icon: "fa-solid fa-location-dot",
            //   path: "/locations/cities",
            //   permission: "cities",
            // },
            // {
            //   name: "Districts",
            //   icon: "fa-solid fa-street-view",
            //   path: "/locations/districts",
            //   permission: "districts",
            // },
          ],
        },
        {
          name: "Support",
          path: "/support",
          icon: "fa-solid fa-ticket",
          permission: "support",
        },
        {
          name: "My account",
          icon: "fa-solid fa-user-gear",
          path: "/account",
        },
      ],
      tree: null,
    };
  },

  methods: {
    openCloseSideBar() {
      const sidebar = this.$refs.sideBar;
      if (!sidebar) return;
      if (sidebar.classList.contains("opacity-0")) {
        sidebar.classList.remove(
          ...["opacity-0", "pointer-events-none", "invisible", "left-full"]
        );
      } else {
        sidebar.classList.add(
          ...["opacity-0", "pointer-events-none", "invisible", "left-full"]
        );
      }
    },
  },
};
</script>
<style lang="scss">
.links-container {
  i {
    @apply text-gray-300;
  }
  p {
    @apply text-gray-200;
  }
  a:not(.active-tab-pills) {
    @apply hover:bg-gray-700 transition-all;
    i {
      @apply text-gray-300;
    }
    p {
      @apply text-gray-200;
    }
  }
  .active-tab-pills {
    i,
    p {
      @apply text-primary;
    }
    p {
      @apply font-bold;
    }
  }
}
</style>
