<template>
  <div>
    <container-wrapper>
      <figure
        class="
          w-full
          bg-white
          rounded-xl
          flex flex-col
          min-h-[calc(100vh-200px)]
          max-h-[calc(100vh-200px)]
        "
      >
        <div>
          <header class="w-full border-b border-gray-100 p-4">
            <div class="flex items-center" v-if="!load">
              <div>
                <div
                  class="w-10 h-10 rounded-full bg-gray-200 animate-pulse"
                ></div>
              </div>
              <div class="px-4 space-y-2 flex-1">
                <div
                  class="
                    w-full
                    max-w-[10rem]
                    h-4
                    rounded
                    bg-gray-200
                    animate-pulse
                  "
                ></div>
                <div
                  class="w-full max-w-xs h-3 rounded bg-gray-200 animate-pulse"
                ></div>
              </div>
            </div>
            <div v-else>
              <div class="flex items-center gap-2">
                <div class="flex-1">
                  <h1 class="font-semibold rtl:font-bold line-clamp-1">
                    {{ item.subject || "-" }}
                  </h1>

                  <p
                    class="
                      text-xs
                      font-medium
                      rtl:font-semibold
                      text-gray-600
                      !mt-2
                    "
                  >
                    <span class="text-primary">{{ item.status }}</span>
                    •
                    <span>
                      {{
                        item.updated_at || item.created_at | moment("from")
                      }}</span
                    >
                  </p>
                </div>
                <div>
                  <t-dropdown
                    :classes="{
                      dropdown:
                        '!origin-top-right rtl:!origin-top-left !max-w-[13rem] !bottom-auto !top-full !right-4 rtl:!right-auto rtl:!left-4',
                    }"
                  >
                    <div
                      slot="trigger"
                      slot-scope="{
                        mousedownHandler,
                        focusHandler,
                        blurHandler,
                        keydownHandler,
                      }"
                    >
                      <button
                        class="
                          flex
                          items-center
                          justify-center
                          text-gray-600
                          bg-gray-100
                          hover:bg-gray-200
                          font-medium
                          rtl:font-semibold
                          text-sm
                          h-8
                          w-8
                          rounded-full
                        "
                        @mousedown="mousedownHandler"
                        @focus="focusHandler"
                        @blur="blurHandler"
                        @keydown="keydownHandler"
                      >
                        <i class="fa-solid fa-ellipsis"></i>
                      </button>
                    </div>
                    <div slot-scope="{ hide }">
                      <div
                        class="border border-gray-200 rounded bg-white p-4 z-20"
                      >
                        <ul class="list-none space-y-3">
                          <li>
                            <button
                              class="
                                w-full
                                flex
                                items-center
                                gap-3
                                text-sky-500
                              "
                              @click.prevent="
                                () => {
                                  hide();
                                  status_active = true;
                                  status_data = {
                                    ...status_data,
                                    status: item.status,
                                  };
                                }
                              "
                            >
                              <span>
                                <i class="fa-solid fa-right-left"></i>
                              </span>
                              <p
                                class="
                                  font-medium
                                  rtl:font-semibold
                                  text-sm
                                  whitespace-nowrap
                                "
                              >
                                Update status
                              </p>
                            </button>
                          </li>
                          <li>
                            <button
                              class="
                                w-full
                                flex
                                items-center
                                gap-3
                                text-gray-600
                              "
                              @click.prevent="
                                () => {
                                  hide();
                                  assign_active = true;
                                }
                              "
                            >
                              <span>
                                <i class="fa-solid fa-user-plus"></i>
                              </span>
                              <p
                                class="
                                  font-medium
                                  rtl:font-semibold
                                  text-sm
                                  whitespace-nowrap
                                "
                              >
                                Assign employee
                              </p>
                            </button>
                          </li>
                          <li>
                            <button
                              class="
                                w-full
                                flex
                                items-center
                                gap-3
                                text-gray-600
                              "
                              @click.prevent="
                                () => {
                                  hide();
                                  priority_active = true;
                                  priority_data = {
                                    ...priority_data,
                                    priority: item.priority || 'low',
                                  };
                                }
                              "
                            >
                              <span>
                                <i class="fa-regular fa-flag"></i>
                              </span>
                              <p
                                class="
                                  font-medium
                                  rtl:font-semibold
                                  text-sm
                                  whitespace-nowrap
                                "
                              >
                                Update priority
                              </p>
                            </button>
                          </li>
                          <li>
                            <button
                              class="
                                w-full
                                flex
                                items-center
                                gap-3
                                text-red-500
                              "
                              @click.prevent="
                                () => {
                                  hide();
                                  deleteItem();
                                }
                              "
                            >
                              <span>
                                <i class="fa-solid fa-trash"></i>
                              </span>
                              <p
                                class="
                                  font-medium
                                  rtl:font-semibold
                                  text-sm
                                  whitespace-nowrap
                                "
                              >
                                Delete
                              </p>
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </t-dropdown>
                </div>
              </div>
            </div>
          </header>
        </div>
        <div class="flex-1 p-5 overflow-y-auto" data-type="chatBox">
          <div class="table m-auto py-20" v-if="!replies.length">
            <div class="flex items-center justify-center flex-col gap-6">
              <div>
                <div
                  class="
                    w-28
                    h-28
                    flex
                    items-center
                    justify-center
                    bg-gray-100
                    rounded-full
                    text-gray-500
                  "
                >
                  <i class="fa-solid fa-message fa-2xl"></i>
                </div>
              </div>
              <div class="flex-1 space-y-2 text-center">
                <h1 class="text-lg text-primary font-semibold rtl:font-bold">
                  There are no messages
                </h1>
                <p
                  class="
                    text-sm text-gray-400
                    max-w-xl
                    font-medium
                    rtl:font-semibold
                  "
                >
                  Click on the ticket from the other side if there are any
                  tickets available to complete the discussion and conversation.
                </p>
              </div>
            </div>
          </div>
          <messages-preview :items="replies" />
        </div>

        <div
          v-if="
            item.status === 'open' &&
            item.admin &&
            (item.admin || {}).id === getUser.id
          "
        >
          <footer class="border-t border-t-gray-100 flex items-start gap-4 p-4">
            <div>
              <button class="w-8 h-8" @click="show_more = !show_more">
                <i class="fa-solid fa-plus fa-sm text-gray-600"></i>
              </button>
            </div>
            <div class="flex-1 space-y-3">
              <div class="form-group">
                <textarea
                  name="message"
                  id="message"
                  placeholder="Type message"
                  v-model="add_data.body"
                ></textarea>
                <p class="error" v-if="add_errors.body">
                  {{ add_errors.body.join("") }}
                </p>
              </div>
              <slide-transition>
                <ul class="flex items-center gap-4 flex-wrap" v-if="show_more">
                  <li>
                    <label
                      for="images"
                      class="
                        flex
                        items-center
                        justify-center
                        gap-3
                        flex-col
                        cursor-pointer
                      "
                    >
                      <div
                        class="
                          w-10
                          h-10
                          rounded-full
                          ring-1 ring-gray-200
                          flex
                          items-center
                          justify-center
                          text-gray-600
                          mx-auto
                        "
                      >
                        <i class="fa-solid fa-camera"></i>
                      </div>
                      <p
                        class="
                          text-xs
                          font-semibold
                          rtl:font-bold
                          text-gray-600 text-center
                          line-clamp-1
                        "
                      >
                        Images
                      </p>
                      <input
                        type="file"
                        name="images"
                        id="images"
                        accept="image/*"
                        hidden
                        aria-hidden="true"
                        class="hidden"
                        multiple
                        ref="images_updater"
                        @change.prevent="handleAttachmentsAndImagesUploader"
                      />
                    </label>
                  </li>
                  <li>
                    <label
                      for="attachments"
                      class="
                        flex
                        items-center
                        justify-center
                        gap-3
                        flex-col
                        cursor-pointer
                      "
                    >
                      <div
                        class="
                          w-10
                          h-10
                          rounded-full
                          ring-1 ring-gray-200
                          flex
                          items-center
                          justify-center
                          text-gray-600
                          mx-auto
                        "
                      >
                        <i class="fa-solid fa-link"></i>
                      </div>
                      <p
                        class="
                          text-xs
                          font-semibold
                          rtl:font-bold
                          text-gray-600 text-center
                          line-clamp-1
                        "
                      >
                        Attachments
                      </p>
                      <input
                        type="file"
                        name="attachments"
                        id="attachments"
                        hidden
                        aria-hidden="true"
                        class="hidden"
                        multiple
                        ref="attachments_updater"
                        @change.prevent="handleAttachmentsAndImagesUploader"
                      />
                    </label>
                  </li>
                </ul>
              </slide-transition>
              <slide-transition>
                <div
                  class="block !mt-6 w-auto"
                  v-if="add_data.attachments.length"
                >
                  <div class="flex items-start gap-4 flex-wrap">
                    <div
                      v-for="(img, imgIdx) in add_data.attachments"
                      :key="imgIdx"
                      class="flex items-center gap-2"
                    >
                      <div class="rounded ring-2 ring-gray-300 py-1 px-2">
                        <span class="text-xs font-medium">{{ img.name }}</span>
                      </div>
                      <button
                        class="text-red-500 text-sm"
                        @click.prevent="add_data.attachments.splice(imgIdx, 1)"
                      >
                        <i class="fa-solid fa-trash"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </slide-transition>
            </div>
            <div>
              <button-with-loader
                class="
                  w-8
                  h-8
                  text-white
                  rounded-full
                  bg-indigo-600
                  flex
                  items-center
                  justify-center
                "
                :disabled="add_disabled"
                @click="addReply"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  class="w-4 h-4"
                  v-if="!add_disabled"
                >
                  <path
                    d="M3.105 2.289a.75.75 0 00-.826.95l1.414 4.925A1.5 1.5 0 005.135 9.25h6.115a.75.75 0 010 1.5H5.135a1.5 1.5 0 00-1.442 1.086l-1.414 4.926a.75.75 0 00.826.95 28.896 28.896 0 0015.293-7.154.75.75 0 000-1.115A28.897 28.897 0 003.105 2.289z"
                  />
                </svg>
              </button-with-loader>
            </div>
          </footer>
        </div>
      </figure>
    </container-wrapper>
    <Modal title="Assign employee" v-if="assign_active" @close="handleClose">
      <template>
        <form class="p-4 space-y-4">
          <div class="form-group">
            <div class="with-label">
              <p class="error" v-if="assign_errors.admin_id">
                {{ assign_errors.admin_id.join(" ") }}
              </p>
              <t-rich-select
                :options="assignees"
                placeholder="Select"
                :text-attribute="'name'"
                value-attribute="id"
                :clear-able="true"
                :closeOnSelect="true"
                v-model="assign_data.admin_id"
              />
              <label for="admin_id">Admin</label>
            </div>
          </div>
          <div>
            <button-with-loader
              :disabled="assign_disabled"
              btnClass="bg-primary py-2 px-4 rounded text-xs font-medium rtl:font-semibold text-white"
              @click="assignItem"
            >
              Save changes
            </button-with-loader>
          </div>
        </form>
      </template>
    </Modal>
    <Modal title="Update status" v-if="status_active" @close="handleClose">
      <template>
        <form class="p-4 space-y-4">
          <div class="form-group">
            <div class="with-label">
              <p class="error" v-if="status_errors.status">
                {{ status_errors.status.join(" ") }}
              </p>
              <select v-model="status_data.status" class="custom-select">
                <option
                  v-for="(_status, index) in status"
                  :key="index"
                  :value="_status"
                >
                  {{ _status }}
                </option>
              </select>
              <label for="status">Status</label>
            </div>
          </div>
          <div>
            <button-with-loader
              :disabled="status_disabled"
              btnClass="bg-primary py-2 px-4 rounded text-xs font-medium rtl:font-semibold text-white"
              @click="statusItem"
            >
              Save changes
            </button-with-loader>
          </div>
        </form>
      </template>
    </Modal>
    <Modal title="Update priority" v-if="priority_active" @close="handleClose">
      <template>
        <form class="p-4 space-y-4">
          <div class="form-group">
            <div class="with-label">
              <p class="error" v-if="priority_errors.priority">
                {{ priority_errors.priority.join(" ") }}
              </p>
              <select v-model="priority_data.priority" class="custom-select">
                <option
                  v-for="(priority, index) in priorities"
                  :key="index"
                  :value="priority"
                >
                  {{ priority }}
                </option>
              </select>
              <label for="priority">Priority</label>
            </div>
          </div>
          <div>
            <button-with-loader
              :disabled="priority_disabled"
              btnClass="bg-primary py-2 px-4 rounded text-xs font-medium rtl:font-semibold text-white"
              @click="priorityItem"
            >
              Save changes
            </button-with-loader>
          </div>
        </form>
      </template>
    </Modal>
  </div>
</template>

<script>
import ContainerWrapper from "@/components/support/ContainerWrapper.vue";
import SlideTransition from "@/components/basics/SlideTransition.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import ButtonWithLoader from "@/components/basics/ButtonWithLoader.vue";
import MessagesPreview from "@/components/support/MessagesPreview.vue";
import Modal from "@/components/basics/Modal.vue";
export default {
  components: {
    ContainerWrapper,
    SlideTransition,
    ButtonWithLoader,
    MessagesPreview,
    Modal,
  },
  name: "SingleTicket",
  data() {
    return {
      show_more: false,
      replies: [],
      item: {},
      load: false,
      add_data: {
        attachments: [],
        id: this.$route.params.id || null,
      },
      add_errors: {},
      add_disabled: false,
      delete_disabled: false,
      // assign
      assign_data: {
        admin_id: null,
        id: this.$route.params.id || null,
      },
      assign_errors: {},
      assignees: [],
      assign_active: false,
      assign_disabled: false,
      // status
      status_data: {
        id: this.$route.params.id || null,
      },
      status: ["open", "pending", "resolved", "closed"],
      status_errors: {},
      status_active: false,
      status_disabled: false,
      // priority
      priority_disabled: false,
      priorities: ["low", "medium", "high", "urgent"],
      priority_data: {
        id: this.$route.params.id || null,
      },
      priority_errors: {},
      priority_active: false,
    };
  },
  computed: {
    ...mapGetters({
      getUser: "getUserInfo",
      getMessage: "chat/getMessage",

    }),
    ticketId() {
      return this.$route.params.id;
    },
  },
  watch: {
    ticketId() {
      this.add_data = {
        attachments: [],
        id: this.ticketId,
      };
      this.add_errors = {};
      this.load = false;
      this.item = {};
      this.replies = [];
      this.getItems().finally(() => (this.load = true));
    },
     getMessage(val) {
      if (val) {
        const { replay, ticket_id } = val;
        if (ticket_id === this.ticketId) {
          this.replies.push(replay);
          const chat = document.querySelector('[data-type="chatBox"]');
          if (chat) {
            setTimeout(() => {
              if (this.replies.length > 0) {
                chat.scrollTop = chat.scrollHeight;
              }
            }, 5);
          }
          this.UPDATE_MESSAGE(null);
        }
      }
    },
  },
  async created() {
    Promise.all([await this.getItems(), await this.getAssignees()]).finally(
      () => {
        this.load = true;
      }
    );
  },
 
  methods: {
    ...mapActions("getting", ["getRoute"]),
    ...mapMutations("chat", ["UPDATE_MESSAGE"]),

    async getItems() {
      try {
        const { result } = await this.getRoute(`tickets/${this.ticketId}`);
        const { replies, ...other } = result.ticket;
        this.item = other;
        this.replies =
          other.status !== "open"
            ? new Array().concat(replies, [
                {
                  flag: true,
                  content: `Current ticket ${other.status} you're not able to send any messages`,
                },
              ])
            : replies || [];
      } catch (error) {
        this.$router.push("/support");
      } finally {
        const chat = document.querySelector('[data-type="chatBox"]');
        if (chat) {
          setTimeout(() => {
            if (this.replies.length > 0) {
              chat.scrollTop = chat.scrollHeight;
            }
          }, 5);
        }
      }
    },
    async getAssignees() {
      try {
        const { result } = await this.getRoute("admins");
        this.assignees = result.admins;
      } catch (error) {}
    },
    async addReply() {
      try {
        this.add_disabled = true;
        this.add_errors = {};
        let fd = new FormData();

        for (const key in this.add_data) {
          const item = this.add_data[key];
          if (key === "attachments") {
            for (const attach in item) {
              if (item[attach]) {
                fd.append(`${key}[${attach}]`, item[attach], item[attach].name);
              }
            }
          } else {
            fd.append(key, item);
          }
        }

        const { data } = await this.axios.post("tickets/add_reply", fd);
        this.add_data = {
          attachments: [],
          id: this.ticketId,
        };
        this.add_active = false;
        if (this.add_data.attachments.length) {
          this.$refs.attachments_updater.value = null;
          this.$refs.images_uploader.value = null;
        }
        this.createAlert(data?.message);
        this.getItems();
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.add_errors = responseError;
        } else {
          this.createAlert(responseError, "error");
        }
      } finally {
        this.add_disabled = false;
      }
    },
    handleAttachmentsAndImagesUploader(e) {
      const files = e.target.files;
      let result = [];

      if (!files.length) {
        this.createAlert(
          "There are no files selected to display and send them to server, try again later",
          "info"
        );
        return;
      }

      for (const file of files) {
        result.push(file);
      }
      this.add_data.attachments = new Array().concat(
        this.add_data.attachments,
        result
      );
      // remove current uploader value after add it value inside attachments
      e.target.value = null;
    },
    async deleteItem() {
      this.delete_disabled = true;
      try {
        const { data } = await this.axios.post(
          `tickets/delete/${this.ticketId}`
        );
        this.createAlert(data.message);
        this.getItems();
      } catch (err) {
        const res = err?.response;
        if (!res) {
          this.createAlert(
            "There is something went wrong, please try again later.",
            "error"
          );
          return;
        }
        this.createAlert(res?.data?.message, "error");
      } finally {
        this.delete_disabled = false;
      }
    },
    async assignItem() {
      try {
        this.assign_disabled = true;
        this.assign_errors = {};

        const { data } = await this.axios.post(
          "tickets/assign",
          this.assign_data
        );

        this.assign_data = {
          admin_id: null,
          id: this.ticketId,
        };

        this.assign_active = false;
        this.createAlert(data?.message);
        this.getItems();
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.assign_errors = responseError;
        } else {
          this.createAlert(responseError, "error");
        }
      } finally {
        this.assign_disabled = false;
      }
    },
    async statusItem() {
      try {
        this.status_disabled = true;
        this.status_errors = {};

        const { data } = await this.axios.post(
          "tickets/update_status",
          this.status_data
        );
        this.status_data = {
          status: data.result.status,
          id: this.ticketId,
        };

        this.status_active = false;
        this.createAlert(data?.message);
        this.getItems();
      } catch (error) {
        console.log(error);
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.status_errors = responseError;
        } else {
          this.createAlert(responseError, "error");
        }
      } finally {
        this.status_disabled = false;
      }
    },
    async priorityItem() {
      try {
        this.priority_disabled = true;
        this.priority_errors = {};

        const { data } = await this.axios.post(
          "tickets/update_priority",
          this.priority_data
        );

        this.priority_data = {
          priority: data.result.priority,
          id: this.ticketId,
        };

        this.priority_active = false;
        this.createAlert(data?.message);
        this.getItems();
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.priority_errors = responseError;
        } else {
          this.createAlert(responseError, "error");
        }
      } finally {
        this.priority_disabled = false;
      }
    },
    handleClose() {
      this.assign_data = {
        admin_id: null,
        id: this.ticketId,
      };
      this.assign_active = false;
      this.assign_errors = {};
      this.status_data = {
        status: this.item.status,
        id: this.ticketId,
      };
      this.status_active = false;
      this.status_errors = {};
      this.priority_data = {
        priority: this.item.priority,
        id: this.ticketId,
      };
      this.priority_active = false;
      this.priority_errors = {};
    },
  },
};
</script>
