<template>
  <section class="w-full p-6 space-y-6">
    <Table
      title="Packages"
      :records="`${pagination.total || 0} records`"
      :pagination="pagination"
      :total_pages="pagination.total_pages"
      v-model="pagination.page"
    >
      <template #actions>
        <div>
          <router-link
            to="/packages/create"
            class="
              py-1.5
              px-3
              text-sm
              flex
              items-center
              gap-2
              text-gray-600
              font-medium
              rtl:font-semibold
            "
          >
            <i class="fa-solid fa-plus"></i>
            <span>New package</span>
          </router-link>
        </div>
      </template>
      <template #filter>
        <ul class="flex items-center">
          <li v-for="(item, index) in status" :key="index">
            <button
              class="
                py-4
                px-6
                text-center
                font-medium
                rtl:font-semibold
                text-sm
                capitalize
              "
              @click.prevent="
                changeFilterStatus(item === 'all' ? null : item, index)
              "
              :class="{
                'active-tab':
                  pagination.is_active === (item === 'all' ? null : item),
              }"
              :disabled="disables[index]"
            >
              <spinner
                size="w-4 h-4 inline-block"
                class="mr-2 rtl:mr-0 rtl:ml-2"
                v-if="disables[index]"
              />
              <!-- {{ $t(item.replace(/\s/g, "_")) }} -->
              {{ item }}
            </button>
          </li>
        </ul>
      </template>
      <template #head>
        <th>Package</th>
        <th>Price / Alt price (SAR)</th>
        <th>Total price / Total alt price (SAR)</th>
        <th>Months</th>
        <th>Installments</th>
        <th>Devices</th>
        <th>Active</th>
        <th>Latest update</th>
        <th>Actions</th>
      </template>
      <template #tbody v-if="!load">
        <TableLoad :rows="9" v-for="i in 4" :key="i" />
      </template>
      <template #tbody v-else-if="load && items.length">
        <tr v-for="(item, index) in items" :key="index">
          <td>
            <div class="flex items-center gap-3">
              <div class="w-10 h-10 shrink-0">
                <img
                  :src="
                    item['image_' + $i18n.locale] || require('@/assets/images/placeholder.png')
                  "
                  @error="
                    $event.target.src = require('@/assets/images/placeholder.png')
                  "
                  alt="package image"
                  class="
                    w-full
                    h-full
                    rounded-md
                    border
                    object-cover
                    border-gray-100
                  "
                />
              </div>
              <span
                class="inline-block line-clamp-2 max-w-sm whitespace-normal"
              >
                {{ item["name_" + $i18n.locale] }}
              </span>
            </div>
          </td>

          <td>
            {{ (item.price || 0).toFixed(2) }} <br />
            {{ (item.alt_price || 0).toFixed(2) }}
          </td>
          <td>
            {{ (item.total_price || 0).toFixed(2) }} <br />
            {{ (item.total_alt_price || 0).toFixed(2) }}
          </td>
          <td>{{ item.months }}</td>
          <td>{{ item.installments }}</td>
          <td>
            <button
              class="w-8 h-8 bg-sky-500/10 text-sky-500 rounded"
              @click.prevent="showDevices(item.id)"
              :content="'Show devices'"
              v-tippy
              v-if="(item.devices || []).length"
            >
              <i class="fa-solid fa-eye"></i>
            </button>
            <span v-else>-</span>
          </td>

          <td>
            <p class="status capitalize" :class="item_status[item.is_active]">
              {{ item.is_active === 1 ? "Active" : "Not active" }}
            </p>
          </td>

          <td v-html="createTime(item.updated_at || item.created_at)"></td>

          <td>
            <div class="flex items-center gap-2">
              <div>
                <button
                  class="p-2 px-3 text-red-500 font-medium rtl:font-semibold"
                  @click.prevent="deleteItem(item.id)"
                  :disabled="disables[`delete_${item.id}`]"
                >
                  <spinner
                    size="w-4 h-4 inline-block"
                    v-if="disables[`delete_${item.id}`]"
                  />
                  <span v-else> Delete </span>
                </button>
              </div>
              <div>
                <button
                  class="w-8 h-8 bg-sky-500/10 text-sky-500 rounded"
                  @click.prevent="changeStatus(item.id)"
                  :content="'Change status'"
                  v-tippy
                  :disabled="disables[`status_${item.id}`]"
                >
                  <spinner
                    size="w-4 h-4 inline-block"
                    v-if="disables[`status_${item.id}`]"
                  />
                  <i class="fa-solid fa-right-left" v-else></i>
                </button>
              </div>
              <div>
                <router-link
                  :to="`/packages/${item.id}`"
                  class="
                    w-8
                    h-8
                    bg-sky-600/10
                    text-sky-600
                    rounded
                    flex
                    items-center
                    justify-center
                  "
                  :content="'Update'"
                  v-tippy
                >
                  <i class="fa-solid fa-pen"></i>
                </router-link>
              </div>
            </div>
          </td>
        </tr>
      </template>

      <template #tbody v-else>
        <tr>
          <td colspan="9" class="!text-center">There are no packages</td>
        </tr>
      </template>
    </Table>

    <transition name="scale">
      <Modal
        v-if="show_devices"
        @close="show_devices = $event"
        :title="`${item['name_' + $i18n.locale]}`"
      >
        <template>
          <div class="p-4">
            <ul class="list-none space-y-3 divide-y divide-gray-100">
              <li
                class="flex gap-4 pt-3 first:p-0"
                v-for="(device, index) in item.devices"
                :key="index"
              >
                <div
                  class="
                    shrink-0
                    w-14
                    h-14
                    rounded
                    flex
                    items-center
                    justify-center
                    bg-gray-100
                    border border-gray-200
                  "
                >
                  <img
                    :src="
                      device.image || require('@/assets/images/placeholder.png')
                    "
                    @error="
                      (e) =>
                        (e.target.src = require('@/assets/images/placeholder.png'))
                    "
                    alt="commercial_register_image"
                    class="max-w-full max-h-full object-contain"
                  />
                </div>
                <div class="flex-1 space-y-2">
                  <p class="font-semibold rtl:font-bold text-sm">
                    {{ device["name_" + $i18n.locale] }}
                    <span class="inline-block text-xs font-bold text-primary">
                    </span>
                  </p>
                  <p class="font-semibold rtl:font-bold text-sm text-gray-600">
                    Price:
                    <span class="inline-block text-xs font-bold text-primary">
                      {{ device.price }} SAR
                    </span>
                  </p>
                  <p
                    class="font-medium rtl:font-semibold text-sm text-gray-600"
                  >
                    {{ device["desc_" + $i18n.locale] }}
                  </p>
                </div>
              </li>
              <li class="pt-3">
                <div class="grid grid-cols-2">
                  <div>
                    <p class="font-medium text-xs text-gray-500">
                      Devices price
                    </p>
                  </div>
                  <div>
                    <p class="font-semibold text-sm text-gray-600">
                      {{
                        (+item.devices_price || 0).toFixed(2) + " SAR" || "N/A"
                      }}
                    </p>
                  </div>
                </div>
              </li>
              <li class="pt-3">
                <div class="grid grid-cols-2">
                  <div>
                    <p class="font-medium text-xs text-gray-500">
                      Devices installments
                    </p>
                  </div>
                  <div>
                    <p class="font-semibold text-sm text-gray-600">
                      {{ item.devices_installment ? "Yes" : "No" }}
                    </p>
                  </div>
                </div>
              </li>
              <li class="pt-3">
                <div class="grid grid-cols-2">
                  <div>
                    <p class="font-medium text-xs text-gray-500">
                      Devices prices per installment
                    </p>
                  </div>
                  <div>
                    <p class="font-semibold text-sm text-gray-600">
                      {{
                        item.devices_installment
                          ? (
                              (+item.devices_price || 0) / +item.installments ||
                              0
                            ).toFixed(2) + " SAR"
                          : "N/A"
                      }}
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </template>
      </Modal>
    </transition>
  </section>
</template>
<script>
import Modal from "@/components/basics/Modal.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  name: "Packages",
  data() {
    return {
      load: false,
      items: [],
      item_status: {
        1: "bg-sky-500/10 text-sky-500",
        0: "bg-red-500/10 text-red-500",
      },
      status: ["all", "active", "not active"],
      pagination: {
        is_active: null,
        page: 1,
        sort_key: null,
        sort_type: "asc",
        supplier_id: this.$route.query?.supplier,
        warehouse_id: this.$route.query?.warehouse,
      },
      disables: {},
      item: {},
      show_devices: false,
    };
  },
  created() {
    this.getItems();
  },
  watch: {
    "pagination.page"() {
      this.getItems();
    },
    is_called(val, _) {
      if (val) {
        this.getItems();
      }
    },
  },
  computed: {
    ...mapGetters("table", ["search", "is_called"]),
  },
  methods: {
    ...mapActions("getting", ["getRoute"]),
    ...mapMutations("table", ["changeIsCalled"]),
    async getItems() {
      try {
        const { total, total_pages, ...others } = this.pagination;
        const user = this.$store.state.user;
        const { result } = await this.getRoute({
          name: "packages",
          options: {
            ...others,
            is_active: others.is_active
              ? others.is_active === "active"
                ? 1
                : 0
              : null,
            search_key: this.search,
            vendor_id: user.user_type === "vendor" ? user?.id : null,
            branch_id: user.user_type === "cashier" ? user?.id : null,
          },
        });
        const { data, pagination } = result.packages;
        this.items = data;
        this.$set(this.pagination, "total", pagination.total);
        this.$set(this.pagination, "total_pages", pagination.total_pages);
      } catch (error) {
        console.log(error);
        // this.createAlert(result?.message, type);
      } finally {
        this.load = true;
        this.changeIsCalled(false);
      }
    },
    async changeStatus(id) {
      this.$set(this.disables, `status_${id}`, true);
      try {
        const { data } = await this.axios.post(
          `packages/update_is_active/${id}`
        );
        this.createAlert(data.message);
        this.getItems();
      } catch (err) {
        const res = err?.response;
        if (!res) {
          this.createAlert(
            "There is something went wrong, please try again later.",
            "error"
          );
          return;
        }
        this.add_errors = res?.data?.message;
        this.createAlert(res?.data?.message, "error");
      } finally {
        this.disables = {};
      }
    },
    async deleteItem(id) {
      this.$set(this.disables, `delete_${id}`, true);
      try {
        const { data } = await this.axios.post(`packages/delete/${id}`);
        this.createAlert(data.message);
        this.getItems();
      } catch (err) {
        const res = err?.response;
        if (!res) {
          this.createAlert(
            "There is something went wrong, please try again later.",
            "error"
          );
          return;
        }
        this.add_errors = res?.data?.message;
        this.createAlert(res?.data?.message, "error");
      } finally {
        this.disables = {};
      }
    },
    sortTable(key) {
      if (this.pagination.sort_key != key) {
        this.pagination.sort_type = "asc";
      } else {
        this.pagination.sort_type =
          this.pagination.sort_type == "asc" ? "desc" : "asc";
      }
      this.pagination.sort_key = key;
      this.getItems().finally(() => {
        this.createAlert(`Table sorted by ${key}`);
      });
    },
    changeFilterStatus(status, index) {
      this.$set(this.disables, index, true);
      this.$set(this.pagination, "is_active", status);
      this.getItems().finally(() => {
        this.disables = {};
      });
    },
    showDevices(id) {
      const item = this.items.find((e) => e.id == id);
      this.item = item;
      this.show_devices = true;
    },
  },
  components: { Modal },
};
</script>
