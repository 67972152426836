<template>
  <div class="p-6">
    <GeneralSettings />
  </div>
</template>
<script>
import GeneralSettings from "@/components/settings/GeneralSettings.vue";
export default {
  components: {
    
    GeneralSettings,
  },
  data() {
    return {
      component: "GeneralSettings",
    };
  },
  name: "SettingsPage",
};
</script>
