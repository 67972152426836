export default {
  dashboard: 'لوحة التحكم',
  calendar: 'التقويم',
  users: 'المستخدمون',
  user_visits: 'مستخدم الزيارات',
  user: 'المستخدم',
  new_visit: 'زيارة جديدة',
  reports: 'التقارير',
  visits: 'الزيارات',
  visit: 'زيارة',
  skus: 'SKUs',
  expiry: 'منهيه الصلاحية',
  expiries: 'انتهاء الصلاحية',
  stock_audits: 'عمليات تدقيق الأسهم',
  shelf_audits: 'تدقيق الرف',
  shelf: 'رفوف',
  returns: 'المسترجعات',
  promotion: 'ترقية وظيفية',
  planogram: 'المخطط',
  setup: 'الاعدادات',
  journey_planes: 'الرحلات',
  configuration: 'المخازن',
  shelves: 'أرفف',
  planograms: 'المخططات',
  teams: 'الفرق',
  permissions: 'الصلاحيات',
  stores: 'المتاجر',
  store: 'متجر',
  branches: 'الفروع',
  branches_count: 'عدد الفروع',
  branch: 'فرع',
  brands: 'العلامات التجارية',
  brand: 'ماركة',
  categories: 'التصنيفات',
  category: 'التصنيف',
  sub_categories: 'التصنيفات الفرعية',
  sub_category: 'التصنيف فرعي',
  store_placements: 'مواضع المتجر',
  store_placement: 'وضع التخزين',
  units: 'الوحدات',
  unit: 'وحدة',
  notifications: 'الاشعارات',
  messages: 'الرسائل',
  regions: 'المناطق',
  region: 'المنطقة',
  cities: 'المدن',
  city: 'المدينة',
  expiry_skus: 'SKUs انتهاء الصلاحية',
  out_of_stock: 'إنتهى من المخزن',
  supervisors: 'المشرفين',
  supervisor: 'مشرف',
  leaders: 'القادة',
  leader: 'قائد',
  sku: 'SKU',
  total_visits_per_week: 'إجمالي الزيارات في الأسبوع',
  visits_per_status: 'زيارات لكل حالة',
  visits_per_day: 'الزيارات اليومية',
  total_visits: 'اجمالي الزيارات',
  visit_summary: 'ملخص الزيارات',
  recent_visits: 'الزيارات الأخيرة',
  done: 'منتهيه',
  missed: 'مفقوده',
  pending: 'قيد الانتظار',
  extra_mission: 'مهمة إضافية',
  extra_mission_done: 'مهمة إضافية منتهيه',
  added_by_user: 'اضيفت بواسطة مستخدم',
  skipped: 'تخطي',
  cancelled: 'ألغيت',
  scheduled: 'مجدول',
  active: 'نشيط',
  not_active: 'غير نشيط',
  available: 'متوفر',
  not_available: 'غير متوفر',
  yes: 'نعم',
  No: 'لا',
  customize: 'تخصيص',
  download: 'تحميل',
  send_email: 'أرسل عبر البريد الإلكتروني',
  filter: 'تصفيه',
  add_new: {
    category: 'تصنيف جديد',
    sub_category: 'تصنيف فرعي جديد',
    store: 'متجر جديد',
    branch: 'فرع جديد',
    brand: 'العلامة التجارية الجديدة',
    team: 'فريق جديد',
    user: 'موظف جديد',
    client: 'عميل جديد',
    visit: 'زيارة جديدة',
    sku: 'SKU جديد',
    shelf: 'رف جديد',
    planogram: 'رسم تخطيطي جديد',
    new: 'اضف جديد',
    now: 'اضف الان',
    company: 'ِشركة جديدة',
    country: 'دولة جديدة',
    region: 'منطقة جديدة',
    city: 'مدينة جديدة',
    store_placement: 'مخزن جديد',
    task: 'مهمة جديدة',
    unit: 'وحدة جديدة',
    admin: 'مدير جديد',
    role: 'مجموعة جديدة',
    zone: 'منطقة جديدة',
    district: 'حي جديد',
    tutorial: 'دورة جديده',
    group: 'مجموعة جديدة',
    page: 'صفحة جديدة',
    partner: 'شريك جديد',
  },
  update: 'تحديث',
  assign_branches: 'تعيين للفروع',
  assign_units: 'تعيين الوحدات',
  assign_info: 'بيانات المعين',
  assignee: 'المعين',
  client_info: 'بيانات العميل',
  delete: 'حذف',
  add: 'اضافة',
  save: 'حفظ',
  save_changes: 'حفظ التغييرات',
  submit: 'ارسال',
  cancel: 'الغاء',
  name: 'الاسم',
  alt_name: 'اسم بديل',
  email: 'عنوان البريد الالكترونى',
  mobile: 'رقم الجوال',
  barcode: 'الرمز الشريطي',
  selling_price: 'سعر البيع',
  value: 'القيمة',
  actual_value: 'القيمة الفعلية',
  tasks: 'المهام',
  required_tasks: 'المهام المطلوبة',
  visit_date: 'زيارة التاريخ',
  created_at: 'تاريخ الإنشاء',
  updated_at: 'اخر تحديث',
  start_date: 'تاريخ البدء',
  production_date: 'تاريخ الإنتاج',
  end_date: 'تاريخ الانتهاء',
  return_date: 'تاريخ الاسترجاع',
  expiry_date: 'تاريخ انتهاء الصلاحية',
  joined_date: 'تاريخ الانضمام',
  qty: 'الكمية',
  in_location: 'في الموقع',
  out_location: ' خارج موقع',
  search: 'يبحث...',
  status: 'الحالة',
  create_new: {
    category: 'إنشاء تصنيف جديد',
    sub_category: 'إنشاء تصنيف فرعي جديد',
    store: 'إنشاء متجر جديد',
    branch: 'إنشاء فرع جديد',
    brand: 'إنشاء علامة تجارية جديدة',
    team: 'إنشاء فريق جديد',
    user: 'إنشاء موظف جديد',
    visit: 'إنشاء زيارة جديدة',
    sku: 'إنشاء SKU جديد',
    shelf: 'إنشاء رف جديد',
    planogram: 'إنشاء مخطط جديد',
  },
  currency: {
    sar: 'ريال سعودي',
  },
  next: 'التالي',
  prev: 'السابق',
  view_details: 'عرض التفاصيل',
  notes: 'ملحوظات',
  reason: 'السبب',
  apply: 'تطبيق',
  reset: 'الغاء',
  image: 'صورة',
  images: 'الصور',
  images_before: 'الصور من قبل',
  images_after: 'الصور بعد',
  type: 'النوع',
  visit_type: 'نوع الزيارة',
  change_status: 'تغيير الحالة',
  view: 'عرض',
  view_update: 'عرض وتحديث',
  listed: 'الفروع المدرجة',
  manager_mobile: 'رقم مدير الفرع',
  address: 'العنوان',
  lat: 'خط العرض',
  lng: 'خط الطول',
  lat_lng: 'خط العرض وخط الطول',
  logout: 'تسجيل خروج',
  edit: 'يحرر',
  edit_profile: 'تعديل الملف الشخصي',
  window_width: 'عرض النافذة',
  view_all: 'عرض الكل',
  all: 'الكل',
  langs: {
    ar: 'عربي',
    en: 'إنجليزي',
  },
  emails: 'عناوين البريد الإلكتروني',
  customize_excel: 'تخصيص أعمدة Excel',
  customize_table: 'تخصيص الجدول',
  total_skus: 'اجمالي المنتجات',
  total_visits: 'اجمالي الزيارات',
  records: 'السجلات',
  password: 'كلمة المرور',
  new_password: 'كلمة السر الجديدة',
  current_password: 'كلمة المرور الحالية',
  confirmation_password: 'تأكيد كلمة المرور',
  generate_password: 'إنشاء كلمة المرور',
  force_password: 'يجب تغيير كلمة المرور',
  multi_client: 'متعدد العملاء',
  no_data: 'لا توجد بيانات لعرضها',
  role: 'الوظيفة',
  username: 'اسم المستخدم',
  merchandiser: 'تاجر',
  merchandisers: 'التجار',
  supervisor: 'مشرف',
  super_user: 'مستخدم متميز',
  client: 'عميل',
  clients: 'العملاء',
  administration: 'الادارة',
  week_days: {
    SAT: 'السبت',
    SUN: 'الاحد',
    MON: 'الاثنين',
    TUE: 'الثلاثاء',
    WED: 'الاربعاء',
    THU: 'الخميس',
    FRI: 'الجمعة',
  },
  visit_time: {
    today: 'اليوم',
    weekly: 'أسبوعي',
    monthly: 'شهريا',
  },
  weeks: {
    1: 'الأسبوع الأول',
    2: 'الأسبوع الثاني',
    3: 'الأسبوع الثالث',
    4: 'الأسبوع الرابع',
  },
  choose_image: 'اختر صورة',
  choose_photo: 'اختر صورة',
  profile_photo: 'صورة الملف الشخصي',
  all_selected: 'جميع العناصر مختاره بالفعل',
  email_note: 'سيتم تسجيل الخروج من الحساب إذا قمت بتغيير البريد الإلكتروني',
  visits_no_data: 'لا توجد زيارات في ذلك اليوم',
  user_no: 'لا يوجد مستخدمون لعرضهم',
  user_no_content: `لا يوجد مستخدمون لعرض زياراتهم ، بحيث يمكنك إضافة ملف
                  زيارة جديدة واختر الفرع المطلوب ثم حدد
                  زيارات ، انقر فوق الزر أدناه للانتقال إلى صفحة الزيارة الجديدةديدة`,
  completed: 'مكتمل',
  login: 'تسجيل دخول',
  copyRight: 'جميع الحقوق محفوظة لدى',
  cost: 'التكلفة',
  add_info_visit: 'هل تريد اضافة زيارة جديدة',
  location_empty: 'لم يتم تحديد الموقع من قبل المستخدم حتى الآن',
  join_requests: 'طلبات الانضمام',
  countries: 'الدول',
  admins: 'المدراء',
  companies: 'الشركات',
  accepted: 'مقبول',
  rejected: 'مرفوض',
  rejected_reason: 'سبب الرفض',
  accept: 'قبول',
  reject: 'رفض',
  country: 'الدولة',
  permissions: 'الصلاحيات',
  permission: 'الصلاحية',
  roles: 'المجموعات',
  select_all: 'تحديد الكل',
  import: 'تصدير',
  contact_name: 'اسم جهة التواصل',
  latest_companies: 'أحدث الشركات',
  latest_join_requests: 'آخر طلبات انضمام',
  listed_stores: 'المتاجر المدرجة',
  listed_branches: 'الفروع المدرجة',
  show: 'عرض',
  hide: 'اخفاء',
  new: 'جديد',
  zones: 'المناطق',
  merchandisers_count: 'عدد التجار',
  active_merchandisers: 'التجار النشطين',
  not_active_merchandisers: 'التجار الغير نشطين',
  domain: 'اسم النطاق',
  client_users: 'مستخدمي العميل',
  go_website: 'الانتقال للموقع',
  modules: 'المهام',
  title: 'العنوان',
  keywords: 'الكلمات الدلاليه',
  tutorials: 'الدورات التعليمية',
  video_url: 'رابط الفيديو',
  requested_branches: 'طلبات اضافة الفروع',
  add_data: 'اضافة البيانات',
  select: 'اختار',
  region_groups: 'مجموعات المنطقة',
  district_group: 'مجموعة الاحياء',
  clear_all: 'مسح الكل',
  districts: 'الاحياء',
  settings: 'الاعدادات',
  logs: 'Logs',
  deletion: {
    title: 'هل أنت متأكد أنك تريد حذف ذلك؟',
    content:
      'إذا كنت لا تزال ترغب في حذفه ، فلن يكون لديك الصلاحية لإعادة الوصول إليه ، وسيتم حذف كل ما يتعلق به',
    code: 'الرجاء إدخال الرمز المرسل عبر البريد الإلكتروني ، وسيتم تأكيد عملية الحذف',
  },
  wrong_location: 'موقع خاطئ',
  pages: 'الصفح',
  partners: 'الشركاء',
  content: 'المحتوى',
  desc: "الوصف"

  // {{ $t(item.replace(/\s/g, '_')) }}
}
