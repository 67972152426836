<template>
  <section class="w-full p-6">
    <form class="w-full mx-auto space-y-6" @submit.prevent novalidate>
      <figure class="w-full bg-white rounded ring-1 ring-gray-200" >
        <header class="p-4">
          <p class="text-base font-semibold rtl:font-bold">Product source</p>
        </header>
        <blockquote class="w-full p-5 border-t border-gray-200 space-y-4">
          <ul class="space-y-4">
            <li class="checkbox" v-for="(item, index) in sources" :key="index">
              <input
                type="radio"
                name="sourceGroup"
                :id="item"
                :value="item"
                v-model="add_data.dest"
              />
              <label
                :for="item"
                class="text-sm font-medium rtl:font-semibold capitalize"
                >{{ item.replace(/(-|_)/g, ' ') }}</label
              >
            </li>
          </ul>
        </blockquote>
      </figure>
      <figure class="w-full bg-white rounded ring-1 ring-gray-200">
        <header class="p-4">
          <p class="text-base font-semibold rtl:font-bold">Product details</p>
        </header>
        <blockquote class="w-full p-5 border-t border-gray-200 space-y-4">
          <figure class="space-y-4">
            <p class="text-sm font-medium rtl:font-semibold">Image</p>
            <slide-transition>
              <figcaption v-if="add_data.image" class="relative table">
                <img
                  :src="
                    add_data.image
                      ? convertImage(add_data.image)
                      : require('@/assets/images/placeholder.png')
                  "
                  @error="
                    (e) =>
                      (e.target.src = require('@/assets/images/placeholder.png'))
                  "
                  alt="branch image"
                  class="w-auto max-h-[7rem] max-w-[5rem] object-contain"
                />
                <button
                  class="
                    w-6
                    h-6
                    rounded-full
                    flex
                    items-center
                    justify-center
                    bg-red-500
                    text-white
                    absolute
                    -top-2
                    -right-2
                    rtl:right-auto rtl:-left-2
                    ring-2 ring-white
                  "
                  type="button"
                  @click.prevent="() => clearImage('add_data')"
                >
                  <i class="fa-solid fa-xmark fa-sm"></i>
                </button>
              </figcaption>
            </slide-transition>
            <blockquote class="space-y-2">
              <label class="block">
                <span class="sr-only">Choose image</span>
                <input
                  type="file"
                  accept="image/*"
                  class="custom-file"
                  ref="uploader"
                  @change="(e) => $set(add_data, 'image', e.target.files[0])"
                />
              </label>
              <p class="error" v-if="add_errors.image">
                {{ add_errors.image.join(" ") }}
              </p>
            </blockquote>
          </figure>
          <div class="w-full">
            <div class="w-full flex gap-4 flex-col sm:flex-row">
              <div class="form-group space-y-3 flex-1">
                <label for="name_ar"> Name | Ar </label>
                <input
                  type="text"
                  name="name_ar"
                  id="name_ar"
                  v-model="add_data.name_ar"
                  placeholder="الاسم"
                  v-rtl
                />
                <p class="error">
                  {{ (add_errors["name_ar"] || []).join(" ") }}
                </p>
              </div>
              <div class="form-group space-y-3 flex-1">
                <label for="name_en"> Name | En </label>
                <input
                  type="text"
                  name="name_en"
                  id="name_en"
                  v-model="add_data.name_en"
                  placeholder="Name"
                  v-ltr
                />
                <p class="error">
                  {{ (add_errors["name_en"] || []).join(" ") }}
                </p>
              </div>
            </div>
          </div>
          <div class="w-full">
            <div class="w-full flex gap-4 flex-col sm:flex-row">
              <div class="form-group space-y-3 flex-1">
                <label for="desc_ar"> Description | Ar </label>
                <textarea
                  name="desc_ar"
                  id="desc_ar"
                  placeholder="..."
                  v-rtl
                  v-model="add_data.desc_ar"
                ></textarea>
                <p class="error">
                  {{ (add_errors["desc_ar"] || []).join(" ") }}
                </p>
              </div>
              <div class="form-group space-y-3 flex-1">
                <label for="desc_en"> Description | En </label>
                <textarea
                  name="desc_en"
                  id="desc_en"
                  placeholder="..."
                  v-ltr
                  v-model="add_data.desc_en"
                ></textarea>
                <p class="error">
                  {{ (add_errors["desc_en"] || []).join(" ") }}
                </p>
              </div>
            </div>
          </div>
          <div class="w-full">
            <div class="w-full flex gap-4 flex-col sm:flex-row">
              <div class="flex-1 form-group space-y-3">
                <label for="prod_date"> Production date </label>
                <t-datepicker
                  placeholder="xxxx-xx-xx"
                  v-model="add_data.prod_date"
                  :clearable="false"
                  id="prod_date"
                  userFormat="Y-m-d"
                  :weekStart="6"
                />
                <p class="error">
                  {{ (add_errors.prod_date || []).join(" ") }}
                </p>
              </div>
              <div class="flex-1 form-group space-y-3">
                <label for="exp_date"> Expiry date </label>
                <t-datepicker
                  placeholder="xxxx-xx-xx"
                  v-model="add_data.exp_date"
                  :clearable="false"
                  id="exp_date"
                  userFormat="Y-m-d"
                  :weekStart="6"
                  :minDate="add_data.prod_date"
                />
                <p class="error">
                  {{ (add_errors.exp_date || []).join(" ") }}
                </p>
              </div>
            </div>
          </div>
          <div class="w-full">
            <div class="w-full flex gap-4 flex-col sm:flex-row">
              <div class="form-group space-y-3 flex-1">
                <label for="barcode"> Barcode </label>
                <input
                  type="text"
                  name="barcode"
                  id="barcode"
                  v-model="add_data.barcode"
                  placeholder="123456789"
                  v-ltr
                />
                <p class="error">
                  {{ (add_errors["barcode"] || []).join(" ") }}
                </p>
              </div>
              <div class="form-group space-y-3 flex-1">
                <label for="sku"> SKU </label>
                <input
                  type="text"
                  name="sku"
                  id="sku"
                  v-model="add_data.sku"
                  placeholder="ABCDEFG"
                  v-ltr
                />
                <p class="error">
                  {{ (add_errors["sku"] || []).join(" ") }}
                </p>
              </div>
            </div>
          </div>
        </blockquote>
      </figure>
      <figure class="w-full bg-white rounded ring-1 ring-gray-200">
        <header class="p-4">
          <p class="text-base font-semibold rtl:font-bold">Price</p>
        </header>
        <blockquote class="w-full p-5 border-t border-gray-200 space-y-4">
          <div class="w-full">
            <div class="w-full flex gap-4 flex-col sm:flex-row">
              <div class="form-group space-y-3 flex-1 hidden">
                <label for="qty"> Quantity </label>
                <input
                  type="number"
                  name="qty"
                  id="qty"
                  v-model="add_data.qty"
                  placeholder="1"
                  min="1"
                />
                <p class="error">
                  {{ (add_errors["qty"] || []).join(" ") }}
                </p>
              </div>
              <div class="form-group space-y-3 flex-1">
                <label for="price"> Price </label>
                <input
                  type="number"
                  name="price"
                  id="price"
                  v-model="add_data.price"
                  placeholder="1"
                  min="1"
                />
                <p class="error">
                  {{ (add_errors["price"] || []).join(" ") }}
                </p>
              </div>
            </div>
          </div>
        </blockquote>
      </figure>
      <figure class="w-full bg-white rounded ring-1 ring-gray-200">
        <header class="p-4">
          <p class="text-base font-semibold rtl:font-bold">Other details</p>
        </header>
        <blockquote class="w-full p-5 border-t border-gray-200 space-y-4">
          <div class="w-full">
            <div class="w-full flex gap-4 flex-col sm:flex-row">
              <div class="form-group space-y-3 flex-1">
                <label for="category_id"> Category </label>
                <t-rich-select
                  :options="categories"
                  placeholder="Select"
                  :text-attribute="'name_' + $i18n.locale"
                  value-attribute="id"
                  v-model="add_data.category_id"
                />
                <p class="error">
                  {{ (add_errors["category_id"] || []).join(" ") }}
                </p>
              </div>
              <div class="form-group space-y-3 flex-1">
                <label for="sub_category_ids"> Sub categories </label>
                <select-with-limit
                  :fetch="getSubCategories"
                  placeholder="Select"
                  :text="'name_' + $i18n.locale"
                  id="id"
                  v-model="add_data.sub_category_ids"
                  :minimum-input-length="1"
                  minimumInputLengthText="Search"
                />
                <p class="error">
                  {{ (add_errors["sub_category_ids"] || []).join(" ") }}
                </p>
              </div>
            </div>
          </div>
          <div class="form-group space-y-3 flex-1" v-if="butchery">
            <label for="meat_type_id"> Meat type </label>
            <!-- :fetch-options="getBranches" -->
            <t-rich-select
              :options="meatTypes"
              placeholder="Select"
              :text-attribute="'name_' + $i18n.locale"
              value-attribute="id"
              :clear-able="true"
              :closeOnSelect="true"
              v-model="add_data.meat_type_id"
            />
            <!-- minimumInputLengthText="Search" -->
            <!-- :minimum-input-length="1" -->
            <p class="error">
              {{ (add_errors["meat_type_id"] || []).join(" ") }}
            </p>
          </div>
        </blockquote>
      </figure>
      <div v-if="false">
        <div class="form-group-with-check">
          <div>
            <input
              type="checkbox"
              id="has_wastage"
              name="has_wastage"
              v-model="has_wastage"
            />
          </div>
          <label for="has_wastage"> Has wastages? </label>
        </div>
      </div>
      <slide-transition>
        <figure
          class="w-full bg-white rounded ring-1 ring-gray-200"
          v-if="has_wastage"
        >
          <header class="p-4">
            <p class="text-base font-semibold rtl:font-bold">Wastages</p>
          </header>
          <blockquote class="w-full p-5 border-t border-gray-200 space-y-4">
            <ul class="list-none space-y-4">
              <li
                class="space-y-4"
                v-for="(wastage, index) in add_data.wastages"
                :key="index"
              >
                <div class="form-group space-y-3">
                  <label for="wastage_id"> Wastage </label>
                  <t-rich-select
                    :options="filterWastages"
                    placeholder="Select"
                    :text-attribute="'name_' + $i18n.locale"
                    value-attribute="id"
                    :clear-able="true"
                    :closeOnSelect="true"
                    v-model="add_data.wastages[index].id"
                  />
                  <p class="error">
                    {{ (add_errors[`wastages.${index}.id`] || []).join(" ") }}
                  </p>
                </div>
                <div class="form-group space-y-3">
                  <label for="weight"> Weight (KG) </label>
                  <div class="flex items-stretch gap-3">
                    <input
                      type="number"
                      name="weight"
                      id="weight"
                      v-model="add_data.wastages[index].value"
                      placeholder="1"
                      min="1"
                      class="flex-1"
                    />
                    <button
                      class="w-10 rounded bg-red-500 text-white shrink-0"
                      :disabled="add_data.wastages.length <= 1"
                      @click.prevent="removeCurrentItem(index)"
                    >
                      <i class="fa-solid fa-minus fa-sm"></i>
                    </button>
                  </div>
                  <p class="error">
                    {{
                      (add_errors[`wastages.${index}.value`] || []).join(" ")
                    }}
                  </p>
                </div>
                <p class="text-sm text-gray-600 font-semibold">Wastage type</p>

                <ul class="inline-flex flex-wrap gap-4">
                  <li>
                    <div class="form-group-with-check">
                      <div>
                        <input
                          type="radio"
                          :id="`fixed_${index}`"
                          :name="`wastage_${index}_value`"
                          value="fixed"
                          v-model="add_data.wastages[index].value_type"
                        />
                      </div>
                      <label :for="`fixed_${index}`"> Fixed </label>
                    </div>
                  </li>
                  <li>
                    <div class="form-group-with-check">
                      <div>
                        <input
                          type="radio"
                          :id="`percentage_${index}`"
                          :name="`wastage_${index}_value`"
                          value="percentage"
                          v-model="add_data.wastages[index].value_type"
                        />
                      </div>
                      <label :for="`percentage_${index}`"> Percentage </label>
                    </div>
                  </li>
                </ul>
                <p
                  class="error"
                  v-if="add_errors[`wastages.${index}.value_type`]"
                >
                  {{
                    (add_errors[`wastages.${index}.value_type`] || []).join(" ")
                  }}
                </p>
              </li>
              <li>
                <button
                  class="
                    bg-teal-600
                    text-white text-xs
                    rounded
                    font-semibold
                    py-2
                    px-4
                  "
                  @click.prevent="addNewItem"
                  :disabled="
                    !add_data.wastages[add_data.wastages.length - 1].id ||
                    !filterWastages.length
                  "
                >
                  Add new
                </button>
              </li>
            </ul>
          </blockquote>
        </figure>
      </slide-transition>
      <figure class="w-full bg-white rounded ring-1 ring-gray-200" v-if="false">
        <header class="p-4">
          <p class="text-base font-semibold rtl:font-bold">More images</p>
        </header>
        <blockquote class="w-full p-6 border-t border-gray-200 space-y-4">
          <div class="w-full space-y-3">
            <label
              for="images_uploader"
              class="
                w-full
                rounded-lg
                !py-12
                px-8
                bg-gray-400/10
                text-gray-400 text-center
                border-dashed border-2 border-gray-400/50
                block
                cursor-pointer
              "
              @dragover="
                $event.target.classList.replace('border-dashed', 'border')
              "
              @dragleave="
                $event.target.classList.replace('border', 'border-dashed')
              "
              @dragend="
                $event.target.classList.replace('border', 'border-dashed')
              "
              @drop="dropControl($event)"
            >
              <span
                class="
                  w-14
                  h-14
                  rounded-full
                  flex
                  items-center
                  justify-center
                  mx-auto
                  bg-gray-400/20
                  text-xl
                  mb-4
                "
              >
                <i class="fa-solid fa-arrow-up-from-bracket"></i>
              </span>
              <p class="text-sm font-medium rtl:font-semibold">
                Drop and Drag or browse images
              </p>
            </label>
            <input
              type="file"
              name="images_uploader"
              id="images_uploader"
              hidden
              class="hidden"
              multiple
              @change="attachmentsUploader"
              accept="image/*"
            />
            <div class="block mt-6 w-auto">
              <div class="flex items-start gap-4 flex-wrap">
                <div v-for="(img, imgIdx) in add_data.images" :key="imgIdx">
                  <div class="w-20 h-20 p-1 rounded ring-2 ring-gray-300">
                    <img
                      :src="convertImage(img)"
                      alt="upload"
                      class="w-full rounded h-full object-cover"
                    />
                  </div>
                  <button
                    class="
                      w-8
                      h-8
                      rounded-full
                      bg-white
                      text-red-500
                      -mt-4
                      mx-auto
                      block
                      text-sm
                      z-[1]
                      relative
                    "
                    @click.prevent="add_data.images.splice(imgIdx, 1)"
                  >
                    <i class="fa-solid fa-trash"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </blockquote>
      </figure>
      <button-with-loader
        btnClass="w-auto py-2 px-4 rounded bg-primary
          text-white text-xs"
        :disabled="add_disabled"
        @click="addProduct"
        >Save</button-with-loader
      >
    </form>
  </section>
</template>
<script>
import ButtonWithLoader from "@/components/basics/ButtonWithLoader.vue";
import { mapActions } from "vuex";
export default {
  components: { ButtonWithLoader },
  name: "CreateProduct",
  data() {
    return {
      add_data: {
        // source: "custom",
        dest: "store_default",
        // wastages: [
        //   {
        //     id: null,
        //     value: null,
        //     value_type: "fixed",
        //   },
        // ],
        images: [],
      },
      wastages: [],
      has_wastage: false,
      add_errors: {},
      add_disabled: false,
      categories: [],
      sub_categories: [],
      products: [],
      branches: [],
      suppliers: [],
      warehouses: [],
      meatTypes: [],
      sources: ["store_default" , "supplier_default"],
    };
  },
  async created() {
    Promise.all([
      await this.getCategories(),
      //   await this.getWarehouses(),
      //   await this.getSuppliers(),
      // await this.getWastages(),

      await this.getMeatTypes(),
    ]);
  },

  computed: {
    butchery() {
      const category_id = this.add_data?.category_id;
      const categories = this.categories;
      const category = categories.find((e) => e.id === category_id);
      const isExisted = category?.modules?.some(
        (e) => e.module_type === "butchery"
      );
      return isExisted;
    },
    filterWastages() {
      const selectedWastages = this.add_data.wastages;
      const mapWastages = selectedWastages.map((e) => e.id);
      const result = this.wastages.filter((e) => !mapWastages.includes(e.id));

      return result;
    },
  },
  methods: {
    ...mapActions("getting", ["getRoute"]),
    async getWastages() {
      try {
        const { result } = await this.getRoute("wastages");
        this.wastages = result.wastages;
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },
    async getCategories() {
      try {
        const { result } = await this.getRoute("categories");
        this.categories = result.categories;
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },

    async getMeatTypes() {
      try {
        const { result } = await this.getRoute("meat_types");
        this.meatTypes = result.meat_types;
        // return { results: result.branches };
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },
    async getWarehouses() {
      try {
        const { result } = await this.getRoute("warehouses");
        this.warehouses = result.warehouses;
        // return { results: result.branches };
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },
    async getProducts(q) {
      try {
        const { result } = await this.getRoute({
          name: "products",
          options: {
            search_key: q,
            supplier_id: this.add_data.supplier_id,
            // branch_id: this.add_data.branch_id ? :,
          },
        });
        return { results: result.products };
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },
    async getSuppliers(q) {
      try {
        const { result } = await this.getRoute({
          name: "users",
          options: {
            user_type: "supplier",
          },
        });
        // return { results: result.suppliers };
        this.suppliers = result.users;
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },
    async getSubCategories(q) {
      try {
        const { result } = await this.getRoute({
          name: "sub_categories",
          options: {
            search_key: q,
            category_id: this.add_data.category_id,
          },
        });
        return { results: result.sub_categories };
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },

    async addProduct() {
      this.add_disabled = true;
      this.add_errors = {};

      let fd = new FormData();

      for (const key in this.add_data) {
        const item = this.add_data[key];
        if (item) {
          // if (["supplier", "branch"].includes(this.add_data.source)) {
          //   if (
          //     [
          //       "qty",
          //       "price",
          //       "product_id",
          //       "branch_id",
          //       "supplier_id",
          //     ].includes(key)
          //   ) {
          //     fd.append(key, item);
          //   }
          // } else {
          //   if (["sub_category_ids", "category_ids"].includes(key)) {
          //     item.forEach((elem, index) => {
          //       fd.append(`${key}[${index}]`, elem);
          //     });
          //   } else
          // }
          if (key === "image") {
            fd.append(key, item, item.name);
          } else if (key === "images") {
            item.forEach((img, index) => {
              if (typeof img === "object") {
                fd.append(`${key}[${index}]`, img, img.name);
              } else {
                fd.delete(`${key}[${index}]`);
              }
            });
          } else if (key === "meat_type_id") {
            if (this.butchery) {
              fd.append(key, item);
            } else {
              fd.delete(key);
            }
          }
          //  else if (key === "wastages") {
          //   if (this.has_wastage) {
          //     for (const wastage in item) {
          //       fd.append(`${key}[${wastage}][id]`, item[wastage].id);
          //       fd.append(`${key}[${wastage}][value]`, item[wastage].value);
          //       fd.append(
          //         `${key}[${wastage}][value_type]`,
          //         item[wastage].value_type
          //       );
          //     }
          //   }
          // } 
          else {
            fd.append(key, item);
          }
        }
      }

      try {
        const { data } = await this.axios.post("products/add", fd);
        this.$router.push("/default-products");
        this.createAlert(data.message);
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.add_errors = responseError;
        } else {
          this.createAlert(responseError, "error");
        }
      } finally {
        this.add_disabled = false;

        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }
    },
    dropControl(e) {
      e.preventDefault();
      const files = e.dataTransfer.files;
      if (files) {
        files.forEach((file) => {
          this.add_data.images.push(file);
        });
        e.currentTarget.classList.replace("border", "border-dashed");
      }
    },
    attachmentsUploader(e) {
      e.preventDefault();
      e.target.files.forEach((file) => {
        this.add_data.images.push(file);
      });
    },
    uploadMainImage(e) {
      e.preventDefault();
      this.add_data.image = e.target.files[0];
    },
    convertImage(e) {
      return URL.createObjectURL(e);
    },
    clearImage(type) {
      const uploader = this.$refs.uploader;
      if (!uploader) return;
      uploader.value = null;
      this.$set(this[type], "image", null);
    },
    addNewItem() {
      if (!this.filterWastages.length) {
        this.createAlert("Wastages are not enough to add new one.", "error");
        return;
      }
      this.add_data.wastages.push({
        id: null,
        value: null,
        value_type: "fixed",
      });
    },
    removeCurrentItem(index) {
      if (index === 0 && this.add_data.wastages.length === 1) {
        this.createAlert("One wastage at least should be added!!", "error");
        return;
      }
      this.add_data.wastages.splice(index, 1);
    },
  },
};
</script>
