<template>
  <website-tabs>
    <div>
      <section class="w-full space-y-6">
        <main class="w-full px-6">
          <Table
            :has_header="true"
            :has_check="false"
            :title="$t('clients')"
            :records="`${pagination.total} ${$t('records')}`"
            :total_pages="pagination.total_pages"
            v-model="pagination.page"
          >
            <template #actions>
              <div>
                <button
                  class="py-1.5 px-3 rounded-sm text-sm flex items-center gap-2 text-gray-600 font-medium rtl:font-semibold"
                  @click.prevent="add_active = !add_active"
                >
                  <i class="fa-solid fa-plus"></i>
                  <span>{{ $t("add_new.client") }}</span>
                </button>
              </div>
            </template>
            <template #filter>
              <ul class="flex items-center">
                <li v-for="(item, index) in status" :key="index">
                  <button
                    class="py-4 px-6 text-center font-medium rtl:font-semibold text-sm capitalize"
                    @click.prevent="
                      changeFilterStatus(item === 'all' ? null : item, index)
                    "
                    :class="{
                      'active-tab':
                        pagination.is_active === (item === 'all' ? null : item),
                    }"
                    :disabled="disables[index]"
                  >
                    <spinner
                      size="w-4 h-4 inline-block"
                      class="mr-2 rtl:mr-0 rtl:ml-2"
                      v-if="disables[index]"
                    />
                    {{ $t(item.replace(/\s/g, "_")) }}
                  </button>
                </li>
              </ul>
            </template>
            <template #head>
              <th>{{ $t("image") }}</th>
              <th>{{ $t("name") }}</th>

              <th>
                <div class="sort">
                  <div>
                    <p>{{ $t("active") }}</p>
                  </div>
                  <div>
                    <button
                      class="sort-btn"
                      @click.prevent="sortTable('is_active')"
                    ></button>
                  </div>
                </div>
              </th>

              <th>
                <div class="sort">
                  <div>
                    <p>{{ $t("updated_at") }}</p>
                  </div>
                  <div>
                    <button
                      class="sort-btn"
                      @click.prevent="sortTable('updated_at')"
                    ></button>
                  </div>
                </div>
              </th>
              <th></th>
            </template>
            <template #tbody v-if="!load">
              <TableLoad :rows="5" v-for="i in 6" :key="i" />
            </template>
            <template #tbody v-else-if="load && items.length !== 0">
              <tr v-for="(item, index) in items" :key="index">
                <td>
                  <div class="w-10 h-10">
                    <img
                      :src="
                        item.image || require('@/assets/images/placeholder.png')
                      "
                      @error="
                        $event.target.src = require('@/assets/images/placeholder.png')
                      "
                      alt="item image"
                      class="w-full h-full rounded-md border object-cover border-gray-100"
                    />
                  </div>
                </td>
                <td>{{ item["name_" + $i18n.locale] || "-" }}</td>

                <td>
                  <p
                    class="status capitalize"
                    :class="item_status[item.is_active]"
                  >
                    {{ item.is_active === 1 ? $t("active") : $t("not_active") }}
                  </p>
                </td>
                <td
                  v-html="createTime(item.updated_at || item.created_at)"
                ></td>

                <td>
                  <div class="actions-group">
                    <div>
                      <button
                        class="w-8 h-8 bg-sky-500/10 text-sky-500 rounded-sm"
                        @click.prevent="changeStatus(item.id)"
                        :content="$t('change_status')"
                        v-tippy
                        :disabled="disables[`status_${item.id}`]"
                      >
                        <spinner
                          size="w-4 h-4 inline-block"
                          v-if="disables[`status_${item.id}`]"
                        />
                        <i class="fa-solid fa-right-left" v-else></i>
                      </button>
                    </div>
                    <div>
                      <button
                        class="w-8 h-8 bg-sky-500 text-white rounded-sm"
                        @click.prevent="showUpdate(item.id)"
                        :content="$t('update')"
                        v-tippy
                      >
                        <i class="fa-solid fa-pen"></i>
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            </template>
            <template #tbody v-else-if="load && items.length === 0">
              <tr>
                <td colspan="5">
                  <div class="table mx-auto my-4">
                    <button
                      class="py-1.5 px-3 rounded-sm text-sm flex items-center gap-2 text-gray-600 ring-1 ring-gray-300 font-medium rtl:font-semibold"
                      @click.prevent="add_active = !add_active"
                    >
                      <i class="fa-solid fa-plus"></i>
                      <span>{{ $t("add_new.client") }}</span>
                    </button>
                  </div>
                  <p
                    class="text-sm text-gray-600 font-medium rtl:font-semibold text-center"
                  >
                    {{ $t("no_data") }}
                  </p>
                </td>
              </tr>
            </template>
          </Table>
        </main>
      </section>
      <transition name="scale">
        <Modal
          :title="$t('add_new.client')"
          v-if="add_active"
          @close="add_active = $event"
        >
          <template>
            <form class="p-6 space-y-4">
              <figure class="space-y-4">
                <slide-transition>
                  <figcaption v-if="add_data.image" class="relative table">
                    <img
                      :src="
                        add_data.image
                          ? convertImage(add_data.image)
                          : require('@/assets/images/placeholder.png')
                      "
                      @error="
                        (e) =>
                          (e.target.src = require('@/assets/images/placeholder.png'))
                      "
                      alt="category image"
                      class="w-auto max-h-[7rem] max-w-[5rem] object-contain"
                    />
                    <button
                      class="w-6 h-6 rounded-full flex items-center justify-center bg-red-500 text-white absolute top-0 right-0 rtl:right-auto rtl:left-0 ring-2 ring-white"
                      type="button"
                      @click.prevent="() => clearImage('add_data')"
                    >
                      <i class="fa-solid fa-xmark fa-sm"></i>
                    </button>
                  </figcaption>
                </slide-transition>
                <blockquote class="space-y-2">
                  <label class="block">
                    <span class="sr-only">Choose image</span>
                    <input
                      type="file"
                      accept="image/*"
                      class="custom-file"
                      ref="uploader"
                      @change="
                        (e) => $set(add_data, 'image', e.target.files[0])
                      "
                    />
                  </label>
                  <p class="error" v-if="add_errors.image">
                    {{ add_errors.image.join(" ") }}
                  </p>
                </blockquote>
              </figure>
              <div class="form-group">
                <div class="with-label">
                  <input
                    type="text"
                    name="name_en"
                    id="name_en"
                    placeholder="Name"
                    required
                    autocomplete="off"
                    v-model="add_data.name_en"
                    v-ltr
                  />
                  <label for="name_en"
                    >{{ $t("name") }} | {{ $t("langs.en") }}</label
                  >
                </div>
                <p
                  class="text-red-500 font-medium text-xs mt-2"
                  v-if="add_errors.name_en"
                >
                  {{ add_errors.name_en.join(" ") }}
                </p>
              </div>
              <div class="form-group">
                <div class="with-label">
                  <input
                    type="text"
                    name="name_ar"
                    id="name_ar"
                    placeholder="الاسم"
                    required
                    autocomplete="off"
                    v-model="add_data.name_ar"
                    v-rtl
                  />
                  <label for="name"
                    >{{ $t("name") }} | {{ $t("langs.ar") }}</label
                  >
                </div>
                <p
                  class="text-red-500 font-medium text-xs mt-2"
                  v-if="add_errors.name_ar"
                >
                  {{ add_errors.name_ar.join(" ") }}
                </p>
              </div>
<div class="form-group">
                <div class="with-label">
                  <textarea
                    name="desc_en"
                    id="desc_en"
                    required
                    autocomplete="off"
                    v-model="add_data.desc_en"
                    v-ltr
                  ></textarea>
                  <label for="desc_en"
                    >{{ $t("desc") }} | {{ $t("langs.en") }}</label
                  >
                </div>
                <p
                  class="text-red-500 font-medium text-xs mt-2"
                  v-if="add_errors.desc_en"
                >
                  {{ add_errors.desc_en.join(" ") }}
                </p>
              </div>
              <div class="form-group">
                <div class="with-label">
                  <textarea
                    name="desc_ar"
                    id="desc_ar"
                    required
                    autocomplete="off"
                    v-model="add_data.desc_ar"
                    v-rtl
                  ></textarea>
                  <label for="desc_ar"
                    >{{ $t("desc") }} | {{ $t("langs.ar") }}</label
                  >
                </div>
                <p
                  class="text-red-500 font-medium text-xs mt-2"
                  v-if="add_errors.desc_ar"
                >
                  {{ add_errors.desc_ar.join(" ") }}
                </p>
              </div>
              <div class="actions-group">
                <div>
                  <button
                    class="py-2.5 px-6 rounded-md text-sm font-medium rtl:font-semibold text-white bg-primary table"
                    @click.prevent="addItem"
                    :disabled="add_disabled"
                  >
                    <spinner size="w-4 h-4 inline-block" v-if="add_disabled" />
                    {{ $t("add") }}
                  </button>
                </div>
              </div>
            </form>
          </template>
        </Modal>
      </transition>
      <transition name="scale">
        <Modal
          :title="$t('update')"
          v-if="edit_active"
          @close="edit_active = $event"
        >
          <template>
            <form class="p-6 space-y-4">
              <figure class="space-y-4">
                <slide-transition>
                  <figcaption v-if="edit_data.image" class="relative table">
                    <img
                      :src="
                        edit_data.image
                          ? typeof edit_data.image === 'object'
                            ? convertImage(edit_data.image)
                            : edit_data.image
                          : require('@/assets/images/placeholder.png')
                      "
                      @error="
                        (e) =>
                          (e.target.src = require('@/assets/images/placeholder.png'))
                      "
                      alt="category image"
                      class="w-auto max-h-[7rem] max-w-[5rem] object-contain"
                    />
                    <button
                      class="w-6 h-6 rounded-full flex items-center justify-center bg-red-500 text-white absolute top-0 right-0 rtl:right-auto rtl:left-0 ring-2 ring-white"
                      type="button"
                      @click.prevent="() => clearImage('edit_data')"
                    >
                      <i class="fa-solid fa-xmark fa-sm"></i>
                    </button>
                  </figcaption>
                </slide-transition>
                <blockquote class="space-y-2">
                  <label class="block">
                    <span class="sr-only">Choose image</span>
                    <input
                      type="file"
                      accept="image/*"
                      class="custom-file"
                      ref="uploader"
                      @change="
                        (e) => $set(edit_data, 'image', e.target.files[0])
                      "
                    />
                  </label>
                  <p class="error" v-if="edit_errors.image">
                    {{ edit_errors.image.join(" ") }}
                  </p>
                </blockquote>
              </figure>
              <div class="form-group">
                <div class="with-label">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Name"
                    required
                    autocomplete="off"
                    v-model="edit_data.name_en"
                    v-ltr
                  />
                  <label for="name_en"
                    >{{ $t("name") }} | {{ $t("langs.en") }}</label
                  >
                </div>
                <p
                  class="text-red-500 font-medium text-xs mt-2"
                  v-if="edit_errors.name_en"
                >
                  {{ edit_errors.name_en.join(" ") }}
                </p>
              </div>
              <div class="form-group">
                <div class="with-label">
                  <input
                    type="text"
                    name="name_ar"
                    id="name_ar"
                    placeholder="الاسم"
                    required
                    autocomplete="off"
                    v-model="edit_data.name_ar"
                    v-rtl
                  />
                  <label for="name_ar"
                    >{{ $t("name") }} | {{ $t("langs.ar") }}</label
                  >
                </div>
                <p
                  class="text-red-500 font-medium text-xs mt-2"
                  v-if="edit_errors.name_ar"
                >
                  {{ edit_errors.name_ar.join(" ") }}
                </p>
              </div>
              <div class="form-group">
                <div class="with-label">
                  <textarea
                    name="desc_en"
                    id="desc_en"
                    required
                    autocomplete="off"
                    v-model="edit_data.desc_en"
                    v-ltr
                  ></textarea>
                  <label for="desc_en"
                    >{{ $t("desc") }} | {{ $t("langs.en") }}</label
                  >
                </div>
                <p
                  class="text-red-500 font-medium text-xs mt-2"
                  v-if="edit_errors.desc_en"
                >
                  {{ edit_errors.desc_en.join(" ") }}
                </p>
              </div>
              <div class="form-group">
                <div class="with-label">
                  <textarea
                    name="desc_ar"
                    id="desc_ar"
                    required
                    autocomplete="off"
                    v-model="edit_data.desc_ar"
                    v-rtl
                  ></textarea>
                  <label for="desc_ar"
                    >{{ $t("desc") }} | {{ $t("langs.ar") }}</label
                  >
                </div>
                <p
                  class="text-red-500 font-medium text-xs mt-2"
                  v-if="edit_errors.desc_ar"
                >
                  {{ edit_errors.desc_ar.join(" ") }}
                </p>
              </div>

              <div class="actions-group">
                <div>
                  <button
                    class="py-2.5 px-6 rounded-md text-sm font-medium rtl:font-semibold text-white bg-primary table"
                    @click.prevent="editItem"
                    :disabled="edit_disabled"
                  >
                    <spinner size="w-4 h-4 inline-block" v-if="edit_disabled" />
                    {{ $t("save_changes") }}
                  </button>
                </div>
              </div>
            </form>
          </template>
        </Modal>
      </transition>
    </div>
  </website-tabs>
</template>
<script>
import Table from "@/components/basics/Table.vue";
import TableLoad from "@/components/basics/TableLoad.vue";
import Modal from "@/components/basics/Modal.vue";
import { mapGetters, mapMutations } from "vuex";
import WebsiteTabs from "@/components/WebsiteTabs.vue";

export default {
  name: "OurClients",
  data() {
    return {
      load: false,
      items: [],
      add_data: {},
      add_active: false,
      add_errors: {},
      add_disabled: false,
      edit_data: {},
      edit_errors: {},
      edit_disabled: false,
      edit_active: false,
      pagination: {
        page: 1,
        total_pages: 0,
        search_key: null,
        sort_key: null,
        sort_type: "asc",
        is_active: null,
        total: 0,
      },
      item_status: {
        1: "bg-sky-500/10 text-sky-500",
        0: "bg-red-500/10 text-red-500",
      },

      apply_disabled: false,
      reset_disabled: false,
      status: ["all", "active", "not active"],
      disables: {},
    };
  },
  watch: {
    "pagination.page"() {
      this.getItems();
    },
    edit_active() {
      if (!this.edit_active) {
        this.edit_data = {};
        this.edit_errors = {};
        this.edit_disabled = false;
      }
    },
    add_active() {
      if (!this.add_active) {
        this.add_data = {};
        this.add_errors = {};
        this.add_disabled = false;
      }
    },
    is_called(val) {
      if (val) this.getItems();
    },
  },
  computed: {
    ...mapGetters("table", ["search", "is_called"]),
  },
  created() {
    this.getItems();
  },
  methods: {
    ...mapMutations("table", ["changeIsCalled"]),
    getItems() {
      const { page, search_key, sort_key, sort_type, is_active } =
        this.pagination;
      return this.$store
        .dispatch("getting/getRoute", {
          name: "w_clients",
          options: {
            page,
            search_key: this.search,
            sort_key,
            sort_type,
            is_active: is_active ? (is_active === "active" ? 1 : 0) : is_active,
          },
        })
        .then(({ result }) => {
          const { data, pagination } = result.w_clients;
          this.items = data;
          this.$set(this.pagination, "total", pagination.total);
          this.$set(this.pagination, "total_pages", pagination.total_pages);
        })
        .catch((err) => {
          const res = err?.response;
          if (!res) {
            this.createAlert(
              "There is something went wrong, please try again later.",
              "error"
            );
            return;
          }
          this.createAlert(res?.data?.message, "error");
        })
        .finally(() => {
          this.load = true;
          this.changeIsCalled(false);
        });
    },
    async addItem() {
      try {
        this.add_disabled = true;
        this.add_errors = {};
        let fd = new FormData();

        for (const key in this.add_data) {
          const item = this.add_data[key];
          if (key === "image") {
            if (item) {
              fd.append(key, item, item?.name);
            }
          } else {
            fd.append(key, item);
          }
        }

        const { data } = await this.axios.post("w_clients/add", fd);
        this.getItems();
        this.createAlert(data.message);
        this.add_active = false;
        this.add_data = {};
      } catch (err) {
        const res = err?.response;
        if (!res) {
          this.add_error =
            "There is something went wrong, please try again later.";
          return;
        }
        this.add_errors = res?.data?.message;
      } finally {
        this.add_disabled = false;
      }
    },
    async editItem() {
      try {
        this.edit_disabled = true;
        this.edit_errors = {};
        let fd = new FormData();

        for (const key in this.edit_data) {
          const item = this.edit_data[key];
          if (key === "image") {
            if (item instanceof File) {
              fd.append(key, item, item?.name);
            } else {
              fd.delete(key);
            }
          } else {
            fd.append(key, item);
          }
        }

        const { data } = await this.axios.post("w_clients/update", fd);
        const { message, result } = data;
        const finalResult = result.client ?? {};
        this.getItems();
        this.createAlert(message);
        // this.edit_active = false
        this.edit_data = {
          ...finalResult,
        };
      } catch (err) {
        const res = err?.response;
        if (!res) {
          this.edit_error =
            "There is something went wrong, please try again later.";
          return;
        }
        this.edit_errors = res?.data?.message;
      } finally {
        this.edit_disabled = false;
      }
    },
    async changeStatus(id) {
      this.$set(this.disables, `status_${id}`, true);
      try {
        const { data } = await this.axios.post(
          `w_clients/update_is_active/${id}`
        );
        this.createAlert(data.message);
        this.getItems();
      } catch (err) {
        const res = err?.response;
        if (!res) {
          this.createAlert(
            "There is something went wrong, please try again later.",
            "error"
          );
          return;
        }
        this.add_errors = res?.data?.message;
        this.createAlert(res?.data?.message, "error");
      } finally {
        this.disables = {};
      }
    },
    async deleteItem(id) {
      this.$set(this.disables, `delete_${id}`, true);
      try {
        const { isConfirmed } = await this.deleteAlert();
        if (!isConfirmed) return;
        const { data } = await this.axios.post(`w_clients/delete/${id}`);
        this.createAlert(data.message);
        this.getItems();
      } catch (err) {
        const res = err?.response;
        if (!res) {
          this.createAlert(
            "There is something went wrong, please try again later.",
            "error"
          );
          return;
        }
        this.add_errors = res?.data?.message;
        this.createAlert(res?.data?.message, "error");
      } finally {
        this.disables = {};
      }
    },
    showUpdate(id) {
      const item = this.items.find((e) => e.id === id);
      if (!item) {
        this.createAlert("Item does not existed", "info");
        return;
      }
      this.edit_data = {
        ...item,
      };
      this.edit_active = true;
    },
    sortTable(key) {
      if (this.pagination.sort_key != key) {
        this.pagination.sort_type = "asc";
      } else {
        this.pagination.sort_type =
          this.pagination.sort_type == "asc" ? "desc" : "asc";
      }
      this.pagination.sort_key = key;
      this.getItems().finally(() => {
        this.createAlert(`Table sorted by ${key}`);
      });
    },
    applyFilter() {
      this.apply_disabled = true;
      this.getItems().finally(() => {
        this.apply_disabled = false;
      });
    },
    resetFilter() {
      this.reset_disabled = true;
      Object.keys(this.pagination)
        .filter((e) => ["status", "search_key"].includes(e))
        .forEach((key) => this.$set(this.pagination, key, null));
      this.getItems().finally(() => {
        this.reset_disabled = false;
      });
    },
    changeFilterStatus(status, index) {
      this.$set(this.disables, index, true);
      this.$set(this.pagination, "is_active", status);
      this.getItems().finally(() => {
        this.disables = {};
      });
    },
    clearImage(type) {
      const uploader = this.$refs.uploader;
      if (!uploader) return;
      uploader.value = null;
      this.$set(this[type], "image", null);
    },
  },
  components: { Table, Modal, TableLoad, WebsiteTabs },
};
</script>
