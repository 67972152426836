<template>
  <div class="p-6 space-y-6">
    <cards-container :cards="cards" :load="!load"></cards-container>
    <Table
      title="Refunds"
      :records="`${pagination.total || 0} records`"
      :pagination="pagination"
      :total_pages="pagination.total_pages"
      v-model="pagination.page"
    >
      <!-- <template #filter>
        <ul class="flex items-center">
          <li v-for="(item, index) in status" :key="index">
            <button
              class="py-4 px-6 text-center font-medium rtl:font-semibold text-sm capitalize"
              @click.prevent="
                changeFilterStatus(item === 'all' ? null : item, index)
              "
              :class="{
                'active-tab':
                  pagination.is_active === (item === 'all' ? null : item),
              }"
              :disabled="disables[index]"
            >
              <spinner
                size="w-4 h-4 inline-block"
                class="mr-2 rtl:mr-0 rtl:ml-2"
                v-if="disables[index]"
              />
              {{ item }}
            </button>
          </li>
        </ul>
      </template> -->
      <template #head>
        <th>Customer name</th>
        <th>Customer mobile</th>
        <th>Products</th>
        <th>Total</th>
        <th>Status</th>
        <th>Payment option</th>
        <th>Latest update</th>
        <th>Actions</th>
      </template>
      <template #tbody v-if="!load">
        <TableLoad :rows="8" v-for="i in 4" :key="i" />
      </template>
      <template #tbody v-else-if="load && items.length">
        <tr v-for="(item, index) in items" :key="index">
          <td>{{ item.customer_name }}</td>
          <td>
            <a
              :href="`tel:${item.customer_mobile}`"
              class="text-sky-600 text-sm"
              >{{ item.customer_mobile }}</a
            >
          </td>
          <td>{{ item.products.length }}</td>
          <td>{{ item.total }}</td>
          <td>{{ item.status }}</td>
          <td>{{ item.payment_option }}</td>

          <td v-html="createTime(item.updated_at)"></td>

          <td></td>
        </tr>
      </template>

      <template #tbody v-else>
        <tr>
          <td colspan="8" class="!text-center">There are no data display</td>
        </tr>
      </template>
    </Table>
  </div>
</template>

<script>
import CardsContainer from "@/components/basics/CardsContainer.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  name: "refunds",
  components: {
    CardsContainer,
  },
  data() {
    return {
      items: [],
      load: false,
      cards: [
        {
          title: "Customers",
          key: "customers_count",
          icon: "fa-solid fa-user-group",
          value: 0,
          style: {
            icon: "text-sky-600",
            parent: "bg-sky-600/20",
          },
        },
        {
          title: "Products",
          key: "products_count",
          icon: "fa-solid fa-shopping-bag",
          value: 0,
          style: {
            icon: "text-orange-500",
            parent: "bg-orange-500/20",
          },
        },
        {
          title: "Total",
          key: "total_count",
          icon: "fa-solid fa-dollar-sign",
          value: 0,
          style: {
            icon: "text-teal-500",
            parent: "bg-teal-500/20",
          },
        },
      ],
      pagination: {
        page: 1,
        total: 0,
        total_pages: 0,
      },
    };
  },
  async created() {
    Promise.all([await this.getItems()]).finally(() => (this.load = true));
  },
  watch: {
    is_called(val, _) {
      if (val) {
        this.getItems();
      }
    },
  },
  computed: {
    ...mapGetters("table", ["search", "is_called"]),
  },
  methods: {
    ...mapActions("getting", ["getRoute"]),
    ...mapMutations("table", ["changeIsCalled"]),
    async getItems() {
      try {
        const { total, total_pages, ...others } = this.pagination;
        const { result } = await this.getRoute({
          name: "refunds",
          options: {
            ...others,
            search_key: this.search,
          },
        });
        const { refunds, pagination } = result;
        this.items = refunds.data;
        this.$set(this.pagination, "total", pagination?.total);
        this.$set(this.pagination, "total_pages", pagination?.total_pages);
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      } finally {
        this.changeIsCalled(false);
      }
    },
  },
};
</script>
