<template>
  <div class="p-6">
    <Table
      title="Roles"
      :records="`${pagination.total || 0} records`"
      :pagination="pagination"
      :total_pages="pagination.total_pages"
      v-model="pagination.page"
    >
      <template #actions>
        <div v-if="hasPermission('add roles')">
          <button
            class="py-1.5 px-3 text-sm flex items-center gap-2 text-gray-600 font-medium rtl:font-semibold"
            @click.prevent="add_active = !add_active"
          >
            <i class="fa-solid fa-plus"></i>
            <span>New role</span>
          </button>
        </div>
      </template>
      <!-- <template #filter>
        <ul class="flex items-center">
          <li v-for="(item, index) in status" :key="index">
            <button
              class="py-4 px-6 text-center font-medium rtl:font-semibold text-sm capitalize"
              @click.prevent="
                changeFilterStatus(item === 'all' ? null : item, index)
              "
              :class="{
                'active-tab':
                  pagination.is_active === (item === 'all' ? null : item),
              }"
              :disabled="disables[index]"
            >
              <spinner
                size="w-4 h-4 inline-block"
                class="mr-2 rtl:mr-0 rtl:ml-2"
                v-if="disables[index]"
              />
              {{ item }}
            </button>
          </li>
        </ul>
      </template> -->
      <template #head>
        <th>Name</th>
        <!-- <th>Status</th> -->
        <th>Latest update</th>
        <th>Actions</th>
      </template>
      <template #tbody v-if="!load">
        <TableLoad :rows="3" v-for="i in 4" :key="i" />
      </template>
      <template #tbody v-else-if="load && items.length">
        <tr v-for="(item, index) in items" :key="index">
          <td>
            {{ item.name }}
          </td>

          <td v-html="createTime(item.updated_at || item.created_at)"></td>

          <td>
            <div class="actions-group">
              <!-- <div v-if="hasPermission('update roles')">
                <button
                  class="w-8 h-8 bg-sky-500/10 text-sky-500 rounded"
                  @click.prevent="changeStatus(item.id)"
                  :content="'Change status'"
                  v-tippy
                  :disabled="disables[`status_${item.id}`]"
                >
                  <spinner
                    size="w-4 h-4 inline-block"
                    v-if="disables[`status_${item.id}`]"
                  />
                  <i class="fa-solid fa-right-left" v-else></i>
                </button>
              </div> -->
              <div v-if="hasPermission('edit roles')">
                <button
                  class="w-8 h-8 bg-sky-500 text-white rounded"
                  @click.prevent="showUpdate(item)"
                  :content="'Update'"
                  v-tippy
                >
                  <i class="fa-solid fa-pen"></i>
                </button>
              </div>
              <div v-if="hasPermission('delete roles')">
                <button
                  class="p-2 px-3 text-red-500 font-medium rtl:font-semibold"
                  @click.prevent="deleteItem(item.id)"
                  :disabled="disables[`delete_${item.id}`]"
                >
                  <spinner
                    size="w-4 h-4 inline-block"
                    v-if="disables[`delete_${item.id}`]"
                  />
                  <span v-else> Delete </span>
                </button>
              </div>
            </div>
          </td>
        </tr>
      </template>

      <template #tbody v-else>
        <tr>
          <td colspan="3" class="!text-center">There are no data display</td>
        </tr>
      </template>
    </Table>
    <Modal
      title="New Role"
      v-if="add_active"
      @close="add_active = $event"
      size="!max-w-screen-md"
    >
      <template>
        <form class="space-y-4 p-5">
          <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div class="form-group space-y-3">
              <label for="name"> Name | EN </label>
              <input
                type="text"
                name="name"
                id="name"
                v-model="add_data.name"
                v-ltr
              />
              <p class="error">
                {{ (add_errors["name"] || []).join(" ") }}
              </p>
            </div>
            <div class="form-group space-y-3">
              <label for="alt_name">Name | AR</label>
              <input
                type="text"
                name="alt_name"
                id="alt_name"
                v-model="add_data.alt_name"
                v-rtl
              />
              <p class="error">
                {{ (add_errors["alt_name"] || []).join(" ") }}
              </p>
            </div>
          </div>
          <!-- <p class="text-sm text-gray-600 font-medium">Permissions</p> -->
          <div class="overflow-x-auto grid">
            <table class="styled-table">
              <thead>
                <tr>
                  <th></th>
                  <th v-for="(item, index) in access" :key="index">
                    {{ item }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(permission, index) in permissions" :key="index">
                  <td>{{ permission.name }}</td>

                  <td v-for="(_access, idx) in access" :key="idx">
                    <div
                      class="checkbox"
                      v-if="permission.access.includes(_access)"
                    >
                      <input
                        type="checkbox"
                        :value="`${_access} ${permission.key}`"
                        :data-key="permission.key"
                        :data-access-key="_access"
                        @change="(e) => handlerChange(e, 'add_data')"
                        v-model="add_data.permissions"
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p class="error">
            {{ (add_errors["permissions"] || []).join(" ") }}
          </p>
          <div>
            <button-with-loader
              :disabled="add_disabled"
              btnClass="bg-primary py-2 px-4 rounded text-xs font-medium rtl:font-semibold text-white"
              @click="addItem"
            >
              Submit
            </button-with-loader>
          </div>
        </form>
      </template>
    </Modal>
    <Modal
      title="Update Role"
      v-if="edit_active"
      @close="edit_active = $event"
      size="!max-w-screen-md"
    >
      <template>
        <form class="space-y-4 p-5">
          <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div class="form-group space-y-3">
              <label for="name"> Name | EN </label>
              <input
                type="text"
                name="name"
                id="name"
                v-model="edit_data.name"
                v-ltr
              />
              <p class="error">
                {{ (edit_errors["name"] || []).join(" ") }}
              </p>
            </div>
            <div class="form-group space-y-3">
              <label for="alt_name">Name | AR</label>
              <input
                type="text"
                name="alt_name"
                id="alt_name"
                v-model="edit_data.alt_name"
                v-rtl
              />
              <p class="error">
                {{ (edit_errors["alt_name"] || []).join(" ") }}
              </p>
            </div>
          </div>
          <!-- <p class="text-sm text-gray-600 font-medium">Permissions</p> -->
          <div class="overflow-x-auto grid">
            <table class="styled-table">
              <thead>
                <tr>
                  <th></th>
                  <th v-for="(item, index) in access" :key="index">
                    {{ item }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(permission, index) in permissions" :key="index">
                  <td>{{ permission.name }}</td>

                  <td v-for="(_access, idx) in access" :key="idx">
                    <div
                      class="checkbox"
                      v-if="permission.access.includes(_access)"
                    >
                      <input
                        type="checkbox"
                        :value="`${_access} ${permission.key}`"
                        :data-key="permission.key"
                        :data-access-key="_access"
                        @change="(e) => handlerChange(e, 'edit_data')"
                        v-model="edit_data.permissions"
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p class="error">
            {{ (edit_errors["permissions"] || []).join(" ") }}
          </p>
          <div>
            <button-with-loader
              :disabled="edit_disabled"
              btnClass="bg-primary py-2 px-4 rounded text-xs font-medium rtl:font-semibold text-white"
              @click="editItem"
            >
              Save changes
            </button-with-loader>
          </div>
        </form>
      </template>
    </Modal>
  </div>
</template>

<script>
import Modal from "@/components/basics/Modal.vue";
import ButtonWithLoader from "@/components/basics/ButtonWithLoader.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import Table from "@/components/basics/Table.vue";
export default {
  components: { Modal, ButtonWithLoader, Table },
  name: "Roles",
  data() {
    return {
      add_data: {
        permissions: [],
      },
      add_errors: {},
      add_error: null,
      add_disabled: false,
      add_active: false,
      edit_data: {
        permissions: [],
      },
      edit_errors: {},
      edit_error: null,
      edit_disabled: false,
      edit_active: false,
      access: ["show", "add", "edit", "update", "delete"],
      permissions: [
        // {
        //   name: "Dashboard",
        //   key: "dashboard",
        //   access: ["show"],
        // },
        {
          name: "Stores",
          key: "stores",
          access: ["show"],
        },
        // {
        //   name: "Suppliers",
        //   key: "suppliers",
        //   access: ["show", "add", "edit", "update", "delete"],
        // },
        // {
        //   name: "Warehouses",
        //   key: "warehouses",
        //   access: ["show", "add", "edit", "update", "delete"],
        // },
        {
          name: "Devices",
          key: "devices",
          access: ["show", "add", "edit", "update", "delete"],
        },
        {
          name: "Subscriptions",
          key: "subscriptions",
          access: ["show", "add", "edit", "update", "delete"],
        },

        {
          name: "Packages",
          key: "packages",
          access: ["show", "add", "edit", "update", "delete"],
        },

        {
          name: "POS devices",
          key: "pos_devices",
          access: ["show", "add", "edit", "update", "delete"],
        },
        {
          name: "Subscription invoices",
          key: "invoices",
          access: ["show"],
        },
        {
          name: "Notifications",
          key: "notifications",
          access: ["show", "add", "edit", "update", "delete"],
        },
        {
          name: "Documents",
          key: "documents",
          access: ["show", "add", "edit", "update", "delete"],
        },

        {
          name: "Modules",
          key: "modules",
          access: ["show", "add", "edit", "update", "delete"],
        },
        {
          name: "Categories group",
          key: "default_category_groups",
          access: ["show", "add", "edit", "update", "delete"],
        },
        {
          name: "Categories",
          key: "default_categories",
          access: ["show", "add", "edit", "update", "delete"],
        },
        {
          name: "Sub categories",
          key: "default_sub_categories",
          access: ["show", "add", "edit", "update", "delete"],
        },
        // {
        //   name: "Meat types",
        //   key: "meat_types",
        //   access: ["show", "add", "edit", "update", "delete"],
        // },
        {
          name: "Wastages",
          key: "wastages",
          access: ["show", "add", "edit", "update", "delete"],
        },
        {
          name: "Default products",
          key: "default_products",
          access: ["show", "add", "edit", "update", "delete"],
        },
        {
          name: "Default inventory",
          key: "default_inventory",
          access: ["show", "add", "edit", "update", "delete"],
        },
        // {
        //   name: "Default suppliers",
        //   key: "default_suppliers",
        //   access: ["show", "add", "edit", "update", "delete"],
        // },
        {
          name: "Users",
          key: "users",
          access: ["show", "add", "edit", "update", "delete"],
        },

        {
          name: "Admins",
          key: "admins",
          access: ["show", "add", "edit", "update", "delete"],
        },

        {
          name: "POS cashiers",
          key: "pos_cashiers",
          access: ["show"],
        },
        {
          name: "Cashiers",
          key: "cashiers",
          access: ["show", "add", "edit", "update", "delete"],
        },

        {
          name: "Support",
          key: "support",
          access: ["show", "add"],
        },

        {
          name: "Settings",
          key: "settings",
          access: ["show", "add", "edit", "update", "delete"],
        },

        // {
        //   name: "Regions",
        //   key: "regions",
        //   access: ["show", "add", "edit", "update", "delete"],
        // },

        // {
        //   name: "Cities",
        //   key: "cities",
        //   access: ["show", "add", "edit", "update", "delete"],
        // },

        // {
        //   name: "Districts",
        //   key: "districts",
        //   access: ["show", "add", "edit", "update", "delete"],
        // },
        {
          name: "Website",
          key: "website",
          access: ["show"],
        },
        {
          name: "Apps",
          key: "apps",
          access: ["show"],
        },

        {
          name: "Roles",
          key: "roles",
          access: ["show", "update", "delete"],
        },
      ],
      // item_status: {
      //   1: "bg-sky-500/10 text-sky-500",
      //   0: "bg-red-500/10 text-red-500",
      // },
      // status: ["all", "active", "not active"],
      pagination: {
        is_active: null,
        page: 1,
        sort_key: null,
        sort_type: "asc",
      },
      disables: {},
      items: [],
      load: true,
    };
  },
  created() {
    this.getItems();
  },
  watch: {
    "pagination.page"() {
      this.getItems();
    },
    is_called(val, _) {
      if (val) {
        this.getItems();
      }
    },
    add_active(val, _) {
      if (!val) {
        // const uploader = this.$refs.uploader;
        this.add_data = { permissions: [] };
        this.add_errors = {};
        // uploader.value = null;
      }
    },
  },
  computed: {
    ...mapGetters("table", ["search", "is_called"]),
  },
  methods: {
    ...mapActions("getting", ["getRoute"]),
    ...mapMutations("table", ["changeIsCalled"]),
    async getItems() {
      try {
        const { total, total_pages, ...others } = this.pagination;

        const { result } = await this.getRoute({
          name: "roles",
          options: {
            ...others,
            is_active: others.is_active
              ? others.is_active === "active"
                ? 1
                : 0
              : null,
            search_key: this.search,
          },
        });
        const { data, pagination } = result.roles;
        this.items = data;
        this.$set(this.pagination, "total", pagination.total);
        this.$set(this.pagination, "total_pages", pagination.total_pages);
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      } finally {
        this.load = true;
        this.changeIsCalled(false);
      }
    },
    async changeStatus(id) {
      this.$set(this.disables, `status_${id}`, true);
      try {
        const { data } = await this.axios.post(`roles/update_is_active/${id}`);
        this.createAlert(data.message);
        this.getItems();
      } catch (err) {
        const res = err?.response;
        if (!res) {
          this.createAlert(
            "There is something went wrong, please try again later.",
            "error"
          );
          return;
        }
        this.add_errors = res?.data?.message;
        this.createAlert(res?.data?.message, "error");
      } finally {
        this.disables = {};
      }
    },
    async deleteItem(id) {
      this.$set(this.disables, `delete_${id}`, true);
      try {
        const { data } = await this.axios.post(`roles/delete/${id}`);
        this.createAlert(data.message);
        this.getItems();
      } catch (err) {
        const res = err?.response;
        if (!res) {
          this.createAlert(
            "There is something went wrong, please try again later.",
            "error"
          );
          return;
        }
        this.add_errors = res?.data?.message;
        this.createAlert(res?.data?.message, "error");
      } finally {
        this.disables = {};
      }
    },
    async addItem() {
      try {
        this.add_disabled = true;
        this.add_errors = {};

        const { data } = await this.axios.post("roles/add", this.add_data);
        this.add_data = {};
        this.add_active = false;
        this.createAlert(data?.message);
        this.getItems();
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.add_errors = responseError;
        } else {
          this.error = responseError;
        }
      } finally {
        this.add_disabled = false;
      }
    },
    async editItem() {
      try {
        this.edit_disabled = true;
        this.edit_errors = {};

        const { data } = await this.axios.post("roles/update", this.edit_data);
        this.edit_data = {};
        this.edit_active = false;
        this.createAlert(data?.message);
        this.getItems();
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.edit_errors = responseError;
        } else {
          this.error = responseError;
        }
      } finally {
        this.edit_disabled = false;
      }
    },
    handlerChange(ev, key) {
      let result;
      const { value, checked, dataset } = ev.target;
      const permissions = this[key].permissions || [];

      if (checked) {
        const hasShow = permissions.some((e) => /show/gi.test(e));
        const { key: _key, accessKey } = dataset;

        if (!hasShow) {
          result = [...permissions, "show " + _key, value];
          this.$set(this[key], "permissions", [...new Set(result)]);
          return;
        }

        result = [...permissions, value];
        this.$set(this[key], "permissions", [...new Set(result)]);
        // this[key] = { ...this[key], permissions: [...new Set(result)] };
      } else {
        this.$set(
          this[key],
          "permissions",
          permissions.filter((e) => e !== value)
        );
      }
    },
    showUpdate(item) {
      this.edit_data = item;
      this.edit_active = true;
      this.edit_errors = {};
    },
  },
};
</script>
