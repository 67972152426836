var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',{staticClass:"w-full p-6 grid grid-cols-1 lg:grid-cols-2 gap-4"},[_c('figure',{staticClass:"w-full ring-1 ring-gray-200 bg-white rounded lg:col-span-2 p-4"},[_c('div',{attrs:{"id":"chart"}},[_c('apexchart',{attrs:{"type":"line","height":"350","options":{
            ..._vm.chartOptions,

            ..._vm.globalOptions,
          },"series":_vm.series}})],1)]),_c('figure',{staticClass:"w-full ring-1 ring-gray-200 bg-white rounded p-4"},[_c('div',{attrs:{"id":"chartBar"}},[_c('apexchart',{attrs:{"type":"bar","height":"350","options":{
            ..._vm.barChart,

            ..._vm.globalOptions,
          },"series":_vm.series}})],1)]),_c('figure',{staticClass:"w-full ring-1 ring-gray-200 bg-white rounded p-4"},[_c('div',{attrs:{"id":"chartBar"}},[_c('apexchart',{attrs:{"type":"radialBar","height":"350","options":{
            ..._vm.radialBarChart,

            ..._vm.globalOptions,
          },"series":[40, 100, 50]}})],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }