<template>
  <section class="w-full p-6 space-y-6">
    <Table
      title="Default Inventory"
      :records="`${pagination.total || 0} records`"
      :pagination="pagination"
      :total_pages="pagination.total_pages"
      v-model="pagination.page"
    >
      <template #actions>
        <div>
          <router-link
            to="/default-inventory/create"
            class="
              py-1.5
              px-3
              text-sm
              flex
              items-center
              gap-2
              text-gray-600
              font-medium
              rtl:font-semibold
            "
          >
            <i class="fa-solid fa-plus"></i>
            <span>New product</span>
          </router-link>
        </div>
      </template>
      <template #filter>
        <ul class="flex items-center">
          <li v-for="(item, index) in status" :key="index">
            <button
              class="
                py-4
                px-6
                text-center
                font-medium
                rtl:font-semibold
                text-sm
                capitalize
              "
              @click.prevent="
                changeFilterStatus(item === 'all' ? null : item, index)
              "
              :class="{
                'active-tab':
                  pagination.is_active === (item === 'all' ? null : item),
              }"
              :disabled="disables[index]"
            >
              <spinner
                size="w-4 h-4 inline-block"
                class="mr-2 rtl:mr-0 rtl:ml-2"
                v-if="disables[index]"
              />
              <!-- {{ $t(item.replace(/\s/g, "_")) }} -->
              {{ item }}
            </button>
          </li>
        </ul>
      </template>
      <template #head>
        <th>Product</th>
        <th>Price (SAR)</th>
        <!-- <th>SKU</th> -->
        <!-- <th>Barcode</th> -->
        <th>Qty | weight</th>
        <th>Supplier</th>
        <th>Category</th>
        <th>Sub categories</th>
        <th>Status</th>
        <th>Latest update</th>
        <th>Actions</th>
      </template>
      <template #tbody v-if="!load">
        <TableLoad :rows="9" v-for="i in 4" :key="i" />
      </template>
      <template #tbody v-else-if="load && items.length">
        <tr v-for="(product, index) in items" :key="index">
          <td>
            <div class="flex items-center gap-3">
              <div>
                <div class="w-10 h-10">
                  <img
                    :src="
                      product.image ||
                      require('@/assets/images/placeholder.png')
                    "
                    @error="
                      $event.target.src = require('@/assets/images/placeholder.png')
                    "
                    alt="product image"
                    class="
                      w-full
                      h-full
                      rounded-md
                      border
                      object-cover
                      border-gray-100
                    "
                  />
                </div>
              </div>
              <span
                class="inline-block line-clamp-2 max-w-sm whitespace-normal"
              >
                {{ product["name_" + $i18n.locale] }}
              </span>
            </div>
          </td>

          <td>{{ product.price }}</td>

          <!-- <td>{{ product.sku || "-" }}</td> -->
          <!-- <td>{{ product.barcode || "-" }}</td> -->
          <td>
            <!-- {{ product.qty }} ({{ GenerateUnit(product.weight || 0) }}) -->
            <span v-if="product.product_type === 'weight'">
              {{ GenerateUnit(product.weight || 0) }}
            </span>
            <span v-else> {{ product.qty || 0 }} Piece </span>
          </td>
          <td>
            <div class="actions-group !flex-wrap min-w-[15rem]">
              <span
                class="status text-gray-600 bg-gray-100"
                v-for="(warehouse, index) in product.warehouses || []"
                :key="index"
                >{{ warehouse["name_" + $i18n.locale] }}</span
              >

              <span
                class="text-gray-600"
                v-if="!(product.warehouses || []).length"
                >-</span
              >
            </div>
          </td>
          <td>
            {{ (product.category || {})["name_" + $i18n.locale] || "-" }}
          </td>
          <td>
            <div class="actions-group !flex-wrap min-w-[15rem]">
              <span
                class="status text-gray-600 bg-gray-100"
                v-for="(sub, index) in product.sub_categories || []"
                :key="index"
                >{{ sub["name_" + $i18n.locale] }}</span
              >

              <span
                class="text-gray-600"
                v-if="!(product.sub_categories || []).length"
                >-</span
              >
            </div>
          </td>
          <td>
            <p
              class="status capitalize"
              :class="item_status[product.is_active]"
            >
              {{ product.is_active === 1 ? "Active" : "Not active" }}
            </p>
          </td>

          <td
            v-html="createTime(product.updated_at || product.created_at)"
          ></td>

          <td
            class="table-drop-item relative"
            :style="`--z: ${indexes[index]}`"
          >
            <t-dropdown>
              <div
                slot="trigger"
                slot-scope="{
                  mousedownHandler,
                  focusHandler,
                  blurHandler,
                  keydownHandler,
                }"
              >
                <button
                  class="w-10 h-10 rounded-full hover:bg-gray-100 text-gray-600"
                  aria-label="Actions"
                  aria-haspopup="true"
                  @mousedown="mousedownHandler"
                  @focus="focusHandler"
                  @blur="blurHandler"
                  @keydown="keydownHandler"
                >
                  <i class="fa-solid fa-ellipsis"></i>
                </button>
              </div>

              <div slot-scope="{ hide }">
                <ul
                  class="
                    list-none
                    ring-1 ring-gray-300
                    divide-y divide-gray-100
                  "
                >
                  <li class="py-2 px-4">
                    <a
                      href="javascript:void(0)"
                      @click.prevent="
                        () => {
                          changeStatus(product.id).finally(() => hide());
                        }
                      "
                      :disabled="disables[`status_${product.id}`]"
                      class="
                        inline-flex
                        gap-4
                        text-gray-600
                        items-center
                        text-sm
                      "
                    >
                      <span class="shrink-0">
                        <spinner
                          size="w-4 h-4 inline-block"
                          v-if="disables[`status_${product.id}`]"
                        />

                        <i class="fa-solid fa-right-left" v-else></i>
                      </span>
                      <p class="font-semibold flex-1">Change status</p>
                    </a>
                  </li>
                  <li class="py-2 px-4">
                    <a
                      href="javascript:void(0)"
                      @click.prevent="
                        () => {
                          deleteItem(product.id).finally(() => hide());
                        }
                      "
                      :disabled="disables[`delete_${product.id}`]"
                      class="
                        inline-flex
                        gap-4
                        text-gray-600
                        items-center
                        text-sm
                      "
                    >
                      <span class="shrink-0">
                        <spinner
                          size="w-4 h-4 inline-block"
                          v-if="disables[`delete_${product.id}`]"
                        />

                        <i class="fa-solid fa-trash" v-else></i>
                      </span>
                      <p class="font-semibold flex-1">Delete</p>
                    </a>
                  </li>
                  <li class="py-2 px-4">
                    <a
                      href="javascript:void(0)"
                      @click.prevent="
                        () => {
                          openWastage(product.id);
                          hide();
                        }
                      "
                      class="
                        inline-flex
                        gap-4
                        text-gray-600
                        items-center
                        text-sm
                      "
                    >
                      <span class="shrink-0">
                        <i class="fa-solid fa-recycle"></i>
                      </span>
                      <p class="font-semibold flex-1">Add wastage</p>
                    </a>
                  </li>
                  <li class="py-2 px-4">
                    <router-link
                      :to="{
                        name: 'UpdateDefaultInventoryProduct',
                        params: {
                          slug: `${product.id}-${
                            product.name_ar ||
                            product.name_en ||
                            `product-${product.created_at}`
                          }`,
                        },
                      }"
                      @click.prevent="hide"
                      class="
                        inline-flex
                        gap-4
                        text-gray-600
                        items-center
                        text-sm
                      "
                    >
                      <i class="fa-solid fa-pen shrink-0"></i>
                      <p class="font-semibold flex-1">Update</p>
                    </router-link>
                  </li>
                </ul>
              </div>
            </t-dropdown>
          </td>
        </tr>
      </template>

      <template #tbody v-else>
        <tr>
          <td colspan="9" class="!text-center">There are no products</td>
        </tr>
      </template>
    </Table>

    <Modal
      title="Add quantities"
      v-if="show_active"
      @close="show_active = $event"
    >
      <template>
        <form class="p-6 space-y-4">
          <div class="form-group space-y-3">
            <label for="value">Value</label>
            <input
              type="number"
              min="1"
              placeholder="1"
              v-model="add_data.value"
              autocomplete="off"
            />
            <p
              class="error"
              v-html="Object.values(add_errors).join('<br />')"
            ></p>
          </div>
          <button-with-loader
            btnClass="w-auto py-2 px-4 rounded bg-primary
          text-white text-xs [margin-inline-start:auto]"
            :disabled="add_disabled"
            @click="addValue"
            >Save changes</button-with-loader
          >
        </form>
      </template>
    </Modal>
    <transition>
      <Modal
        title="Manual wastage"
        v-if="wastage_active"
        @close="wastage_active = $event"
      >
        <template>
          <form class="space-y-4 p-4">
            <div class="form-group space-y-3">
              <label for="wastage_id"> Wastage </label>
              <t-rich-select
                :options="wastages"
                placeholder="Select"
                :text-attribute="'name_' + $i18n.locale"
                value-attribute="id"
                :clear-able="true"
                :closeOnSelect="true"
                v-model="wastage_data.wastage_id"
              />
              <p class="error">
                {{ (wastage_errors["wastage_id"] || []).join(" ") }}
              </p>
            </div>
            <div class="form-group space-y-3">
              <label for="weight"> Weight (KG) </label>
              <input
                type="number"
                name="weight"
                id="weight"
                v-model="wastage_data.value"
                placeholder="1"
                min="1"
              />
              <p class="error">
                {{ (wastage_errors["value"] || []).join(" ") }}
              </p>
            </div>
            <button-with-loader
              btnClass="w-auto py-2 px-4 rounded bg-primary
                text-white text-xs"
              :disabled="wastage_disabled"
              @click="addWastage"
              >Save changes</button-with-loader
            >
          </form>
        </template>
      </Modal>
    </transition>
  </section>
</template>
<script>
import Modal from "@/components/basics/Modal.vue";
import ButtonWithLoader from "@/components/basics/ButtonWithLoader.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  name: "Stock",
  data() {
    return {
      load: false,
      items: [],
      currentPage: 1,
      rows: 0,
      totalPages: 0,
      perPage: 1,
      active: false,
      product_id: null,
      delete_disabled: false,
      status_disabled: [],
      error: "",
      search_key: "",
      search_disabled: false,
      item_status: {
        1: "bg-sky-500/10 text-sky-500",
        0: "bg-red-500/10 text-red-500",
      },
      add_data: {
        is_inventory: 1,
      },
      add_errors: {},
      add_error: null,
      add_disabled: false,
      show_active: false,
      status: ["all", "active", "not active"],
      pagination: {
        is_active: null,
        page: 1,
        sort_key: null,
        sort_type: "asc",
      },
      disables: {},
      indexes: [],
      wastage_data: {},
      wastage_errors: {},
      wastage_active: false,
      wastage_disabled: false,
      wastages: [],
    };
  },
  async created() {
    await Promise.all([this.getItems(), this.getWastages()]);
  },
  watch: {
    "pagination.page"() {
      this.getItems();
    },
    is_called(val, _) {
      if (val) {
        this.getItems();
      }
    },
  },
  computed: {
    ...mapGetters("table", ["search", "is_called"]),
  },
  methods: {
    ...mapActions("getting", ["getRoute"]),
    ...mapMutations("table", ["changeIsCalled"]),
    async getWastages() {
      try {
        const { result } = await this.getRoute("wastages");
        this.wastages = result.wastages;
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },
    async getItems() {
      try {
        const { total, total_pages, ...others } = this.pagination;
        const user = this.$store.state.user;

        const { result } = await this.getRoute({
          name: "products",
          options: {
            ...others,
            is_active: others.is_active
              ? others.is_active === "active"
                ? 1
                : 0
              : null,
            search_key: this.search,
            is_inventory: 1,
            store_id: this.$route.query.location
              ? this.$route.query.location
              : user.user_type === "cashier"
              ? user?.id
              : null,
          },
        });

        const { data, pagination } = result.products;
        this.indexes = Array(data.length)
          .fill()
          .map((_, i) => i + 1)
          .sort(() => -1);
        this.items = data;
        this.$set(this.pagination, "total", pagination.total);
        this.$set(this.pagination, "total_pages", pagination.total_pages);
      } catch (error) {
        console.log(error);
        // this.createAlert(result?.message, type);
      } finally {
        this.load = true;
        this.changeIsCalled(false);
      }
    },
    async addValue() {
      this.add_disabled = true;
      try {
        const { data } = await this.axios.post(
          "products/add_stock",
          this.add_data
        );
        this.createAlert(data.message);
        this.getItems();
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.add_errors = responseError;
        } else {
          this.createAlert(responseError, "error");
        }
      } finally {
        this.add_disabled = false;
      }
    },
    async changeStatus(id) {
      this.$set(this.disables, `status_${id}`, true);
      try {
        const { data } = await this.axios.post(
          `products/update_is_active/${id}`
        );
        this.createAlert(data.message);
        this.getItems();
      } catch (err) {
        const res = err?.response;
        if (!res) {
          this.createAlert(
            "There is something went wrong, please try again later.",
            "error"
          );
          return;
        }
        this.createAlert(res?.data?.message, "error");
      } finally {
        this.disables = {};
      }
    },
    async deleteItem(id) {
      this.$set(this.disables, `delete_${id}`, true);
      try {
        const { data } = await this.axios.post(`products/delete/${id}`);
        this.createAlert(data.message);
        this.getItems();
      } catch (err) {
        const res = err?.response;
        if (!res) {
          this.createAlert(
            "There is something went wrong, please try again later.",
            "error"
          );
          return;
        }
        this.createAlert(res?.data?.message, "error");
      } finally {
        this.disables = {};
      }
    },
    sortTable(key) {
      if (this.pagination.sort_key != key) {
        this.pagination.sort_type = "asc";
      } else {
        this.pagination.sort_type =
          this.pagination.sort_type == "asc" ? "desc" : "asc";
      }
      this.pagination.sort_key = key;
      this.getItems().finally(() => {
        this.createAlert(`Table sorted by ${key}`);
      });
    },
    addValues(id) {
      this.add_data = {
        product_id: id,
        value: null,
      };
      this.show_active = true;
      this.add_errors = {};
      this.add_error = null;
      this.add_disabled = false;
    },
    async addWastage() {
      this.wastage_disabled = true;
      this.wastage_errors = {};

      try {
        const { data } = await this.axios.post("products/add_wastage", {
          ...this.wastage_data,
          product_id: this.productId,
        });
        this.getItems();
        this.wastage_data = {};
        this.wastage_active = false;
        this.createAlert(data.message);
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.wastage_errors = responseError;
        } else {
          this.createAlert(responseError, "error");
        }
      } finally {
        this.wastage_disabled = false;

        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }
    },
    openWastage(id) {
      this.wastage_data = {
        product_id: id,
      };
      this.wastage_errors = {};
      this.wastage_active = true;
    },

    changeFilterStatus(status, index) {
      this.$set(this.disables, index, true);
      this.$set(this.pagination, "is_active", status);
      this.getItems().finally(() => {
        this.disables = {};
      });
    },
  },
  components: { Modal, ButtonWithLoader },
};
</script>
